import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import eye from "../../../assets/eye.png";
import { history } from "../../../_helpers/history";
import DataTable from "react-data-table-component";
import { admincourseservice } from "../../../_services/admincourse.service";
import PopupModal from "../../../_helpers/PopupModal";
import Loader from "../../../_helpers/Loader";
import moment from "moment";
import CourseIterationModel from "../../../Models/CourseIterationModel";
import Logout from "../../../_helpers/Logout";
import CourseIterationModuleBridgeModel from "../../../Models/CourseIterationModuleBridgeModel";
import CommonUploadForm from "../../../_helpers/CommonUploadForm";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import UpdateUnitForIterationModel from "../../../_helpers/UpdateUnitForIterationModel";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

var now = new Date();
var currentDate = moment(now).format("YYYY-MM-DD");
const dateFormat = "YYYY-MM-DD";

const customStyles = {
  // rows: {
  //   style: {
  //     minHeight: '72px', // override the row height
  //   }
  // },
  headCells: {
    style: {
      minWidth: "102px!important", // override the cell padding for data cells
      maxWidth: "0px!important",
    },
  },
  cells: {
    style: {
      minWidth: "104px!important", // override the cell padding for data cells
      maxWidth: "0px!important",
    },
  },
};
const rowSelectCritera = row => row.welcomeNoteSent===true;

export default class EditCourseIteration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupModal: false,
      membershipNumber: "",
      popUpModalText: "",
      messageType: 0,
      isLoading: false,
      courseName: "",
      courseIterationName: "",
      startDate: "",
      //endDate: moment().format(dateFormat),
      endDate: "",
      firstInstallmentDate: "",
      secondInstallmentDate: "",
      minSecondInstallmentDate: moment().format(dateFormat),
      thirdInstallmentDate: "",
      minThirdInstallmentDate: moment().format(dateFormat),
      fourthInstallmentDate: "",
      minFourthInstallmentDate: moment().format(dateFormat),
      iterationCost: "",
      courseIterationId: 0,
      errorMessage: {},
      showEnrollButton: false,
      selected: "",
      enrolledStudentList: [],
      dropDropDownList: [],
      studentList: [],
      selectedIndex: -1,
      courseID: 0,
      moduleList: [],
      Search: "",
      unfilteredList: [],
      isModuleVisible: false,
      moduleVisibleList: [],
      showCommomUploadModel: false,
      folderName: "AssignmentQuestions",
      moduleId: 0,
      documentUrlPath: "",
      welcomeNoteCheckbox: [],
      newIteration: false,
      passedCourseID: 0,
      showUpdateModel: false,
      categoryName: "",
    };
  }

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the list of all student who are enrolled to the course as well as the list of all students who are yet to enroll and also to get the list of all modules that have been assigned to the iteration
  <summary>*/
  componentDidMount = async () => {
    try {
      var CourseIterationId = this.props.match.params.CourseIterationId;
      var courseId = this.props.match.params.courseID;
      var response;
      this.setState({
        isLoading: true,
        courseID: courseId,
        passedCourseID: this.props.match.params.courseID,
      });
      if (CourseIterationId == 0) {
        this.setState({
          courseIterationId: 0,
          showEnrollButton: false,
          newIteration: true,
        });
        response = await admincourseservice.GetNotEnrolledStudentList(0);
      } else {
        this.setState({
          courseIterationId: CourseIterationId,
          //showEnrollButton: true,
          newIteration: false,
        });
        response = await admincourseservice.GetNotEnrolledStudentList(CourseIterationId);

        this.GetCourseIterationList(CourseIterationId);
      }

      this.GetModuleDetails();
      //to Get all the student List who has membershipNumber no.

      if (response.status == 200) {
        this.setState({
          dropDropDownList: response.data.dropDownList,
          enrolledStudentList: response.data.enrolledStudentList,
        });
      } else if (response.status == 401) {
        Logout();
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the list of all students who have been enrolled to the iteration
  <summary>*/
  GetModuleDetails = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      var courseId = this.props.match.params.courseID;
      var CourseIterationId = this.props.match.params.CourseIterationId;
      if(CourseIterationId==0){
        CourseIterationId=this.state.courseIterationId
      }
      var response = await admincourseservice.GetModuleListBasedOnIterationID(courseId,CourseIterationId);
      if (response.status == 200) {
        this.setState({
          moduleList: response.data,
          unfilteredList: response.data,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the full details of the course iteration along with its installments dates
  <summary>*/
  GetCourseIterationList = async (CourseIterationId) => {
    try {
      var response =
        await admincourseservice.GetCourseIterationBasedOnID(CourseIterationId);
      if (response.status == 200) {
        this.setState({
          courseName: response.data.courseName,
          courseIterationName: response.data.courseIterationName,
          startDate: moment(response.data.startDate).format("YYYY-MM-DD"),
          endDate: moment(response.data.endDate).format("YYYY-MM-DD"),
          firstInstallmentDate: moment(response.data.firstInstallmentDate).format("YYYY-MM-DD"),
          secondInstallmentDate: moment(response.data.secondInstallmentDate).format("YYYY-MM-DD"),
          thirdInstallmentDate: moment(response.data.thirdInstallmentDate).format("YYYY-MM-DD"),
          fourthInstallmentDate: moment(response.data.fourthInstallmentDate).format("YYYY-MM-DD"),
          iterationCost: response.data.iterationCost,
          courseID: response.data.courseID,
          categoryName: response.data.categoryName,
        });
      } else if (response.status == 401) {
        Logout();
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 21-07-2021
  Name: VD
  description: Date Change Values
  <summary>*/
  onDateChange = (e) => {
    var selectedDate = e.target.value;
    const { name, value } = e.target;

    //fix for future date on firefox
    //if (selectedDate >= currentDate) {
    this.setState({
      [name]: value,
    });
    //}

    this.setminDate(name, value);
  };

  /* <summary>
  date: 02-08-2021
  Name: VD
  description: To set the installment date duration 3 month each
               VD 25/10/2021 to r 
  <summary>*/
  setminDate = (name, value) => {
    if (name == "firstInstallmentDate") {
      // this.setState({
      //   minSecondInstallmentDate: moment(value)
      //     .add(3, "months")
      //     .format("YYYY-MM-DD"),
      // });
      this.setState({
        minSecondInstallmentDate: moment(value).format("YYYY-MM-DD"),
      });
    }
    if (name == "secondInstallmentDate") {
      // this.setState({
      //   minThirdInstallmentDate: moment(value)
      //     .add(3, "months")
      //     .format("YYYY-MM-DD"),
      // });
      this.setState({
        minThirdInstallmentDate: moment(value).format("YYYY-MM-DD"),
      });
    }
    if (name == "thirdInstallmentDate") {
      // this.setState({
      //   minFourthInstallmentDate: moment(value)
      //     .add(3, "months")
      //     .format("YYYY-MM-DD"),
      // });
      this.setState({
        minFourthInstallmentDate: moment(value).format("YYYY-MM-DD"),
      });
    }

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
    date: 21-07-2021
    Name: VD
    description: So that it doesnt accept negative value
    <summary>*/
  onChangeNumber = (e) => {
    var value = 0;
    if (e.target.value < 0) {
      value = 0;
    } else {
      value = e.target.value;
    }
    const { name } = e.target;
    this.setState({
      [name]: value,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 21-07-2021
  Name: VD
  description: To save the selected value into the Database
  <summary>*/
  addEditCourseIteration = async () => {
    const userID = localStorage.getItem("UserID");
    try {
      if (this.validate()) {
        this.setState({
          isLoading: true,
        });
        const {
          courseIterationId,
          courseIterationName,
          startDate,
          endDate,
          firstInstallmentDate,
          secondInstallmentDate,
          thirdInstallmentDate,
          fourthInstallmentDate,
          iterationCost,
          newIteration,
          //courseID,
        } = this.state;

        var courseId = this.props.match.params.courseID;

        var courseIteration = new CourseIterationModel();
        courseIteration.courseIterationId = courseIterationId;
        courseIteration.courseID = courseId;
        courseIteration.courseIterationName = courseIterationName;
        courseIteration.startDate = startDate;
        courseIteration.endDate = endDate;
        courseIteration.firstInstallmentDate = firstInstallmentDate;
        courseIteration.secondInstallmentDate = secondInstallmentDate;
        courseIteration.fourthInstallmentDate = fourthInstallmentDate;
        courseIteration.thirdInstallmentDate = thirdInstallmentDate;
        courseIteration.iterationCost = iterationCost;
        courseIteration.userID = userID;

        var response = await admincourseservice.AddEditCourseIteration(
          courseIteration
        );
        if (response.status == 200) {
          {
            newIteration
              ? this.setState({
                  showEnrollButton: true,
                  courseIterationId: response.data,
                  popUpModalText: helperText.EditCourseIteration.addedCourseIteration,
                  showPopupModal: true,
                  messageType: MessageType.Success,
                })
              : this.setState({
                  showEnrollButton: true,
                  courseIterationId: response.data,
                  popUpModalText: helperText.EditCourseIteration.updatedCourseIteration,
                  showPopupModal: true,
                  messageType: MessageType.Success,
                });
          }
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
        this.setState({
          isLoading: false,
          newIteration: false,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 28-07-2021
  Name: VD
  description: Validation of the Text  +when comparing two dates
  <summary>*/
  validate = () => {
    try {
      var {
        courseIterationName,
        startDate,
        endDate,
        firstInstallmentDate,
        secondInstallmentDate,
        minSecondInstallmentDate,
        thirdInstallmentDate,
        minThirdInstallmentDate,
        fourthInstallmentDate,
        minFourthInstallmentDate,
        iterationCost,
      } = this.state;
      var isValid = true;

      if (
        courseIterationName == "" ||
        startDate == "" ||
        endDate == "" ||
        startDate > endDate ||
        +firstInstallmentDate <= +startDate ||
        firstInstallmentDate == "" ||
        +secondInstallmentDate >= +minSecondInstallmentDate ||
        secondInstallmentDate == "" ||
        +thirdInstallmentDate >= +minThirdInstallmentDate ||
        thirdInstallmentDate == "" ||
        +fourthInstallmentDate >= +minFourthInstallmentDate ||
        fourthInstallmentDate == "" ||
        iterationCost == 0
      )
        isValid = false;

      if (courseIterationName == "") {
        var error = this.state.errorMessage;
        error["emptyTitle"] = helperText.createCourseIteration.emptyTitle;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }
      if (startDate == "") {
        var error = this.state.errorMessage;
        error["emptyAvailableFrom"] = helperText.createCourseIteration.emptyAvailableFrom;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }
      if (endDate == "" || startDate > endDate) {
        var error = this.state.errorMessage;
        error["emptyAvailableTo"] = helperText.createCourseIteration.emptyAvailableTo;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      if (iterationCost == "") {
        var error = this.state.errorMessage;
        error["emptyIterationCost"] = helperText.createCourseIteration.emptyIterationCost;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      if (firstInstallmentDate == "" || firstInstallmentDate > startDate) {
        var error = this.state.errorMessage;
        error["emptyFirstInstallmentDate"] =helperText.createCourseIteration.emptyFirstInstallmentDate
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }
      if (
        +secondInstallmentDate < +minSecondInstallmentDate ||
        secondInstallmentDate == "" ||
        secondInstallmentDate < minSecondInstallmentDate
      ) {
        var error = this.state.errorMessage;
        error["emptySecondInstallmentDate"] =helperText.createCourseIteration.emptySecondInstallmentDate;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }
      if (
        thirdInstallmentDate < minThirdInstallmentDate ||
        thirdInstallmentDate == "" ||
        thirdInstallmentDate < secondInstallmentDate
      ) {
        var error = this.state.errorMessage;
        error["emptyThirdInstallmentDate"] = helperText.createCourseIteration.emptyThirdInstallmentDate;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      if (
        fourthInstallmentDate < minFourthInstallmentDate ||
        fourthInstallmentDate == "" ||
        fourthInstallmentDate < thirdInstallmentDate
      ) {
        var error = this.state.errorMessage;
        error["emptyFourthInstallmentDate"] =helperText.createCourseIteration.emptyFourthInstallmentDate
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      return isValid;
    } catch (error) {
      return false;
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: This is called when the student is enrolled to the iteration 
  <summary>*/
  EnrollStudentToCourseIteration = async () => {
    try {
      const {
        courseIterationId,
        courseIterationName,
        startDate,
        endDate,
        firstInstallmentDate,
        secondInstallmentDate,
        thirdInstallmentDate,
        fourthInstallmentDate,
        iterationCost,
        courseID,
        membershipNumber,
        StudentList,
        enrolledStudentList,
        dropDropDownList,
        selectedIndex,
      } = this.state;

      var checkmember = dropDropDownList.filter((item) => {
        if (item.membershipNumber == membershipNumber) {
          return item;
        }
      });

      if (checkmember.length == 0) {
        this.setState({
          popUpModalText: helperText.EditCourseIteration.studentNotPresent,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
        return;
      }
      var courseIteration = new CourseIterationModel();
      courseIteration.courseIterationId = courseIterationId;
      courseIteration.courseID = courseID;
      courseIteration.courseIterationName = courseIterationName;
      courseIteration.startDate = startDate;
      courseIteration.endDate = endDate;
      courseIteration.firstInstallmentDate = firstInstallmentDate;
      courseIteration.secondInstallmentDate = secondInstallmentDate;
      courseIteration.thirdInstallmentDate = thirdInstallmentDate;
      courseIteration.fourthInstallmentDate = fourthInstallmentDate;
      courseIteration.iterationCost = iterationCost;
      courseIteration.membershipNumber = membershipNumber;
      var response = await admincourseservice.EnrollStudentToCourseIteration(courseIteration);
      if (response.status == 200) {
        var studentToAdd = dropDropDownList.filter((item) => {
          if (item.membershipNumber == membershipNumber) {
            return item;
          }
        });

        //inorder to stop duplicate entry
        var checkifstudentexist = enrolledStudentList.filter((item) => {
          if (item.membershipNumber == membershipNumber) {
            return item;
          }
        });

        var array = [...dropDropDownList]; // make a separate copy of the array
        //var index = array.indexOf((i) => i.membershipNumber == parseInt(AdmnNo));
        if (selectedIndex !== -1) {
          array.splice(selectedIndex - 1, 1);
          this.setState({ dropDropDownList: array });
        }

        if (checkifstudentexist.length == 0) {
          this.setState((prevstate) => ({
            enrolledStudentList: [
              ...prevstate.enrolledStudentList,
              ...studentToAdd,
            ],
          }));
        } else {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.memebershipNumberExist,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
      this.setState({
        selected: "",
        showEnrollButton: false,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
date: 21-07-2021
Name: VD
description:Once the Text has been changed it gets saved in the setstate 
<summary>*/
  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 31-08-2021
  Name: VD
  description: This method was used because the user can select multiple check boxes at the same time and can save only one 
  <summary>*/
  toggleCheckboxforModuleVisible = async (e, item) => {
    try {
      let moduleListfilter;
      if (e.target.checked) {
        let arr = this.state.moduleVisibleList;
        var row = {
          moduleId: item.moduleId,
          moduleVisible: e.target.checked,
        };
        arr.push(row);

        moduleListfilter = [...this.state.moduleList];
        moduleListfilter
          .filter((row) => row.moduleId == item.moduleId)
          .map((lst) => {
            return lst.moduleVisible = true;
            // lst;
          });

        this.setState({ moduleVisibleList: arr, moduleList: moduleListfilter });
      } else {
        let items = this.state.moduleVisibleList.splice(
          this.state.moduleVisibleList.indexOf(item.moduleId),
          1
        );

        moduleListfilter = [...this.state.moduleList];
        moduleListfilter
          .filter((row) => row.moduleId == item.moduleId)
          .map((lst) => {
            return lst.moduleVisible = false;
            // lst;
          });
        this.setState({
          moduleVisibleList: items,
          moduleList: moduleListfilter,
        });
      }
    } catch (ex) {
      return "";
    }
  };

  header = [
    {
      name: <th className="align-middle text-center">Module ID</th>,
      cell: (row) => <td className="center">{row.uniqueId}</td>,
      center: true,
      selector: (row) => row.uniqueId,
      grow: 1,
    },
    {
      name: <th className="align-middle text-center">Module Title</th>,
      selector: (row) => row.moduleName,
      grow: 3,
      cell: (row) => <td style={{ textAlign: "left" }}>{row.moduleName}</td>,
      left: true,
    },
    // {
    //   name: <th className="align-middle text-center">Module status</th>,
    //   //selector:,
    //   cell: (row) => (
    //     <td className="center">
    //       <a>
    //         <img className="view-image" src={eye} width="30px" />
    //       </a>
    //       <a />
    //     </td>
    //   ),
    //   center: true,
    // },
    {
      name: <th className="align-middle">Module page</th>,
      //selector:,
      cell: (row) => (
        // row.moduleVisible ? (

        <td className="center tg-broze">
          <label class="conta">
            <input
              type="checkbox"
              id="check"
              name="check"
              defaultValue="check"
              checked={row.moduleVisible}
              onChange={(e) => this.toggleCheckboxforModuleVisible(e, row)}
            />

            <span
              style={{ marginTop: "0px" }}
              class="checkbox center-align"
            ></span>
          </label>
        </td>
      ),
      center: true,
    },
    {
      name: <th className="align-middle text-center">TMA-Q</th>,
      //selector:,
      cell: (row) =>
        row.tmaqFilePath == null ? (
          <td className="center">
            <img
              className="view-image"
              src={eye}
              width="30px"
              style={{ opacity: "0.5" }}
            />
          </td>
        ) : (
          <td className="center">
            <img
              className="view-image"
              src={eye}
              width="30px"
              onClick={() => this.showTMAQFile(row)}
            />
          </td>
        ),
      center: true,
    },
    {
      name: <th className="align-middle text-center">Change TMA-Q file</th>,
      //selector:,
      cell: (row) => (
        <td style={{ paddingTop: "10px" }} className="center">
          <button onClick={() => this.showUploadModel(row)}>Choose file</button>
          <div>
            <label>{row.tmaqFileName}</label>
          </div>
        </td>
      ),
      width: "150px",
      center: true,
    },
    {
      name: <th className="align-middle"> </th>,
      //selector:,
      cell: (row) => (
        <td className="center">
          {" "}
          <button
            type="button"
            className="btn save-button"
            onClick={(e) => this.saveModule(e, row)}
          >
            Save
          </button>{" "}
        </td>
      ),
    },
  ];

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: This is called when the admin wants to view the file that has been uodated
  <summary>*/
  showTMAQFile = async (row) => {
    var fileMediaId = row.tmaqfileMediaId;
    var filePath = row.tmaqFilePath;
    //Create a Blob from the PDF Stream
    //  const file = new Blob([response.data], { type: "application/pdf" });
    //  //Build a URL from the file
    //  const fileURL = URL.createObjectURL(file);
    //  //Open the URL on new Window
    //   const pdfWindow = window.open();
    //   pdfWindow.location.href = fileURL;
    if (filePath == null) {
      this.setState({
        popUpModalText: helperText.EditCourseIteration.FileNotUploaded,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      // this.setState({ documentUrlPath: `${filePath}` });

      // await window.open(this.state.documentUrlPath);
      //var fPath = `${filePath}`;
      window.open(filePath, "_blank");
    }
  };
  showUploadModel = async (row) => {
    this.setState({
      showCommomUploadModel: true,
      moduleId: row.moduleId,
    });
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: When the admin does any changes in the particular iteration Module this method is called
  <summary>*/
  saveModule = async (e, row) => {
    try {
      const { moduleVisibleList, courseIterationId } = this.state;
      var isVisible = moduleVisibleList.find((item) => {
        if (item.moduleId == row.moduleId) {
          return item.moduleVisible;
        }
      });
      var filePath = row.tmaqFilePath;
      if (filePath == null) {
        this.setState({
          popUpModalText: helperText.EditCourseIteration.FileNotUploaded,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
        return;
      }
      var courseIterationModuleBridge = new CourseIterationModuleBridgeModel();
      courseIterationModuleBridge.courseIterationModuleBridgeID = 0;
      courseIterationModuleBridge.courseIterationID = parseInt(courseIterationId);
      courseIterationModuleBridge.moduleID = row.moduleId;
      courseIterationModuleBridge.moduleVisible = isVisible == undefined ? false : isVisible.moduleVisible;
      courseIterationModuleBridge.tMAQVisible = false;
      courseIterationModuleBridge.tmaqfileMediaId = row.tmaqfileMediaId;
      courseIterationModuleBridge.unitFileMediaID = 0;

      var response =
        await admincourseservice.addEditCourseIterationModuleBridge(
          courseIterationModuleBridge
        );
      if (response.status == 200) {
        if (response.data == true) {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.moduleUpdated,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.moduleNotUpdated,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
    } catch (e) {
      return "";
    }
  };

  enrollerStudents = [
    {
      name: <th className="align-middle">Membership Number</th>,
      //selector:,
      cell: (row) => <td className="center">{row.membershipNumber}</td>,
      grow: 1,
      whiteSpace: "unset!important",
    },
    {
      name: <th className="align-middle">First Name</th>,
      //selector:,
      cell: (row) => <td className="center">{row.firstName}</td>,
      grow: 1,
      center:true, 
    },
    {
      name: <th className="align-middle">Last Name</th>,
      //selector:,
      cell: (row) => <td className="center">{row.lastName}</td>,
      grow: 1,
      center:true,
    },
    // {
    //   name: (
    //     <th
    //       className="text-center"
    //       scope="col"
    //       style={{ verticalAlign: "middle" }}
    //     >
    //       {" "}
    //       Welcome Note
    //     </th>
    //   ),
    //   cell: (row) => (
    //     <td className="center ">
    //       {/* cell-width */}
    //       {row.welcomeNoteSent == true ? (
    //         <label class="conta">
    //           <input
    //             type="checkbox"
    //             id="check"
    //             name="check"
    //             defaultValue="check"
    //             checked={true}
    //           />

    //           <span
    //             style={{
    //               opacity: "0.5",
    //               marginTop: "-3px",
    //               marginLeft: "17px",
    //             }}
    //             class="checkbox center-align"
    //           ></span>
    //         </label>
    //       ) : (
    //         <label class="conta">
    //           <input
    //             type="checkbox"
    //             id="check"
    //             name="check"
    //             defaultValue="check"
    //             value={row.membershipNumber}
    //             checked={this.state.welcomeNoteCheckbox.find(
    //               (p) => p.id === row.membershipNumber
    //             )}
    //             onChange={(e) => this.toggleWelcomeCheckbox(e, row)}
    //           />

    //           <span
    //             style={{ marginTop: "-3px", marginLeft: "17px" }}
    //             class="checkbox center-align"
    //           ></span>
    //         </label>
    //       )}
    //     </td>
    //   ),
    //   grow: 1,
    // },
    {
      name: <th className="align-middle"></th>,
      //selector:,
      cell: (row) => (
        <td className="center " style={{paddingBottom:'8px',marginLeft:'-5px'}}>
           {/* cell-width */}
          <button
            type="button"
            className="btn manage-button"
            onClick={() =>
              history.push(
                `/ManageEnrollment/${row.studentID}/${this.state.courseIterationId}/${this.props.match.params.courseID}`
              )
            }
          >
            Manage
          </button>
        </td>
      ),
      grow: 1,
    },
  ];

  //to save the checkbox inorder to send the welcome note
  toggleWelcomeCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.welcomeNoteCheckbox;
      arr.push(item.membershipNumber);

      this.setState({ welcomeNoteCheckbox: arr });
    } else {
      let items = this.state.welcomeNoteCheckbox.splice(
        this.state.welcomeNoteCheckbox.indexOf(item.membershipNumber),
        1
      );

      this.setState({
        welcomeNoteCheckbox: items,
      });
    }
    this.setState({
      errorMessage: {},
    });
  };

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  OnEventChange(event) {
    const membershipNumber = event.target.value;
    this.setState({
      selected: event.target.value,
      membershipNumber: membershipNumber,
      selectedIndex: event.target.selectedIndex,
      showEnrollButton: true,
    });
  }

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();

    var filteredStudent = this.state.unfilteredList.filter((p) =>
      p.moduleName.toLowerCase().includes(inputValue)
    );

    this.setState({
      Search: event.target.value,
      moduleList: filteredStudent,
    });
  };

  closeModal = () => {
    this.setState({
      showCommomUploadModel: false,
      showUpdateModel: false,
    });
    this.GetModuleDetails();
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: This is used to send welcome note to the Enrolled user Through email
  <summary>*/
  // To send the welcome note
  sendWelcomeNote = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const { welcomeNoteCheckbox, courseIterationId } = this.state;
      if (welcomeNoteCheckbox.length == 0) {
        var error = this.state.errorMessage;
        error["emptyWelcomeCheckbox"] = helperText.EditCourseIteration.emptyWelcomeCheckbox;
        this.setState({ errorMessage: error });
      } else {
        var response = await adminstudentservice.SendWelcomeNote(
          welcomeNoteCheckbox,
          courseIterationId
        );
        if (response.status == 200) {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.welcomeNoteSent,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (e) {
      return "";
    }
  };

  getFileMediaId = (Id) => {
    this.setState({
      popUpModalText: helperText.EditCourseIteration.FileUploaded,
      showPopupModal: true,
      messageType: MessageType.Success,
    });
  };

  showUpdatePopUp = () => {
    this.setState({
      showUpdateModel: true,
    });
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    this.setState({
      welcomeNoteCheckbox: state.selectedRows,
      isChanged: true,
      errorMessage: {},
    });
  };

  render() {
    const {
      membershipNumber,
      popUpModalText,
      showPopupModal,
      messageType,
      iterationCost,
      courseName,
      courseIterationName,
      startDate,
      endDate,
      firstInstallmentDate,
      secondInstallmentDate,
      minSecondInstallmentDate,
      thirdInstallmentDate,
      minThirdInstallmentDate,
      fourthInstallmentDate,
      minFourthInstallmentDate,
      showEnrollButton,
      isLoading,
      enrolledStudentList,
      showCommomUploadModel,
      folderName,
      courseIterationId,
      moduleId,
      newIteration,
      unfilteredList,
      showUpdateModel,
      passedCourseID,
      categoryName,
    } = this.state;
    return (
      <div>
        <AdminHeader currentTab="Courses" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div className="row">
            <div className="col-lg-12 col-xs-12">
              <h5 className="mt-3 mb-3 gray">
                {categoryName}
                {/* <a className="info-icon" title="More informtion">
                  <img src={info} />
                </a> */}
              </h5>
            </div>
            
            <div className="col-lg-12 col-xs-12">
              <h6 className="mb-3 gray" style={{ fontSize: "18px" }}>
                Update the form below to edit a new iteration to the selected
                course. Once the iteration has been updated, you may upload
                modules and assign students to it.
              </h6>
            </div>
            <div className="col-lg-6">
              <div className="table-responsive" style={{ border: "none" }}>
                <h6 className="gray" style={{ paddingBottom: "7px" }}>
                  Iterations Details
                </h6>
                <table
                  className="table table-striped edit-course-table edit-course-input-width"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color">
                        <label>Selected Course: </label>
                      </td>
                      <td className="white-color left">
                        <label>{courseName}</label>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Iteration Title </label>
                      </td>
                      <td className="white-color left">
                        <input
                          className="input-align textbox"
                          type="text"
                          id="description"
                          name="courseIterationName"
                          style={{ width: "80%" }}
                          value={courseIterationName}
                          onChange={this.onTextChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyTitle
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyTitle}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Available from: </label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="startDate"
                          value={startDate}
                          placeholder="yyyy-mm-dd"
                          defaultValue
                          //min={currentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyAvailableFrom
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyAvailableFrom}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Available to:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="endDate"
                          value={endDate}
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          min={startDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyAvailableTo
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyAvailableTo}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        {" "}
                        <label>Iteration Cost:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="number"
                          step="0.01"
                          name="iterationCost"
                          value={iterationCost}
                          onChange={this.onChangeNumber}
                          style={{width:'137px'}}
                          placeholder="Iteration Cost"
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyIterationCost
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyIterationCost}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        {" "}
                        <label>First installment Due:</label>
                      </td>
                      <td className="white-color left">
                        {" "}
                        <input
                          type="date"
                          name="firstInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={firstInstallmentDate}
                          //min={minSecondInstallmentDate}
                          max={startDate}
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptyFirstInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyFirstInstallmentDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Second installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="secondInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={secondInstallmentDate}
                          min={minSecondInstallmentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptySecondInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptySecondInstallmentDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Third installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="thirdInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={thirdInstallmentDate}
                          min={minThirdInstallmentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptyThirdInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyThirdInstallmentDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Fourth installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="fourthInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={fourthInstallmentDate}
                          min={minFourthInstallmentDate}
                          //max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptyFourthInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{ textAlign: "left", color: "red" }}>
                        {this.state.errorMessage.emptyFourthInstallmentDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color left">
                        {" "}
                        {newIteration ? (
                          <button
                            type="button"
                            className="btn btn-dark button-color create-iteration"
                            onClick={this.addEditCourseIteration}
                          >
                            Create Iteration
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-dark button-color create-iteration"
                            onClick={this.addEditCourseIteration}
                          >
                            Update Iteration
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {newIteration ? (
              ""
            ) : (
              <div
                className="col-lg-6 col-sm-12"
                style={{ marginBottom: "1rem" }}
              >
                <div
                  className="col-lg-6 flex-item-leftside"
                  style={{ paddingLeft: "0px" }}
                >
                  <h6 className="gray"> Enrolled Courses </h6>
                </div>
                <div
                  className="col-lg-6 flex-item-right"
                  style={{ textAlign: "right" }}
                >
                  <img src="images/Over-due.png" width="30px;" />
                  <span> Outstanding Installment(s)</span>
                </div>
                <div
                  className="table-responsive tableBorder"
                  style={{ marginTop: "-19px" }}
                >
                  <table
                    className="table table-striped"
                    style={{ marginBottom: 0 }}
                  >
                    <thead>
                      <tr className="table-header">
                        <th
                          scope="col"
                          colSpan={8}
                          style={{ textAlign: "left" }}
                        >
                          Quick Student Enrollment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ backgroundColor: "#ffffff" }}>
                        <td colSpan={2}>
                          {" "}
                          <label> Student Number: </label>
                        </td>
                        <td colSpan={5} style={{ textAlign: "left" }}>
                          {/* <select
                            className="input101"
                            name="studentList"
                            id="studentList"
                            value={this.state.selected}
                            onChange={this.OnEventChange.bind(this)}
                            style={{ width: "100%", height: "28px" }}
                          > */}
                          {/* <option
                              aria-label="None"
                              value=""
                              selected
                              disabled
                            >
                              Select student to enroll
                            </option> */}
                          {/* {this.state.dropDropDownList != undefined &&
                              this.state.dropDropDownList.map((item, i) => {
                                return (
                                  <option value={item.membershipNumber} key={i}>
                                    {item.membershipNumber}
                                  </option>
                                );
                              })} */}
                          {/* </select> */}
                          <input
                            list="ShowDataList"
                            placeholder="Search / Select"
                            className="input101"
                            name="studentList"
                            id="studentList"
                            value={this.state.selected}
                            onChange={this.OnEventChange.bind(this)}
                            style={{ width: "100%", height: "28px" }}
                          />

                          <datalist id="ShowDataList">
                            {this.state.dropDropDownList != undefined &&
                              this.state.dropDropDownList.map((item, i) => {
                                return (
                                  <option value={item.membershipNumber} key={i}>
                                    {item.membershipNumber}
                                  </option>
                                );
                              })}
                          </datalist>
                        </td>
                        <td colSpan={1} className="center">
                          <button
                            type="button"
                            disabled={!showEnrollButton}
                            className="btn enroll-button"
                            onClick={this.EnrollStudentToCourseIteration}
                          >
                            Enroll
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="table-responsive tableBorder overwrap-th"
                  style={{}}
                >
                  <DataTable
                    columns={this.enrollerStudents}
                    data={enrolledStudentList}
                    noHeader={true}
                    overflowY={true}
                    customStyles={customStyles}
                    pagination
                    selectableRows
                    onSelectedRowsChange={this.handleChange}
                    selectableRowSelected={rowSelectCritera}
                    selectableRowDisabled={rowSelectCritera}
                  />
                  <p style={{ textAlign: "right", color: "red" }}>
                    {this.state.errorMessage.emptyWelcomeCheckbox}
                  </p>

                  <div
                    style={{
                      textAlign: "right",
                      paddingBottom: "15px",
                      paddingRight: "10px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-dark button-color"
                      onClick={() => this.sendWelcomeNote()}
                    >
                      Send Welcome Note
                    </button>
                  </div>
                </div>
              </div>
            )}
            {newIteration ? (
              ""
            ) : (
              <div className="col-md-12">
                <h5 className="mt-3 mb-3 gray">Course Iteration Modules</h5>
              </div>
            )}
            {newIteration ? (
              ""
            ) : (
              <div className="col-md-12">
                <p className="red">
                  {" "}
                  <strong>
                    {" "}
                    CAUTION:
                    <br /> Only minor changes should be applied to a Module, e.g.
                    No questions should differ between the original and revised
                    versions.{" "}
                  </strong>
                </p>
              </div>
            )}
            {/* {newIteration ? (
              ""
            ) : (
              <div
                className="col-lg-5 flex-item-left"
                style={{ padding: "20px" }}
              >
                <button type="button" className="btn delete-button delete">
                  <img src={Deleteicon} className="delete-icon" width="20px" />{" "}
                  Delete
                </button>
              </div>
            )} */}
            {newIteration ? (
              ""
            ) : (
              <div
                className="col-lg-5 flex-item-left"
                style={{ padding: "20px" }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.showUpdatePopUp()}
                >
                  UPDATE MODULE LIST
                </button>
              </div>
            )}
            {newIteration ? (
              ""
            ) : (
              <div className="col-lg-7 flex-item-right">
                <form className="example1" style={{ float: "right" }}>
                  <input
                    type="text"
                    placeholder="Search by Name/ID"
                    name="search2"
                    value={this.state.Search}
                    onChange={this.onSearchTextChange.bind(this)}
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
            )}
          </div>
          {newIteration ? (
            ""
          ) : (
            <div className="table-responsive tableBorder">
              <DataTable
                columns={this.header}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: "72px", // override the row height
                    },
                  },
                  headCells: {
                    style: {
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 14,
                      paddingLeft: "8px", // override the cell padding for head cells
                      paddingRight: "8px",
                    },
                  },
                  cells: {
                    style: {
                      fontSize: 14,
                      paddingLeft: "8px", // override the cell padding for data cells
                      paddingRight: "8px",
                    },
                  },
                }}
                data={this.state.moduleList}
                noHeader={true}
                pagination
              />
            </div>
          )}
          <div className="col-md-12" style={{ paddingTop: "20px" }}>
            <button
              type="button"
              className="btn back-button"
              onClick={() =>
                history.push(`/CourseIteration/${this.state.passedCourseID}`)
              }
            >
              <i className="fa fa-angle-left" />
              Back to Course Iterations
            </button>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closePopupModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            courseIterationId={courseIterationId}
            moduleId={moduleId}
            currentPage="EditCourseIteration"
          />
        )}
        {showUpdateModel && (
          <UpdateUnitForIterationModel
            courseIterationId={courseIterationId}
            courseID={passedCourseID}
            selectedUnitList={unfilteredList}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}
