import React, { Component } from "react";
import Header from "../../_components/Header";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { Input } from "reactstrap";
import { uploadfileservice } from "../../_services/uploadfile.service";
import { ConvertToHtml } from "../../_helpers/utilities";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import StudentFooter from "../../_components/StudentFooter";
import AdminHeader from "../../_components/AdminHeader";
import AdminFooter from "../../_components/AdminFooter";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import Deleteicon from "../../assets/RedDeleteIcon.png";
import DataTable from "react-data-table-component";
import { history } from "../../_helpers/history";

export default class SendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      subject: "",
      message: EditorState.createEmpty(),
      fileInputKey: this.generateRandomString(),
      errorMessage: {},
      hasMessage: false,
      userId: 0,
      sendTo: 0,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      isAdmin: true,
      isPageLoaded: false,
      sendMailTo: "",
      AttachedFilesList: [],
    };
  }

  componentDidMount = () => {
    var UserID = localStorage.getItem("UserID");
    var roleID = localStorage.getItem("RoleID");
    var sendMail = localStorage.getItem("sendMailTo");
    const emailId = this.props.match.params.emailId;
    //var roleID = this.props.match.params.roleId;
    this.setState({
      userId: UserID,
      sendTo: emailId == "" ? "" : emailId,
      sendMailTo: sendMail == undefined ? "" : sendMail,
    });
    if (roleID == 1) {
      this.setState({
        isAdmin: true,
        isPageLoaded: true,
      });
    } else {
      this.setState({
        isAdmin: false,
        isPageLoaded: true,
      });
    }
  };
  subjectText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  };

  HandleTextChange = (editorState, name) => {
    var empty = editorState.getCurrentContent().hasText();
    this.setState({
      [name]: editorState,
      errorMessage: {},
      hasMessage: empty,
    });
  };

  onFileChange = (e) => {
    if (!e.target.value) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        AttachedFilesList: null,
      });
      return;
    }
    let files = e.target.files;
    var ins = files.length;

    for (var i = 0; i < ins; i++) {
      let attachedFile = files[i];
      this.setState((prevState) => ({
        AttachedFilesList: [...prevState.AttachedFilesList, attachedFile],
      }));
    }

    this.setState({
      documentFile: files,
    });
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
  date: 24-09-2021
  Name: VD
  description: This is used to send the mail from both admin and student side
  <summary>*/
  sendEmail = async (e) => {
    try {
      e.preventDefault();

      const {
        subject,
        message,
        documentFile,
        userId,
        sendTo,
        AttachedFilesList,
      } = this.state;
      var value = ConvertToHtml(message);
      if (this.validate()) {
        this.setState({
          isLoading: true,
        });
        let data = new FormData();
        data.append("subject", subject);
        data.append("message", value);
        data.append("file", documentFile);
        data.append("userId", userId);
        data.append("sendTo", sendTo);
        if (AttachedFilesList != undefined) {
          var ins = AttachedFilesList.length;
          for (var x = 0; x < ins; x++) {
            if (
              AttachedFilesList[x].size != null &&
              AttachedFilesList[x].size != undefined
            ) {
              data.append(`file_${x}`, AttachedFilesList[x]);
            }
          }
        }
        var response = await uploadfileservice.SendEmail(data);
        if (response.status == 200) {
          this.setState({
            popUpModalText: helperText.AdvanceCoursePopupModalText.mailsent,
            showPopupModal: true,
            messageType: MessageType.Success,
            subject: "",
            message: EditorState.createEmpty(),
            fileInputKey: this.generateRandomString(),
            documentFile: null,
            AttachedFilesList: [],
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (ex) {
      return "";
    }
  };

  validate = () => {
    const { subject, message, hasMessage } = this.state;
    try {
      var isValid = true;
      if (subject == "" || hasMessage == false) isValid = false;

      if (subject == "") {
        var error = this.state.errorMessage;
        error["emptysubject"] = helperText.AddEditTemplate.emptyTemplatesubject;
        this.setState({ errorMessage: error });
      }
      if (hasMessage == false) {
        var error = this.state.errorMessage;
        error["emptymessage"] = helperText.AddEditTemplate.emptyTemplatemessage;
        this.setState({ errorMessage: error });
      }

      return isValid;
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  DeleteFile = (row) => {
    let attachedlist = this.state.AttachedFilesList;
    var ins = attachedlist.length;
    for (var x = 0; x < ins; x++) {
      if (attachedlist[x].name == row.name) {
        if (attachedlist[x].size != null && attachedlist[x].size != undefined) {
          const items = this.state.AttachedFilesList.filter(
            (AttachedFilesList) => AttachedFilesList.name !== row.name
          );
          this.setState({ AttachedFilesList: items });
        } else {
          this.setState((prevState) => ({
            DeleteAttachedFilesList: [
              ...prevState.DeleteAttachedFilesList,
              row.courseEnquiryEmailTemplateAttachmentBridgeId,
            ],
          }));
          const items = this.state.AttachedFilesList.filter(
            (AttachedFilesList) => AttachedFilesList.name !== row.name
          );
          this.setState({ AttachedFilesList: items });
        }
      }
    }
  };

  AttachedFilesColumns = [
    {
      cell: (row) => <td className="center">{row.name}</td>,
      center: true,
      grow: 1,
    },
    {
      cell: (row) => (
        <td
          className="center material-tab"
          onClick={() => this.DeleteFile(row)}
          style={{ cursor: "pointer" }}
        >
          <img src={Deleteicon} className="manage-notification-deleteIcon" />
        </td>
      ),
      center: true,
    },
  ];

  render() {
    const {
      isLoading,
      subject,
      message,
      fileInputKey,
      popUpModalText,
      messageType,
      showPopupModal,
      isPageLoaded,
      isAdmin,
      sendMailTo,
      AttachedFilesList,
    } = this.state;
    return (
      <div>
        <div className="admin-main-body email-main-body">
          <section className=" student-menu-bar">
            {isPageLoaded ? (
              isAdmin ? (
                <AdminHeader currentTab="None" />
              ) : (
                <Header />
              )
            ) : (
              ""
            )}
            {isLoading && <Loader />}
            {/* /* --- Body Code ---* /  */}
            <section id="coursenbanner" className="read-msg-title-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      {" "}
                      <b className="p-title">Send Message </b>
                      {/* <span>-</span>
                        <label>admin@iims.co.uk</label> */}
                    </p>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </section>
          </section>
          <section className="read-msg-sec">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-10">
                  <form className="send-msg-form">
                    {/* {sendMailTo == "" ? (
                      ""
                    ) : (
                      <div className="form-group row">
                        <span htmlFor className="col-sm-1 col-form-label">
                          To
                        </span>
                        <div className="col-sm-11">
                          <label htmlFor className="col-form-label">
                            {sendMailTo}
                          </label>
                        </div>
                      </div>
                    )} */}
                    <div className="form-group row">
                      <span htmlFor className="col-sm-1 col-form-label">
                        Subject
                      </span>
                      <div className="col-sm-11">
                        <div>
                          <input
                            type="text"
                            name="subject"
                            className="form-control "
                            value={subject}
                            onChange={(e) => this.subjectText(e)}
                          />
                        </div>
                        <div
                          style={{
                            display: this.state.errorMessage.emptysubject
                              ? "contents"
                              : "none",
                            color: "red",
                          }}
                        >
                          {this.state.errorMessage.emptysubject}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <span
                        htmlFor="inputPassword"
                        className="col-sm-1 col-form-label"
                      >
                        Message
                      </span>
                      <div className="col-sm-11">
                        {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} /> */}

                        <Editor
                          editorStyle={{ minHeight: 170 }}
                          editorState={message}
                          wrapperClassName="demo-wrapper sendtext-area-bg"
                          editorClassName="editer-content"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={(editorState) =>
                            this.HandleTextChange(editorState, "message")
                          }
                          toolbar={{
                            options: ["inline"],
                            inline: {
                              inDropdown: false,
                              options: ["bold", "italic", "underline"],
                            },
                          }}
                        />
                        <div
                          style={{
                            display: this.state.errorMessage.emptymessage
                              ? "contents"
                              : "none",
                            color: "red",
                          }}
                        >
                          {this.state.errorMessage.emptymessage}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <span
                        htmlFor="inputPassword"
                        className="col-sm-1 col-form-label"
                      >
                        Attach
                      </span>
                      <div className=" col-12 col-sm-6">
                        <Input
                          type="file"
                          accept=".pdf,.xlsx, .xls,.doc,.docx"
                          // accept=" .doc, .docx, .pdf"
                          name="documentFile"
                          onChange={this.onFileChange}
                          multiple="multiple"
                          key={fileInputKey || ""}
                          style={{
                            marginTop: "10px",
                            width: "95%",
                            overflow: "revert",
                            whiteSpace: "break-spaces",
                            color: "transparent",
                            cursor: "pointer",
                          }}
                        />
                        <div id="selectedRefereceLetters">
                          <DataTable
                            columns={this.AttachedFilesColumns}
                            data={AttachedFilesList}
                            noHeader={true}
                          />
                        </div>
                      </div>
                      {/* <div className="xol-4 col-sm-2">
                            <button type="upload" className="btn btn-success mb-2">Upload</button>
                          </div> */}
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <div className="col-sm-6">
                          <button
                            type="button"
                            className="btn back-button"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-angle-left" />
                            Back{" "}
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 custom-send-btn"
                            onClick={this.sendEmail}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isPageLoaded ? isAdmin ? <AdminFooter /> : <StudentFooter /> : ""}
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
