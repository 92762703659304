import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { studentService } from "../_services/student.service";
import { ConvertToHtml } from "./utilities";
import PopupModal from "./PopupModal";
import { MessageType } from "./Enums";
import { helperText } from "../_helpers/helpertext";
import Multiselect from 'multiselect-react-dropdown';

export default class AdvanceCoursePopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      unitList: [],
      selectedUnitList: [],
      message: EditorState.createEmpty(),
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      errorMessage: {}
    };
  }

  componentDidMount = async () => {
    try{
      this.setState({
        isLoading: true,
      });
      var studentId = localStorage.getItem("UserID");
      var response = await studentService.GetModuleListBasedOnStudentID(studentId)
      if (response.status == 200) {
        this.setState({
          unitList: response.data,
        });
      }
  
      this.setState({
        isLoading: false,
      });
    }catch (ex) {
      return "";
    }
  };

  OnEventChange(event) {
    this.setState({
      selectedUnit: event.target.value,
    });
  }

  /* <summary>
  date: 20-09-2021
  Name: VD
  description: This was used so that the student typed text will get saved in the exact format since its  a mail
  <summary>*/
  HandleTextChange = (editorState, name) => {
    this.setState({
      [name]: editorState,
      errorMessage: {},
    });
  };

  sendMessage = async () => {
    try{
      const { selectedUnitList, message } = this.state;

      var messageHasText = message.getCurrentContent().hasText();
  
      if(messageHasText == false)
      {
        var error = this.state.errorMessage;
        error["emptymessage"] = helperText.AddEditTemplate.emptyTemplatemessage;
        this.setState({ errorMessage: error });
      }
      else{
        var value = ConvertToHtml(message);
        var studentId = localStorage.getItem("UserID");
        var response = await studentService.SendAdvanceCourseEnquiryMail(studentId,selectedUnitList,value);
        this.setState({
          isLoading: true,
         });
  
      if (response.status == 200) {
        this.setState({
          popUpModalText: helperText.AdvanceCoursePopupModalText.mailsent,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
      }
  
      this.setState({
        isLoading: false,
      });
      this.props.closeSubmitmodal();
      }
    }catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
      
    });
    this.props.closeModal()
  };

  onSelect=(selectedList, selectedItem)=> {
    this.setState((prevState)=>({
      selectedUnitList:[...prevState.selectedUnitList,selectedItem]
    }));
  }

  onRemove=(selectedList, removedItem)=> {
    this.setState((prevState)=>({
      selectedUnitList:prevState.selectedUnitList.filter(
        (x)=>x.id !== removedItem.id
      )
    }));
  }

  render() {
    const {
      unitList,
      selectedUnitList,
      message,
      showPopupModal,
      popUpModalText,
      messageType,
    } = this.state;
    return (
      <div
        className="modal AdvanceCourseModal"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLongTitle">
                Opt-in for Advanced Course
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.closeModal}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="SelectAdvancedUnit">
                    Select Advanced Module
                  </label>
                  {/* <select
                    className="form-control form-control-sm"
                    value={selectedUnit}
                    onChange={this.OnEventChange.bind(this)}
                  >
                    {unitList.map((item) => {
                      return (
                        <option value={item.moduleID}>{item.unitName}</option>
                      );
                    })}
                  </select> */}
                  <Multiselect
                  options={unitList} // Options to display in the dropdown
                  selectedValues={selectedUnitList} // Preselected value to persist in dropdown
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="SelectAdvancedUnit-message">Message</label>
                  
                  <Editor
                    editorStyle={{ height: 110, background: "#ffffff" }}
                    editorState={message}
                    wrapperClassName="demo-wrapper"
                    editorClassName="editer-content"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={(editorState) =>
                      this.HandleTextChange(editorState, "message")
                    }
                    toolbar={{
                      options: ["inline"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline"],
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                  display: this.state.errorMessage.emptymessage
                  ? "contents"
                  : "none",
                  color: "red",
                  }}
                >
                {this.state.errorMessage.emptymessage}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={this.props.closeModal}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.sendMessage}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType}  />
        )}
      </div>
    );
  }
}
