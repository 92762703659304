//this file will contain wrapper functions for http get/post.
import axios from "axios";
import { config } from "./config";
import {isStringEmpty} from "../_helpers/string_helper";
const defaultTimeout = 15000;
let postServiceTimeout = null;
let saveServiceTimeout = null;
let postServiceWithHeaderTimeout = null;
let getServiceTimeout = null;

class webserviceWrapper {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    //service.defaults.timeout = defaultTimeout;
    service.interceptors.request.use((config) => {
      const authToken = this.retreiveAuthToken();

      if (!isStringEmpty(authToken)) config.headers.Authorization = authToken;
      return config;
    });

    this.service = service;
  }
  buildUrl = (path) => {
    return `${config.apiUrl}/${path}`;
  };
  buildLongRunUrl = (path) => {
    return `${config.longRunningUrl}${path}`;
  };

  /* <summary>
  date: 09/07/2021
  Name: VD
  description: Handle Success Response from WebAPI
  <summary>*/

  handleSuccess(response) {
    var response = {
      status: 200,
      error: null,
      data: response.data,
      headers: response.headers,
    };
    return response;
  }

  /* <summary>
  date: 09/07/2021
  Name: VD
  description: Handle  Error response from WebAPI 
  <summary>*/

  handleError = (error) => {
    try {
      var response = {
        status: error.response !== undefined ? error.response.status : error,
        error: error,
        data: error.response,
      };
      return response;
    } catch (error) {
      var response = {
        status: 500,
        error: error,
        data: null,
      };
      return response;
    }
  };

  async get(path, timeout, responseType) {
    clearTimeout(getServiceTimeout);
    let source = axios.CancelToken.source();
    getServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    const response = await this.service.get(
      this.buildUrl(path),
      { responseType: responseType == undefined ? "json" : responseType },
      {
        cancelToken: source.token,
      }
    );
    clearTimeout(getServiceTimeout);
    getServiceTimeout = null;
    return response;
  }
  async saveRequest(path, payload, timeout) {
    clearTimeout(saveServiceTimeout);
    var source = axios.CancelToken.source();
    saveServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: this.buildUrl(path),
      responseType: "json",
      data: payload,
      timeout: 60 * 7 * 1000,
      //timeout: timeout === undefined ? defaultTimeout : timeout,
    });
    clearTimeout(saveServiceTimeout);
    saveServiceTimeout = null;
    return response;
  }

  async post(path, payload, timeout) {
    clearTimeout(postServiceTimeout);
    var source = axios.CancelToken.source();
    postServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: this.buildUrl(path),
      responseType: "json",
      data: payload,
      //timeout: timeout === undefined ? defaultTimeout : timeout,
    });
    clearTimeout(postServiceTimeout);
    postServiceTimeout = null;
    return response;
  }

  
  /* <summary>
  date: 09/07/2021
  Name: VD
  description: Post API Call with Header Data
  <summary>*/

  async postWithHeader(path, payload, timeout, header) {
    clearTimeout(postServiceWithHeaderTimeout);
    var source = axios.CancelToken.source();
    postServiceWithHeaderTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: this.buildUrl(path),
      responseType: "json",
      header: header,
      data: payload,
      cancelToken: source.token,
    });
    postServiceWithHeaderTimeout = null;
    return response;
  }

  /* <summary>
  date: 19-07-2021
  Name: VD
  description: For Downloading the File
  <summary>*/ 
  async downloadBlob(path, timeout) {
    clearTimeout(getServiceTimeout);
    let source = axios.CancelToken.source();
    getServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );

    const url = this.buildUrl(path);
    const response = await this.service.get(url, {
      cancelToken: source.token,
      responseType: "blob",
    });
    clearTimeout(getServiceTimeout);
    getServiceTimeout = null;
    return response;
  }

  async postForNotification(path, payload, timeout) {
    clearTimeout(postServiceTimeout);
    var source = axios.CancelToken.source();
    postServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
    });
    clearTimeout(postServiceTimeout);
    postServiceTimeout = null;
    return response;
  }

  async longRunningPost(path, payload, timeout, withCredentials) {
    clearTimeout(postServiceTimeout);
    var source = axios.CancelToken.source();
    postServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
      withCredentials: withCredentials ? true : false,
    });
    clearTimeout(postServiceTimeout);
    postServiceTimeout = null;
    return response;
  }
  /* <summary>
  date: 09/07/2021
  Name: VD
  description: Get Call with Long Running Url
  <summary>*/
  async getWithLongRunningUrl(path, timeout, responseType) {
    clearTimeout(getServiceTimeout);
    let source = axios.CancelToken.source();
    getServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    const response = await this.service.get(
      this.buildLongRunUrl(path),
      { responseType: responseType == undefined ? "json" : responseType },
      {
        cancelToken: source.token,
      }
    );
    clearTimeout(getServiceTimeout);
    getServiceTimeout = null;
    return response;
  }
  /* <summary>
  date: 09/07/2021
  Name: VD
  description: Post call with Long running url
  <summary>*/
  async postWithLongRunningUrl(path, payload, timeout) {
    clearTimeout(postServiceTimeout);
    var source = axios.CancelToken.source();
    postServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    var response = await this.service.request({
      method: "POST",
      url: this.buildLongRunUrl(path),
      responseType: "json",
      data: payload,
      //timeout: timeout === undefined ? defaultTimeout : timeout,
    });
    clearTimeout(postServiceTimeout);
    postServiceTimeout = null;
    return response;
  }

  /* <summary>
  date: 21-07-2021
  Name: VD
  description: To get the auth token for authorisation
  <summary>*/ 
retreiveAuthToken = () => {
  let authToken = "";
  try {
    const value = localStorage.getItem('SessionToken');
    if (!isStringEmpty(value)) authToken = `Bearer ${value}`;
  } catch (e) {}
  return authToken;
};
}
export default new webserviceWrapper();
