
import webserviceWrapper from "../_helpers/webserviceWrapper";

export const courseService={
    GetAllCourseList,
    GetIndivisualCourse,
    GetModuleDetailsById,
    GetCourseDetailsByStudentId,
    getAssignmentListBasedOnModuleIdAndStudentId,
    DeleteAssignmentSelectedByStudent,
    ShowHideModule,
};

function GetAllCourseList(){
    return webserviceWrapper.get(
        `Course/GetAllCourseList`
        
      );
}

function GetIndivisualCourse(CourseID){
    return webserviceWrapper.post(
        `Course/GetIndivisualCourse?CourseID=${CourseID}`
    )
}

function GetModuleDetailsById(moduleId){
    return webserviceWrapper.post(
        `Course/GetModuleDetailsById?moduleId=${moduleId}`
    )
}


function GetCourseDetailsByStudentId(studentId){
    return webserviceWrapper.post(
        `Course/GetCourseDetailsByStudentId?studentId=${studentId}`
    )
}

function getAssignmentListBasedOnModuleIdAndStudentId(modueleId,studentId){
    return webserviceWrapper.post(
        `Course/getAssignmentListBasedOnModuleIdAndStudentId`,
        { modueleId, studentId},
        60000
      );
}

function DeleteAssignmentSelectedByStudent(studentAssignmentFilesBridgeID){
    return webserviceWrapper.post(
        `Course/DeleteAssignmentSelectedByStudent`,
        { studentAssignmentFilesBridgeID},
        60000
      );
}

function ShowHideModule(studentId){
    return webserviceWrapper.post(
        `Course/ShowHideModule?studentId=${studentId}`
    )
}