import webserviceWrapper from "../_helpers/webserviceWrapper";

export const admincourseservice={
    EnrollStudentToCourseIteration,
    AddEditCourseIteration,
    GetNotEnrolledStudentList,
    GetCourseList,
    CreateCourse,
    DeleteSelectedCourses,
    GetCourseIterationList,
    DeleteCourseIterations,
    GetCourseIterationBasedOnID,
    GetInstallmentDetails,
    GetModuleDetailsForIterationBasedOnUserID,
    GetModuleListBasedOnIterationID,
    GetcourseCategoryList,
    addEditCourseIterationModuleBridge,
    addEditStudentModuleBridge,  
    addEditIterationExtendDateForStudent,
    AddEditIterationExtendAmountandDatePaidByStudent,
    GetIterationExtendedDateForStudent,
    AddEditAssignmentPayment,
    GetModuleListToAssignToStudent,
    AddModulesToCourseIteration,
    addEditHardcopyAmount,
    GetIterationFeesDetailsBasedOnStudentID,
    RemoveEnrolledStudentFromIteration,
    GetModuleListNotSelectedByStudent,
    AddModuleToStudentID,
    AddEditCourseName,
    AddEditInstallmentAmount,
    addEditAdvanceCourseEndDate,
    addEditManageEnrollmentPage,
    getRemarksListBasedOnStudentIdAndAttemptNumber,
    addEditRemarksFileBasedOnStudentIdAndAttemptNumber,
}

function EnrollStudentToCourseIteration(courseIteration){
    return webserviceWrapper.post(
        `CourseAdmin/EnrollStudentToCourseIteration`,
        courseIteration
    )
}


function AddEditCourseIteration(courseIteration){
    return webserviceWrapper.post(
        `CourseAdmin/AddEditCourseIteration`,
        courseIteration
    )
}

function GetNotEnrolledStudentList(CourseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetNotEnrolledStudentList?CourseIterationId=${CourseIterationId}`
    )
}


function GetCourseList(){
    return webserviceWrapper.get(
        `CourseAdmin/GetCourseList`
    )
}

function CreateCourse(courseName,selectedCategory){
    return webserviceWrapper.post(
        `CourseAdmin/CreateCourse`,
        {courseName,selectedCategory}
    )
}

function DeleteSelectedCourses(CourseID){
    return webserviceWrapper.post(
        `CourseAdmin/DeleteSelectedCourses`,
        CourseID
    )
}

function GetCourseIterationList(courseId){
    return webserviceWrapper.post(
        `CourseAdmin/GetCourseIterationList?courseId=${courseId}`
    )
}

function DeleteCourseIterations(courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/DeleteCourseIterations`,
        courseIterationId
    )
}

function GetCourseIterationBasedOnID(courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetCourseIterationBasedOnID?courseIterationId=${courseIterationId}`
    )
}

function GetInstallmentDetails(userID,courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetInstallmentDetails`,
        {userID,courseIterationId}
    )
}

function GetModuleDetailsForIterationBasedOnUserID(userID, courseIterationID){
    return webserviceWrapper.post(
        `CourseAdmin/GetModuleDetailsForIterationBasedOnUserID`,
        { userID, courseIterationID }
    )
}

function GetModuleListBasedOnIterationID(courseId,CourseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetModuleListBasedOnIterationID`,
        {courseId,CourseIterationId}
    )
}

function GetcourseCategoryList(){
    return webserviceWrapper.get(
        `CourseAdmin/GetcourseCategoryList`
    )
}

function addEditCourseIterationModuleBridge(courseIterationModuleBridge){
    return webserviceWrapper.post(
        `CourseAdmin/addEditCourseIterationModuleBridge`,
        courseIterationModuleBridge
    )
}

function addEditStudentModuleBridge(studentModuleBridge){
    return webserviceWrapper.post(
        `CourseAdmin/addEditStudentModuleBridge`,
        studentModuleBridge
    )
}

function addEditIterationExtendDateForStudent(isCourseExtended,numberOfDaysExtended,extendedDaysAmount,userID,courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/addEditIterationExtendDateForStudent`,
        {isCourseExtended,numberOfDaysExtended,extendedDaysAmount,userID,courseIterationId}
    )
}

function AddEditIterationExtendAmountandDatePaidByStudent(extendedDaysPaidOn,extendedDaysAmount,userID,courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/AddEditIterationExtendAmountandDatePaidByStudent`,
        {userID, courseIterationId, extendedDaysAmount, extendedDaysPaidOn}
    )
}

function GetIterationExtendedDateForStudent(userID,courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetIterationExtendedDateForStudent`,
        {userID,courseIterationId}
    )
}

function AddEditAssignmentPayment(studentModuleBridge){
    return webserviceWrapper.post(
        `CourseAdmin/AddEditAssignmentPayment`,
        studentModuleBridge
    )
}

function GetModuleListToAssignToStudent(courseId,CourseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetModuleListToAssignToStudent`,
        {courseId,CourseIterationId}
    )
}

function AddModulesToCourseIteration(newModule){
    return webserviceWrapper.post(
        `CourseAdmin/AddModulesToCourseIteration`,
        newModule
    )
}

function addEditHardcopyAmount(userId,CourseIterationId,hardCopyAmount, hardCopyPaymentDate){
    return webserviceWrapper.post(
        `CourseAdmin/addEditHardcopyAmount`,
        {userId, CourseIterationId, hardCopyAmount, hardCopyPaymentDate}
    )
}

function GetIterationFeesDetailsBasedOnStudentID(userId,CourseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/GetIterationFeesDetailsBasedOnStudentID`,
        {userId,CourseIterationId}
    )
}

/* <summary>
date: 11-10-2021
Name: VD
description: When the student is removed from the iteration(EnrolledCourses)
<summary>*/ 
function RemoveEnrolledStudentFromIteration(deleteIterationCheckBox,userID){
    return webserviceWrapper.post(
        `CourseAdmin/RemoveEnrolledStudentFromIteration`,
        {deleteIterationCheckBox,userID}
    )
}

function GetModuleListNotSelectedByStudent(courseId,CourseIterationId,studentId){
    return webserviceWrapper.post(
        `CourseAdmin/GetModuleListNotSelectedByStudent`,
        {courseId,CourseIterationId,studentId}
    )
}

function AddModuleToStudentID(newModule){
    return webserviceWrapper.post(
        `CourseAdmin/AddModuleToStudentID`,
        newModule
    )
}

function AddEditCourseName(courseId,courseName){
    return webserviceWrapper.post(
        `CourseAdmin/AddEditCourseName`,
        {courseId,courseName}
    )
}

function AddEditInstallmentAmount(userId, CourseIterationId, installmentAmount,instllmentNo){
    return webserviceWrapper.post(
        `CourseAdmin/AddEditInstallmentAmount`,
        {userId, CourseIterationId, installmentAmount,instllmentNo}
    )
}

function addEditAdvanceCourseEndDate(userID,courseIterationId,date){
    return webserviceWrapper.post(
        `CourseAdmin/addEditAdvanceCourseEndDate`,
        {userID,courseIterationId,date}
    )
}

function addEditManageEnrollmentPage(CourseIterationPaymentDetailsModel){
    return webserviceWrapper.post(
        `CourseAdmin/addEditManageEnrollmentPage`,
        CourseIterationPaymentDetailsModel
    )
}

function getRemarksListBasedOnStudentIdAndAttemptNumber(attemptNumber,moduleId,studentId,courseIterationId){
    return webserviceWrapper.post(
        `CourseAdmin/getRemarksListBasedOnStudentIdAndAttemptNumber`,
        {attemptNumber,moduleId,studentId,courseIterationId}
    )
}

function addEditRemarksFileBasedOnStudentIdAndAttemptNumber(data){
    return webserviceWrapper.post(
        `CourseAdmin/addEditRemarksFileBasedOnStudentIdAndAttemptNumber`,
        data
    )
}