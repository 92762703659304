import React, { Component } from "react";
import Header from "../../_components/Header";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import { studentService } from "../../_services/student.service";
import moment from "moment";
import Logout from "../../_helpers/Logout";
import { history } from "../../_helpers/history";
import StudentFooter from "../../_components/StudentFooter";
import { MessageType } from "../../_helpers/Enums";

export default class StudentNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      userID: 0,
      messageSent: [],
      messageReceived: [],
      notificationCount:0,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get all the notification that the student has sent and recieved
  <summary>*/ 
  componentDidMount = async () => {
    try{
      var studentId = localStorage.getItem("UserID");
      this.setState({
        isLoading: true,
        userID: studentId,
      });
      var response = await studentService.GetStudentNotification(studentId);
      if (response.status == 200) {
        this.setState({
          messageSent: response.data.fromResponse,
          messageReceived: response.data.toResponse,
          notificationCount:localStorage.getItem("notificationCount")
        });
      } else if(response.status==401){
        Logout();
      }else {
        this.setState({
          popUpModalText: "error while loading data",
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
  
      this.setState({
        isLoading: false,
      });
    }catch (ex) { 
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  
  openEmail=(emailId,type)=>{
    const roleId=localStorage.getItem("RoleID")
    localStorage.setItem("MessageType",type);
    history.push(`/ReadEmail/${emailId}/${roleId}`)
  }


  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      messageSent,
      messageReceived,
      notificationCount
    } = this.state;
    return (
      <div>
        <section className="student-main-body student-footer-body-space">
          <Header currentTab="StudentNotification" />
          {isLoading && <Loader />}
          <section>
              <section
                id="coursenbanner"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ marginTop: "3px" }}>
                      Messages - <small> Unread {notificationCount} (of {messageReceived.length})</small>&nbsp;&nbsp;
                      <button type="button" 
                      className="btn btn-primary"
                      onClick={()=>history.push("/SendEmail")}>
                        <img src="images/new-message.png" />
                        &nbsp;New Message
                      </button>{" "}
                    </h2>
                  </div>
                </div>
              </section>
              <section className="courselist col-md-12" id="content">
                <ul className="nav nav-tabs m-b-n-xxs xd">
                  <li className="active notifiaction-btn-msg">
                    {" "}
                    <a className="msg-link" href="#Recieved" data-toggle="tab">
                      {" "}
                      Received
                    </a>{" "}
                  </li>
                  <li className="notifiaction-btn-msg sent-sm" style={{marginLeft:'7px'}}>
                    {" "}
                    <a className="msg-link" href="#Sent" data-toggle="tab">
                      Sent
                    </a>{" "}
                  </li>
                </ul>
                <div className="panel panel-default tab-content xdff">
                  <ul className="list-group tab-pane active" id="Recieved">
                    {messageReceived.map((item) => {
                      return (
                        <li className="list-group-item">
                          <h4 style={{ fontSize: "15px" }}>
                            from {item.recievedFrom} -
                            {moment(item.dateRecieved).format(
                              "DD/MM/YYYY - HH:mm:ss "
                            )}{" "}
                          </h4>
                          <ul className="mail-items">
                          {item.viewed ?"":
                            <li>
                              <img src="images/new.png" />
                            </li>
                          }
                            <li>
                              <img src="images/reply-message.png" />
                            </li>
                          </ul>
                          {item.viewed ? (
                            <p onClick={()=>this.openEmail(item.notificationId,"receivedMail")}  style={{cursor:'pointer'}}>
                              {item.subject} 
                            </p>
                          ) : (
                            <p onClick={()=>this.openEmail(item.notificationId,"receivedMail")}  style={{cursor:'pointer'}}>
                              <strong>
                                {item.subject}
                              </strong>
                            </p>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  <ul
                    className="list-group tab-pane list-group-alt list-group-lg"
                    id="Sent"
                  >
                    {messageSent.map((item) => {
                      return (
                        <li className="list-group-item">
                          <h4 style={{ fontSize: "15px" }}>
                            to {item.recievedTo} -
                            {moment(item.dateSent).format(
                              "DD/MM/YYYY - HH:mm:ss"
                            )}{" "}
                          </h4>
                          <ul className="mail-items">
                            <li>
                              <img src="images/reply-message.png" />
                            </li>
                          </ul>
                          <p onClick={()=>this.openEmail(item.notificationId,"sentMail")}  style={{cursor:'pointer'}}>
                            {item.subject}
                          </p>
                        </li>
                      );
                    })}
                  </ul> 
                </div>
              </section>
              <div className="clearfix" />
          </section>
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
