import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Calendar from "../../../assets/Calendar.png";
import Qa from "../../../assets/Qa.png";
import { studentService } from "../../../_services/student.service";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import moment from "moment";
import Logout from "../../../_helpers/Logout";
import document from "../../../assets/Document.png";
import success from "../../../assets/success.png";
import { admincourseservice } from "../../../_services/admincourse.service";
import Overdue from "../../../assets/Over-due.png";
import DataTable from "react-data-table-component";
import StudentModuleBridgeModel from "../../../Models/StudentModuleBridgeModel";
import { history } from "../../../_helpers/history";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ResubmissionAssignmentPaymentModel from "../../../Models/ResubmissionAssignmentPaymentModel";
import spam from "../../../assets/spam.png";
import AdminFooter from "../../../_components/AdminFooter";
import { PaymentDetailsModal } from "../../../components/PaymentDetailsModal";
import AddUnitToStudentModel from "../../../_helpers/AddUnitToStudentModel";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";
import CourseIterationPaymentDetailsModel from "../../../Models/CourseIterationPaymentDetailsModel";
import Deleteicon from "../../../assets/RedDeleteIcon.png";

const dateFormat = "YYYY-MM-DD";
var now = new Date();
var currentDate = moment(now).format("YYYY-MM-DD");
//var minDate = moment(new Date("0001-01-01")).format("YYYY-MM-DD");
var minDate = moment.utc("0001-01-01");
export default class ManageEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      emailAddress: "",
      membershipNumber: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      courseTitle: "",
      courseIteration: "",
      iterationCost: 0,
      startDate: moment().format(dateFormat),
      endDate: moment().format(dateFormat),
      isLoading: false,
      firstInstallmentReceived: moment().format(dateFormat),
      secondInstallmentReceived: moment().format(dateFormat),
      thirdInstallmentReceived: moment().format(dateFormat),
      fourthInstallmentReceived: moment().format(dateFormat),
      paymentPlanId: 0,
      firstInstallmentAmount: 0,
      secondInstallmentAmount: 0,
      secondInstallmentAmountRecieved: 0,
      thirdInstallmentAmount: 0,
      thirdInstallmentAmountRecieved: 0,
      fourthInstallmentAmount: 0,
      fourthInstallmentAmountRecieved: 0,
      firstInstallmentOverDue: false,
      secondInstallmentOverDue: false,
      thirdInstallmentOverDue: false,
      fourthInstallmentOverDue: false,
      userID: 0,
      courseIterationId: 0,
      courseID: 0,
      errorMessage: {},
      checkedBoxes: [],
      moduleList: [],
      Search: "",
      unfilteredList: [],
      moduleVisibleList: [],
      tmaqVisibleList: [],
      archievedDropDownList: [],
      resitArchievedDropDownList: [],
      completedDate: "",
      studentOnHold: false,
      onHoldStartDate: moment().format(dateFormat),
      onHoldEndDate: moment().format(dateFormat),
      isCourseExtended: false,
      numberOfDaysExtended: moment().format(dateFormat),
      createdOn: moment().format(dateFormat),
      extendedDaysAmount: 0,
      extendedDaysAmountPaidOn: "",
      showSpamImage: false,
      totalCourseFee: 0,
      hardCopyPaymentAmount: 0,
      hardCopyPaymentDate: "",
      openCommonUploadModal: false,
      paymentFor: 0,
      paymentAmount: "",
      paymentDate: "",
      paymentDue: "",
      showUpdateModel: false,
      advanceCourseEndDate: "",
      isAdvanceCourseEndDateChecked: false,
      advanceCourseFees: 0,
      advanceCourseDate: "",
    };
  }

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the lis of student based on the user id and also to get the student Iteration details based on the iteration id
  <summary>*/
  componentDidMount = async () => {
    try {
      const userID = this.props.match.params.userID;
      const courseIterationId = this.props.match.params.CourseIterationId;
      const courseId = this.props.match.params.courseID;
      let dateCompleted = "YYYY-MM-DD";
      this.setState({
        isLoading: true,
        userID: userID,
        courseIterationId: courseIterationId,
        courseID: courseId,
      });
      var response = await studentService.getStudentProfile(userID);
      if (response.status === 200) {
        this.setState({
          displayName: response.data.studentData.displayName,
          emailAddress: response.data.studentData.emailAddress,
          membershipNumber: response.data.studentData.membershipNumber,
          address: response.data.studentData.address,
          createdOn: response.data.studentData.createdOn,
        });
      } else if (response.status === 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      var enrollmentResponse =
        await adminstudentservice.GetCourseAndIterationDetailsForStudent(
          userID,
          courseIterationId
        );
      if (enrollmentResponse.status === 200) {
        if (
          moment
            .utc(enrollmentResponse.data.iterationCompletedDate)
            .isSame(minDate)
        ) {
          dateCompleted = "YYYY-MM-DD";
        } else {
          dateCompleted = moment(
            enrollmentResponse.data.iterationCompletedDate
          ).format("YYYY-MM-DD");
        }
        this.setState({
          courseTitle: enrollmentResponse.data.courseName,
          courseIteration: enrollmentResponse.data.courseIterationName,
          iterationCost: enrollmentResponse.data.iterationCost,
          startDate: moment(enrollmentResponse.data.startDate).format(
            "YYYY-MM-DD"
          ),
          endDate: moment(enrollmentResponse.data.endDate).format("YYYY-MM-DD"),
          completedDate: dateCompleted,
        });
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.getOnHoldDetails();
      this.installmentOverDue();

      this.getModuleList();
      this.getExtendedDate();

      this.getCourseFeeDetails();
      this.getModuleListForUpdate();
      // this.getAssinmentList();
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  getModuleListForUpdate = () => {
    // const{courseID,courseIterationId}=this.state;
    // var response = await admincourseservice.GetModuleListToAssignToStudent(courseID,courseIterationId);
    //  if (response.status == 200) {
    //     this.setState({
    //       UnitList: response.data,
    //       unFilteredUnitList: JSON.parse(JSON.stringify(response.data)),
    //       totalUnitList: response.data.length,
    //     });
    //   }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the student iteration installment details base on the iteration and student id
  <summary>*/
  getCourseFeeDetails = async () => {
    try {
      const { userID, courseIterationId } = this.state;
      var response =
        await admincourseservice.GetIterationFeesDetailsBasedOnStudentID(
          userID,
          courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          iterationCost: response.data.iterationCost,
          firstInstallmentAmount: response.data.firstInstallmentAmount,
          secondInstallmentAmount: response.data.secondInstallmentAmount,
          secondInstallmentAmountRecieved:
            response.data.secondInstallmentAmountRecieved,
          thirdInstallmentAmount: response.data.thirdInstallmentAmount,
          thirdInstallmentAmountRecieved:
            response.data.thirdInstallmentAmountRecieved,
          fourthInstallmentAmount: response.data.fourthInstallmentAmount,
          fourthInstallmentAmountRecieved:
            response.data.fourthInstallmentAmountRecieved,
          firstInstallmentReceived: moment(
            response.data.firstInstallmentDate
          ).format("YYYY-MM-DD"),
          secondInstallmentReceived: moment(
            response.data.secondInstallmentDate
          ).format("YYYY-MM-DD"),
          thirdInstallmentReceived: moment(
            response.data.thirdInstallmentDate
          ).format("YYYY-MM-DD"),
          fourthInstallmentReceived: moment(
            response.data.fourthInstallmentDate
          ).format("YYYY-MM-DD"),
          paymentPlanId: response.data.paymentPlanId,
        });
      }

      this.setmininstallmentDate();
    } catch (e) {
      return "";
    }
  };

  setmininstallmentDate = () => {
    if (moment.utc(this.state.firstInstallmentReceived).isSame(minDate)) {
      this.setState({
        firstInstallmentReceived: "YYYY-MM-DD",
      });
    }

    if (moment.utc(this.state.secondInstallmentReceived).isSame(minDate)) {
      this.setState({
        secondInstallmentReceived: "YYYY-MM-DD",
      });
    }

    if (moment.utc(this.state.thirdInstallmentReceived).isSame(minDate)) {
      this.setState({
        thirdInstallmentReceived: "YYYY-MM-DD",
      });
    }

    if (moment.utc(this.state.fourthInstallmentReceived).isSame(minDate)) {
      this.setState({
        fourthInstallmentReceived: "YYYY-MM-DD",
      });
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: If the student has been put on hold then that details are retrieved here
  <summary>*/
  getOnHoldDetails = async () => {
    try {
      const { userID, courseIterationId } = this.state;
      var response =
        await adminstudentservice.GetIterationOnHoldDetailsForStudent(
          userID,
          courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          studentOnHold: response.data.isOnHold,
          onHoldStartDate: moment(response.data.onHoldStartDate).format(
            "YYYY-MM-DD"
          ),
          onHoldEndDate: moment(response.data.onHoldEndDate).format(
            "YYYY-MM-DD"
          ),
          showSpamImage: response.data.isOnHold,
        });
      }
    } catch (e) {
      return "";
    }
  };
  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  closeUnitListModal = () => {
    this.setState(
      {
        showUpdateModel: false,
      },
      async () => {
        this.getModuleList();
      }
    );
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to get the extended date 
  <summary>*/
  getExtendedDate = async () => {
    try {
      var response =
        await admincourseservice.GetIterationExtendedDateForStudent(
          this.state.userID,
          this.state.courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          isCourseExtended: response.data.isExtendCourse,
          numberOfDaysExtended: moment(
            response.data.numberOfDaysCourseExtended
          ).format("YYYY-MM-DD"),
          extendedDaysAmount: response.data.extendedDaysAmount,
          extendedDaysAmountPaidOn: moment(
            response.data.extendedDaysAmountPaidOn
          ).format("YYYY-MM-DD"),
          createdOn: moment(response.data.createdOn).format("YYYY-MM-DD"),
          advanceCourseEndDate: moment(
            response.data.advanceCourseEndDate
          ).format("YYYY-MM-DD"),
          isAdvanceCourseEndDateChecked:
            response.data.advanceCourseEndDate == "" ? false : true,
          advanceCourseFees: response.data.advanceCourseFees,
        });
      }

      this.checkExtendedDate();
    } catch (e) {
      return "";
    }
  };

  checkExtendedDate = () => {
    if (moment.utc(this.state.numberOfDaysExtended).isSame(minDate)) {
      this.setState({
        numberOfDaysExtended: "",
      });
    }

    if (this.state.extendedDaysAmountPaidOn === minDate) {
      this.setState({
        extendedDaysAmountPaidOn: "",
      });
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is usde to get the installment details to check if there are any over due
  <summary>*/
  installmentOverDue = async () => {
    try {
      var response = await admincourseservice.GetInstallmentDetails(
        this.state.userID,
        this.state.courseIterationId
      );
      if (response.status === 200) {
        this.setState({
          firstInstallmentOverDue: response.data.firstInstallmentOverDue,
          secondInstallmentOverDue: response.data.secondInstallmentOverDue,
          thirdInstallmentOverDue: response.data.thirdInstallmentOverDue,
          fourthInstallmentOverDue: response.data.fourthInstallmentOverDue,
          totalCourseFee: response.data.total,
          hardCopyPaymentAmount: response.data.hardCopyPaymentAmount,
          hardCopyPaymentDate:
            (response.data.hardCopyPaymentDate === "" || response.data.hardCopyPaymentDate === null)
              ? ""
              : moment(response.data.hardCopyPaymentDate).format("YYYY-MM-DD"),
        });
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to get the list of modules
  <summary>*/
  getModuleList = async () => {
    try {
      var response =
        await admincourseservice.GetModuleDetailsForIterationBasedOnUserID(
            this.state.userID,
            this.state.courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          moduleList: response.data,
          unfilteredList: response.data,
        });
      }
    } catch (e) {
      return "";
    }
  };

  onDateCompletedChange = (e) => {
    let { name, value } = e.target;

    let date = moment(value).format(dateFormat);
    this.setState({
      [name]: date,
      errorMessage: {},
    });
  };

  onDateChange = (e) => {
    let { name, value } = e.target;

    let date = moment(value).format(dateFormat);
    this.setState({
      [name]: date,
      errorMessage: {},
    });
  };

  validate = (name, date) => {
    const {
      firstInstallmentReceived,
      secondInstallmentReceived,
      thirdInstallmentReceived,
      fourthInstallmentReceived,
    } = this.state;
    try {
      var isValid = true;
      let selectedDate = moment(date).format(dateFormat);
      if (name == "firstInstallmentReceived") {
        if (moment(secondInstallmentReceived).isBefore(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptyFirstInstallmentDate"] =
            helperText.manageEnrollment.worngFirstInstallmentDate;
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        }
      }
      if (name == "secondInstallmentReceived") {
        if (moment(thirdInstallmentReceived).isBefore(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptySecondInstallmentDate"] =
            helperText.manageEnrollment.worngSecondInstallmentDate;
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        } else if (moment(firstInstallmentReceived).isAfter(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptyFirstInstallmentDate"] =
            helperText.manageEnrollment.worngFirstInstallmentDate;
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        }
      }

      if (name == "thirdInstallmentReceived") {
        if (moment(fourthInstallmentReceived).isBefore(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptyThirdInstallmentDate"] =
            helperText.manageEnrollment.worngThirdInstallmentDate;
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        } else if (moment(secondInstallmentReceived).isAfter(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptySecondInstallmentDate"] =
            helperText.manageEnrollment.worngSecondInstallmentDate;
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        }
      }

      if (name == "fourthInstallmentReceived") {
        if (moment(thirdInstallmentReceived).isAfter(selectedDate)) {
          var error = this.state.errorMessage;
          error["emptyThirdInstallmentDate"] =
            "*third installment received date cannot be greater than fourth installment received date.";
          this.setState({ errorMessage: error });
          isValid = false;
          return isValid;
        }
      }

      return isValid;
    } catch (error) {
      return false;
    }
  };

  toggleCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.checkedBoxes;
      arr.push(item.uniqueId);

      this.setState({ checkedBoxes: arr });
    } else {
      let items = this.state.checkedBoxes.splice(
        this.state.checkedBoxes.indexOf(item.uniqueId),
        1
      );

      this.setState({
        checkedBoxes: items,
      });
    }
  };

  toggleCheckboxforModuleVisible = async (e, item) => {
    let moduleListfilter;
    if (e.target.checked) {
      let arr = this.state.moduleVisibleList;
      var row = {
        moduleId: item.moduleId,
        moduleVisible: e.target.checked,
      };
      arr.push(row);

      moduleListfilter = [...this.state.moduleList];
      moduleListfilter
        .filter((row) => row.moduleId == item.moduleId)
        .map((lst) => {
          return (lst.moduleVisible = true);
          // lst;
        });

      this.setState({ moduleVisibleList: arr, moduleList: moduleListfilter });
    } else {
      let items = this.state.moduleVisibleList.splice(
        this.state.moduleVisibleList.indexOf(item.moduleId),
        1
      );

      moduleListfilter = [...this.state.moduleList];
      moduleListfilter
        .filter((row) => row.moduleId == item.moduleId)
        .map((lst) => {
          return (lst.moduleVisible = false);
          // lst;
        });
      this.setState({
        moduleVisibleList: items,
        moduleList: moduleListfilter,
      });
    }
  };

  toggleCheckboxforTMAQVisible = async (e, item) => {
    let tmaqListfilter;
    if (e.target.checked) {
      let arr = this.state.tmaqVisibleList;
      var row = {
        moduleId: item.moduleId,
        tmaqVisible: e.target.checked,
      };
      arr.push(row);

      tmaqListfilter = [...this.state.moduleList];
      tmaqListfilter
        .filter((row) => row.moduleId == item.moduleId)
        .map((lst) => {
          return (lst.tmaqVisible = true);
          // lst;
        });

      this.setState({ tmaqVisibleList: arr, moduleList: tmaqListfilter });
    } else {
      let items = this.state.tmaqVisibleList.splice(
        this.state.tmaqVisibleList.indexOf(item.moduleId),
        1
      );

      tmaqListfilter = [...this.state.moduleList];
      tmaqListfilter
        .filter((row) => row.moduleId == item.moduleId)
        .map((lst) => {
          return (lst.tmaqVisible = false);
          // lst;
        });
      this.setState({
        tmaqVisibleList: items,
        moduleList: tmaqListfilter,
      });
    }
  };

  header = [
    {
      name: <th className="align-middle">QA</th>,
      //selector:,
      cell: (row) => (
        <td className="center">
          {" "}
          <img src={Qa} width="30px" />{" "}
        </td>
      ),
      width: "70px",
    },
    {
      name: (
        <th className="align-middle" style={{ width: "5px" }}>
          Module ID
        </th>
      ),
      //selector:,
      cell: (row) => <td className="center">{row.uniqueId}</td>,
      width: "100px",
      center: true,
    },
    {
      name: <th className="align-middle">Module Title</th>,
      //selector:,
      cell: (row) => <td className="left">{row.moduleName}</td>,
      left: true,
      grow: 3,
    },
    {
      name: <th className="align-middle">Resubmits</th>,
      //selector:,
      cell: (row) => <td>{row.numResit}</td>,
      width: "100px",
      center: true,
    },
    {
      name: <th className="align-middle">Module Visible</th>,
      //selector:,
      cell: (row) => (
        <td className="center tg-broze">
          <label class="conta">
            <input
              type="checkbox"
              id="check"
              name="check"
              defaultValue="check"
              checked={row.moduleVisible}
              onChange={(e) => this.toggleCheckboxforModuleVisible(e, row)}
            />

            <span
              class="checkbox center-align"
              style={{ marginTop: "-3px" }}
            ></span>
          </label>
        </td>
      ),
      center: true,
    },
    {
      name: <th className="align-middle">TMA-Q visible</th>,
      //selector:,
      cell: (row) => (
        <td className="center">
          <label class="conta">
            <input
              type="checkbox"
              id="check"
              name="check"
              defaultValue="check"
              checked={row.tmaqVisible}
              onChange={(e) => this.toggleCheckboxforTMAQVisible(e, row)}
            />

            <span
              class="checkbox center-align"
              style={{ marginTop: "-3px" }}
            ></span>
          </label>
        </td>
      ),
      center: true,
    },
    {
      name: <th className="align-middle">Update</th>,
      //selector:,
      cell: (row) => (
        <td className="center">
          {" "}
          <button
            type="button"
            className="btn save-button"
            onClick={(e) => this.saveModule(e, row)}
          >
            Save
          </button>{" "}
        </td>
      ),
    },
  ];

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    this.setState({
      checkedBoxes: state.selectedRows,
      isChanged: true,
    });
  };

  /* <summary>
  date: 18-08-2021
  Name: VD
  description: On click of Save this module is called
  <summary>*/
  saveModule = async (e, row) => {
    try {
      const {
        moduleVisibleList,
        tmaqVisibleList,
        userID,
        courseIterationId,
        moduleList,
      } = this.state;
      let isVisible;
      let istmaqVisible;
      isVisible = moduleVisibleList.find((item) => {
        if (item.moduleId == row.moduleId) {
          return item.moduleVisible;
        }
      });

      //if the true value is not changed
      if (isVisible == undefined) {
        isVisible = moduleList.find((item) => {
          if (item.moduleId == row.moduleId) {
            return item.moduleVisible;
          }
        });
      }

      istmaqVisible = tmaqVisibleList.find((item) => {
        if (item.moduleId == row.moduleId) {
          return item.tmaqVisible;
        }
      });

      //if the true value is not changed
      if (istmaqVisible == undefined) {
        istmaqVisible = moduleList.find((item) => {
          if (item.moduleId == row.moduleId) {
            return item.moduleVisible;
          }
        });
      }

      var studentModuleBridge = new StudentModuleBridgeModel();
      studentModuleBridge.studentID = userID;
      studentModuleBridge.moduleID = row.moduleId;
      studentModuleBridge.numResit = 0;
      studentModuleBridge.moduleVisible =
        isVisible == undefined ? false : isVisible.moduleVisible;
      studentModuleBridge.tmaqVisible =
        istmaqVisible == undefined ? false : istmaqVisible.tmaqVisible;
      studentModuleBridge.fileMediaID = 0;
      studentModuleBridge.courseIterationId = courseIterationId;

      var response = await admincourseservice.addEditStudentModuleBridge(
        studentModuleBridge
      );
      if (response.status === 200) {
        if (response.data === true) {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.moduleUpdated,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText: helperText.EditCourseIteration.moduleNotUpdated,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: When the admin decides to delete any course this method is called
  <summary>*/
  deleteSelectedCourses = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const { checkedBoxes } = this.state;

      if (this.validateCheckbox()) {
        var response = await admincourseservice.DeleteCourseIterations(
          checkedBoxes
        );
        if (response.status === 200) {
          this.setState({
            courseIterationList: response.data,
            checkedBoxes: [],
            popUpModalText: helperText.courseIteration.courseDeleted,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  validateCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes == "") isValid = false;

      if (checkedBoxes == "") {
        var error = this.state.errorMessage;
        error["emptyCheckbox"] =
          helperText.courseIteration.emptyiterationCheckbox;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (error) {}
  };

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();

    var filteredStudent = this.state.unfilteredList.filter((p) =>
      p.moduleName.toLowerCase().includes(inputValue)
    );

    this.setState({
      Search: event.target.value,
      moduleList: filteredStudent,
    });
  };

  disbandStudent = async (e) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to remove the student?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmDisband(),
        },
        {
          label: "No",
          // onClick: { onClose },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: When the admin confirms to disband the student this method is used
  <summary>*/
  confirmDisband = async () => {
    try {
      const { userID, courseIterationId, courseID } = this.state;
      var response = await adminstudentservice.RemoveStudenFromIteration(
        userID,
        courseIterationId
      );
      if (response.status === 200) {
        if (response.data === true) {
          history.push(`/EditCourseIteration/${courseIterationId}/${courseID}`);
        } else {
          this.setState({
            popUpModalText: helperText.manageEnrollment.cannotRemoveStudent,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 02-09-2021
  Name: VD
  description: To save the Assignment payment details
  <summary>*/
  saveResubmissionCharges = async (item) => {
    try {
      const { userID, courseIterationId } = this.state;
      this.setState({
        isLoading: true,
      });
      var studentModuleBridge = new ResubmissionAssignmentPaymentModel();
      studentModuleBridge.assignmentTwoAmount = item.assignmentTwoAmount;
      studentModuleBridge.assignmentTwoDueDate = item.assignmentTwoDueDate;
      studentModuleBridge.assignmentTwoWaveOffCharges =
        item.assignmentTwoWaveOffCharges;
      studentModuleBridge.assignmentThreeAmount = item.assignmentThreeAmount;
      studentModuleBridge.assignmentThreeDueDate = item.assignmentThreeDueDate;
      studentModuleBridge.assignmentThreeWaveOffCharges =
        item.assignmentThreeWaveOffCharges;
      studentModuleBridge.studentModuleBridgeID = item.studentModuleBridgeID;
      studentModuleBridge.assignmentTwoAmountPaidDate =
        item.assignmentTwoAmountPaidDate;
      studentModuleBridge.assignmentThreeAmountPaidDate =
        item.assignmentThreeAmountPaidDate;

      var response = await admincourseservice.AddEditAssignmentPayment(
        studentModuleBridge
      );
      if (response.status === 200) {
        this.setState({
          popUpModalText:
            helperText.manageEnrollment.updateSubmmissionAmountAndDate,
          showPopupModal: true,
          messageType: MessageType.Success,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 31-08-2021
  Name: VD
  description: This is used when you want to put the student on hold 
  <summary>*/
  onHold = async (e) => {
    let { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  onHoldDateChange = (e) => {
    let { name, value } = e.target;

    let date = moment(value).format(dateFormat);
    this.setState({
      [name]: date,
      errorMessage: {},
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This method is called when the used wants to save the onhold date
  <summary>*/
  saveOnHoldDate = async () => {
    try {
      const {
        studentOnHold,
        onHoldStartDate,
        onHoldEndDate,
        userID,
        courseIterationId,
      } = this.state;
      var enddateValue = studentOnHold
        ? onHoldEndDate
        : onHoldStartDate == ""
        ? onHoldStartDate
        : onHoldStartDate < currentDate
        ? currentDate
        : onHoldStartDate;
      var response =
        await adminstudentservice.AddEditIterationOnHoldDateBasedOnStudenID(
          studentOnHold,
          onHoldStartDate,
          enddateValue,
          userID,
          courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          popUpModalText: helperText.manageEnrollment.studentOnHold,
          showPopupModal: true,
          showSpamImage: true,
          messageType: MessageType.Success,
        });
      }
    } catch (e) {
      return "";
    }
  };

  clearOnHoldDate = () => {
    this.setState({
      studentOnHold: false,
      onHoldStartDate: "",
      onHoldEndDate: "",
    });
  };

  OnEventChange(event) {
    const value = event.target.value;
    let date = moment(value).format(dateFormat);
    this.setState({
      numberOfDaysExtended: date,
      selectedIndex: event.target.selectedIndex,
      errorMessage: {},
    });
  }

  onChangeNumber = (e) => {
    var value = 0;
    if (e.target.value < 0) {
      value = 0;
    } else {
      value = e.target.value;
    }
    const { name } = e.target;
    this.setState({
      [name]: value,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to save the Hard Copy amount
  <summary>*/
  saveHardcopyAmount = async (e) => {
    try {
      var value = 0;
      if (e.target.value < 0) {
        value = 0;
      } else {
        value = e.target.value;
      }
      const { name } = e.target;
      this.setState({
        [name]: value,
      });

      this.setState({
        errorMessage: {},
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: When the admin decides to extend the Date this method is called
  <summary>*/
  saveExtendedDays = async () => {
    try {
      const {
        isCourseExtended,
        numberOfDaysExtended,
        extendedDaysAmount,
        userID,
        courseIterationId,
      } = this.state;

      //if (this.validateExtendedCourse()) {
      var response =
        await admincourseservice.addEditIterationExtendDateForStudent(
          isCourseExtended,
          numberOfDaysExtended,
          extendedDaysAmount,
          userID,
          courseIterationId
        );
      if (response.status === 200) {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.manageEnrollment.courseExtended,
          messageType: MessageType.Success,
        });
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.manageEnrollment.courseExtendError,
          messageType: MessageType.Failure,
        });
      }
      //}
    } catch (e) {
      return "";
    }
  };

  validateExtendedCourse = () => {
    try {
      const { numberOfDaysExtended, extendedDaysAmount } = this.state;
      var isValid = true;

      if (numberOfDaysExtended == "") {
        var error = this.state.errorMessage;
        error["emptyNumberOfDaysExtended"] =
          helperText.manageEnrollment.emptyExtendedDate;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      if (extendedDaysAmount == 0) {
        var error = this.state.errorMessage;
        error["emptyExtendedDaysAmount"] =
          helperText.createCourseIteration.emptyIterationCost;
        this.setState({ errorMessage: error });
        isValid = false;
        return isValid;
      }

      return isValid;
    } catch (error) {}
  };

  onChangeAssignmentTwoAmount = (e, itemId) => {
    var value = 0;
    if (e.target.value < 0) {
      value = 0;
    } else {
      value = e.target.value;
    }
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId === itemId) {
        item.assignmentTwoAmount = value;
      }
      return item;
    });
    this.setState({
      moduleList: newModuleList,
    });
    //assignmentDetailsToSave

    // const { name } = e.target;
    // this.setState({
    //   [name]: value,
    // });
  };
  onAssignmentTwoDueDateChange = (e, itemId) => {
    var selectedDate = e.target.value;
    const { name, value } = e.target;
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId === itemId) {
        item.assignmentTwoDueDate = value;
      }
      return item;
    });

    this.setState({
      moduleList: newModuleList,
    });
  };

  onAssignmentTwoPaymentSubmissionChange = (e, itemId) => {
    const { name, value } = e.target;
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId == itemId) {
        item.assignmentTwoAmountPaidDate = value;
      }
      return item;
    });

    this.setState({
      moduleList: newModuleList,
    });
  };

  onChangeAssignmentThreeAmount = (e, itemId) => {
    var value = 0;
    if (e.target.value < 0) {
      value = 0;
    } else {
      value = e.target.value;
    }
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId === itemId) {
        item.assignmentThreeAmount = value;
      }
      return item;
    });
    this.setState({
      moduleList: newModuleList,
    });
  };

  onAssignmentThreeDueDateChange = (e, itemId) => {
    var selectedDate = e.target.value;
    const { name, value } = e.target;
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId === itemId) {
        item.assignmentThreeDueDate = value;
      }
      return item;
    });

    this.setState({
      moduleList: newModuleList,
    });
  };

  onAssignmentThreePaymentSubmissionChange = (e, itemId) => {
    var selectedDate = e.target.value;
    const { name, value } = e.target;
    const newModuleList = this.state.moduleList.map((item) => {
      if (item.moduleId === itemId) {
        item.assignmentThreeAmountPaidDate = value;
      }
      return item;
    });

    this.setState({
      moduleList: newModuleList,
    });
  };

  toggleWaveOffCharges = (e, itemId) => {
    let checked = e.target.checked;
    const newModuleList = this.state.moduleList.map((list) => {
      if (list.moduleId == itemId) {
        if (list.assignmentTwoSubmittedOn === "") {
          list.assignmentTwoWaveOffCharges = checked;
        } else {
          list.assignmentThreeWaveOffCharges = checked;
        }
      }
      return list;
    });

    this.setState({
      moduleList: newModuleList,
    });
  };

  OpenPaymentDetailsModal = (val) => {
    this.setState({
      paymentFor: val,
      openPaymentDetailsModal: true,
    });
  };

  ClosePaymentDetailsModal = () => {
    this.setState({
      openPaymentDetailsModal: false,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: This method is called to get the payment details from xero
  <summary>*/
  getPaymentDetailsFromXero = async (Amount, DoneDate, DueDate) => {
    try {
      if (this.state.paymentFor === 1) {
        //Hardcopy
        this.setState({
          hardCopyPaymentDate: DoneDate,
          hardCopyPaymentAmount: Amount,
        });
        const { userID, courseIterationId } = this.state;
      } else if (this.state.paymentFor === 2) {
        //Extended date
        this.setState({
          extendedDaysAmountPaidOn: DoneDate,
          extendedDaysAmount: Amount,
        });
        const { userID, courseIterationId } = this.state;
        var response =
          await admincourseservice.AddEditIterationExtendAmountandDatePaidByStudent(
            userID,
            courseIterationId,
            Amount,
            DoneDate
          );
      } else if (this.state.paymentFor === 3) {
        //Second installment Date
        this.setState({
          secondInstallmentReceived: moment(DoneDate).format("YYYY-MM-DD"),
          secondInstallmentAmountRecieved: Amount,
        });
      } else if (this.state.paymentFor === 4) {
        //third installment Date
        this.setState({
          thirdInstallmentAmountRecieved: Amount,
          thirdInstallmentReceived: moment(DoneDate).format("YYYY-MM-DD"),
        });
      } else if (this.state.paymentFor === 5) {
        //fourth installment Date
        this.setState({
          fourthInstallmentAmountRecieved: Amount,
          fourthInstallmentReceived: moment(DoneDate).format("YYYY-MM-DD"),
        });
      } else if (this.state.paymentFor === 6) {
        //Advance course Fees
        this.setState({
          advanceCourseFees: Amount,
          advanceCourseDate: DoneDate,
        });
      }
    } catch (e) {
      return "";
    }
  };

  showUpdatePopUp = () => {
    this.setState({
      showUpdateModel: true,
    });
  };

  onAmountChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (name == "secondInstallmentAmountRecieved") {
      this.setState({ paymentFor: 3 });
    } else if (name == "thirdInstallmentAmountRecieved") {
      this.setState({ paymentFor: 4 });
    } else {
      this.setState({ paymentFor: 5 });
    }
  };

  onadvanceCourseEndDateChange = (e) => {
    let { name, value } = e.target;

    let date = moment(value).format(dateFormat);
    this.setState({
      [name]: date,
      errorMessage: {},
    });
  };

  /* <summary>
  date: 17-01-2022
  Name: VD
  description: This is used to Add or Edit the end date of the advance course 
  <summary>*/
  saveAdvanceCourseEndDate = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        userID,
        courseIterationId,
        advanceCourseEndDate,
        isAdvanceCourseEndDateChecked,
      } = this.state;
      var response = await admincourseservice.addEditAdvanceCourseEndDate(
        userID,
        courseIterationId,
        isAdvanceCourseEndDateChecked == true ? advanceCourseEndDate : ""
      );
      if (response.status === 200) {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.manageEnrollment.advanceCourseEndDateSave,
          messageType: MessageType.Success,
          advanceCourseEndDate:
            isAdvanceCourseEndDateChecked == false ? "" : advanceCourseEndDate,
        });
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.manageEnrollment.advanceCourseEndDateError,
          messageType: MessageType.Failure,
        });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      return "";
    }
  };

  saveAdvanceCourseFees = async (e) => {
    try {
      var value = 0;
      if (e.target.value < 0) {
        value = 0;
      } else {
        value = e.target.value;
      }
      const { name } = e.target;
      this.setState({
        advanceCourseFees: value,
      });

      this.setState({
        errorMessage: {},
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 17-02-2022
  Name: VD
  description: This is used as a Common save function to save all the installment details as well as additional Charges details and Date Completed
  <summary>*/
  addEditManageEnrollmentPage = async () => {
    const {
      completedDate,

      hardCopyPaymentAmount,
      hardCopyPaymentDate,

      advanceCourseFees,
      advanceCourseDate,

      firstInstallmentReceived,

      secondInstallmentReceived,
      secondInstallmentAmountRecieved,

      thirdInstallmentReceived,
      thirdInstallmentAmountRecieved,

      fourthInstallmentReceived,
      fourthInstallmentAmountRecieved,

      userID,
      courseIterationId,
    } = this.state;

    var courseIterationPaymentDetails =
      new CourseIterationPaymentDetailsModel();
    courseIterationPaymentDetails.iterationCompletedDate =
      completedDate == "YYYY-MM-DD" ? "" : completedDate;
    courseIterationPaymentDetails.hardCopyPaymentAmount = hardCopyPaymentAmount;
    courseIterationPaymentDetails.hardCopyPaymentDate = hardCopyPaymentDate;
    courseIterationPaymentDetails.advanceCourseFees = advanceCourseFees;
    courseIterationPaymentDetails.advanceCourseDate = advanceCourseDate;
    courseIterationPaymentDetails.firstInstallmentReceived =
      firstInstallmentReceived == "YYYY-MM-DD" ? "" : firstInstallmentReceived;
    courseIterationPaymentDetails.secondInstallmentReceived =
      secondInstallmentReceived == "YYYY-MM-DD"
        ? ""
        : secondInstallmentReceived;
    courseIterationPaymentDetails.secondInstallmentAmountRecieved =
      secondInstallmentAmountRecieved;
    courseIterationPaymentDetails.thirdInstallmentReceived =
      thirdInstallmentReceived == "YYYY-MM-DD" ? "" : thirdInstallmentReceived;
    courseIterationPaymentDetails.thirdInstallmentAmountRecieved =
      thirdInstallmentAmountRecieved;
    courseIterationPaymentDetails.fourthInstallmentReceived =
      fourthInstallmentReceived == "YYYY-MM-DD"
        ? ""
        : fourthInstallmentReceived;
    courseIterationPaymentDetails.fourthInstallmentAmountRecieved =
      fourthInstallmentAmountRecieved;
    courseIterationPaymentDetails.studentId = userID;
    courseIterationPaymentDetails.courseIterationId = courseIterationId;

    this.setState({ isLoading: true });
    var response = await admincourseservice.addEditManageEnrollmentPage(
      courseIterationPaymentDetails
    );
    if (response.status === 200) {
      if (response.data === true) {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.manageEnrollment.dataSaved,
          messageType: MessageType.Success,
        });
      }
    } else {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.manageEnrollment.dateSaveError,
        messageType: MessageType.Failure,
      });
    }

    this.setState({ isLoading: false });
  };

  deleteInstallmentDate = (installmentName) => {
    var name = installmentName;
    this.setState({
      [name]: "",
    });
  };

  deleteAdditionalCharges = (installmentName) => {
    var name = installmentName;
    this.setState({
      [name]: 0,
    });

    if (name === "hardCopyPaymentAmount") {
      this.setState({ hardCopyPaymentDate: "" });
    } else {
      this.setState({ advanceCourseDate: "" });
    }
  };

  clearadvanceCourseEndDate = () => {
    this.setState({
      advanceCourseEndDate: "",
      isAdvanceCourseEndDateChecked: false,
    });
  };

  clearnumberOfDaysExtended = () => {
    this.setState({
      isCourseExtended: false,
      numberOfDaysExtended: "",
      extendedDaysAmount: 0,
    });
  };
  render() {
    const {
      membershipNumber,
      displayName,
      emailAddress,
      popUpModalText,
      showPopupModal,
      messageType,
      iterationCost,
      startDate,
      endDate,
      isLoading,
      firstInstallmentReceived,
      secondInstallmentReceived,
      thirdInstallmentReceived,
      fourthInstallmentReceived,
      firstInstallmentOverDue,
      secondInstallmentOverDue,
      thirdInstallmentOverDue,
      fourthInstallmentOverDue,
      paymentPlanId,
      completedDate,
      userID,
      courseIterationId,
      courseID,
      studentOnHold,
      onHoldStartDate,
      onHoldEndDate,
      isCourseExtended,
      numberOfDaysExtended,
      createdOn,
      extendedDaysAmount,
      moduleList,
      showSpamImage,
      totalCourseFee,
      hardCopyPaymentAmount,
      hardCopyPaymentDate,
      firstInstallmentAmount,
      secondInstallmentAmount,
      secondInstallmentAmountRecieved,
      thirdInstallmentAmount,
      thirdInstallmentAmountRecieved,
      fourthInstallmentAmount,
      fourthInstallmentAmountRecieved,
      showUpdateModel,
      unfilteredList,
      advanceCourseEndDate,
      isAdvanceCourseEndDateChecked,
      advanceCourseFees,
      advanceCourseDate,
    } = this.state;
    return (
      <div className="overall-font" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Courses" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h5 className="mt-3 mb-3 gray">
                Manage Course Iteration Enrollment
              </h5>
            </div>
            <div className="col-lg-6 col-sm-12" style={{ paddingTop: "20px" }}>
              <img src={Overdue} className="over-due" width="35px" />
              <span>Outstanding installments </span>
              {/* <a className="info-icon" title="More informtion">
                <img src={info} />
              </a> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="table-responsive-sm" style={{ border: "none" }}>
                <table
                  className="table table-striped"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color">
                        <h5 className="manageEnrollment-h5">Student Details</h5>
                      </td>
                      <td className="blue-color left" />
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Student Number: </label>
                      </td>
                      <td className="white-color left">{membershipNumber}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Student Name:</label>
                      </td>
                      <td className="white-color left">{displayName}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Student Email:</label>
                      </td>
                      <td className="white-color left">{emailAddress} </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color left">
                        <button
                          type="button"
                          className="btn view-student-info manage-button-align"
                          onClick={() =>
                            history.push(`/EnrolledCourses/${userID}`)
                          }
                        >
                          View Student Info
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Total Net Course Fee:</label>
                      </td>
                      <td className="white-color left span-background">
                        <span>£ {totalCourseFee} </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color left">
                        {" "}
                        <button
                          type="button"
                          className="btn remove-button remove-button-align"
                          //onClick={()=>this.disbandStudent()}
                          onClick={(e) => {
                            this.disbandStudent();
                          }}
                        >
                          Remove Student From Course
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <h5 className="manageEnrollment-h5">On-Hold Mode</h5>
                      </td>
                      <td className="blue-color left  border-color" />
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td
                        className="white-color center checkbox-top"
                        colSpan={4}
                        style={{ display: "flex", borderTop: "none" }}
                      >
                        <label className="conta">
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            checked={studentOnHold}
                            name="studentOnHold"
                            onChange={(e) => this.onHold(e)}
                          />
                          <span className="checkbox center-align" />
                        </label>
                        <span
                          className="span-class"
                          style={{ paddingLeft: "30px" }}
                        >
                          Set student on-hold mode{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Start Date:</label>
                      </td>
                      <td
                        className="white-color left"
                        style={{ borderTop: "none" }}
                      >
                        <input
                          type="date"
                          name="onHoldStartDate"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          min={startDate}
                          value={onHoldStartDate}
                          onChange={this.onHoldDateChange}
                          style={{ marginRight: "25px" }}
                        />
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                          style={{ left: "3px" }}
                        />
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> End Date:</label>
                      </td>
                      <td
                        className="white-color left"
                        style={{ borderTop: "none" }}
                      >
                        <input
                          type="date"
                          name="onHoldEndDate"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          min={onHoldStartDate}
                          value={
                            studentOnHold
                              ? onHoldEndDate
                              : onHoldEndDate == ""
                              ? ""
                              : onHoldStartDate < currentDate
                              ? currentDate
                              : onHoldStartDate
                          }
                          onChange={this.onHoldDateChange}
                          style={{ marginRight: "25px" }}
                        />
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                          style={{ left: "3px" }}
                        />
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color"></td>
                      <td className="white-color center" colSpan={1}>
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={this.clearOnHoldDate}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={this.saveOnHoldDate}
                        >
                          Save &amp; Notify
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="table-responsive-sm" style={{ border: "none" }}>
                <table
                  className="table table-striped"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color" width="40%">
                        <h5 className="manageEnrollment-h5">
                          Enrollment Details
                        </h5>
                      </td>
                      <td className="blue-color left" />
                      <td className="blue-color left" />
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Date Enrolled: </label>
                      </td>
                      <td className="white-color left">{createdOn}</td>
                      <td className="white-color left"> </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Start Date:</label>
                      </td>
                      <td className="white-color center span-date-box">
                        <input
                          type="date"
                          name="begin"
                          readOnly="true"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          value={startDate}
                        />
                      </td>
                      <td className="white-color left">
                        {" "}
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>End Date:</label>
                      </td>
                      <td className="white-color center">
                        <input
                          type="date"
                          name="begin"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          value={endDate}
                          readOnly="true"
                        />{" "}
                      </td>
                      <td className="white-color left">
                        {" "}
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>{" "}
                        {showSpamImage ? (
                          <img
                            src={spam}
                            width="30px"
                            style={{ paddingLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Date Completed:</label>
                      </td>
                      <td className="white-color center">
                        <input
                          type="date"
                          name="completedDate"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          value={completedDate}
                          onChange={this.onDateCompletedChange}
                        />{" "}
                      </td>
                      <td className="white-color left">
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />{" "}
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left border-color">
                        <h5 className="manageEnrollment-h5">Advance Course</h5>
                      </td>
                      <td className="blue-color" colSpan={4}></td>
                    </tr>
                    <tr>
                      <td className="blue-color left border-color"></td>
                      <td
                        className="white-color center checkbox-top"
                        colSpan={4}
                        style={{ borderTop: "none" }}
                      >
                        <label className="conta">
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            checked={isAdvanceCourseEndDateChecked}
                            name="isAdvanceCourseEndDateChecked"
                            onChange={(e) => this.onHold(e)}
                          />
                          <span className="checkbox addCheckbox" />
                        </label>
                        <span
                          className="span-class-Additional"
                          style={{ fontWeight: "bold" }}
                        >
                          Additional days for Advance course{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>End Date:</label>
                      </td>
                      <td className="white-color center">
                        <input
                          type="date"
                          name="advanceCourseEndDate"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          min={currentDate}
                          value={advanceCourseEndDate}
                          onChange={this.onadvanceCourseEndDateChange}
                        />{" "}
                      </td>
                      <td className="white-color left">
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />{" "}
                        <span className="date-mark date-image">
                          {" "}
                          (dd/mm/yyyy){" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color center" colSpan={2}>
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={() => this.clearadvanceCourseEndDate()}
                        >
                          Clear
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={this.saveAdvanceCourseEndDate}
                        >
                          Save &amp; Notify
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left border-color">
                        <h5 className="manageEnrollment-h5">Extended Date</h5>
                      </td>
                      <td className="blue-color" colSpan={4}></td>
                    </tr>
                    <tr>
                      <td className="blue-color left border-color"></td>
                      <td
                        className="white-color center checkbox-top"
                        colSpan={4}
                        style={{ borderTop: "none" }}
                      >
                        <label className="conta">
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            checked={isCourseExtended}
                            name="isCourseExtended"
                            onChange={(e) => this.onHold(e)}
                          />
                          <span className="checkbox addCheckbox" />
                        </label>
                        <span
                          className="span-class-Additional"
                          style={{ fontWeight: "bold" }}
                        >
                          Additional days to complete course{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td
                        className="white-color left"
                        colSpan={4}
                        style={{
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <input
                          type="date"
                          name="numberOfDaysExtended"
                          placeholder="dd-mm-yyyy"
                          value={numberOfDaysExtended}
                          min={currentDate}
                          style={{ marginLeft: "55px" }}
                          onChange={this.OnEventChange.bind(this)}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptyNumberOfDaysExtended
                          ? "contents"
                          : "none",
                      }}
                    >
                      {" "}
                      <td className="blue-color left  border-color"></td>
                      <td
                        colSpan={3}
                        style={{ textAlign: "left", color: "red" }}
                      >
                        {this.state.errorMessage.emptyNumberOfDaysExtended}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td
                        className="white-color span-box left"
                        colSpan={4}
                        style={{ borderTop: "none" }}
                      >
                        {" "}
                        <img
                          className={success}
                          src={success}
                          width="25px"
                          style={{ marginRight: "15px" }}
                        />
                        <span>
                          £{" "}
                          <input
                            type="Number"
                            name="extendedDaysAmount"
                            value={extendedDaysAmount}
                            onChange={this.onChangeNumber}
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                          />{" "}
                        </span>
                        <label className="payment-date">
                          {/* (Paid on {extendedDaysAmountPaidOn}) */}
                        </label>
                        <img
                          className={document}
                          src={document}
                          width="25px"
                          marginLeft="10px"
                          onClick={() => this.OpenPaymentDetailsModal(2)}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyExtendedDaysAmount
                          ? "contents"
                          : "none",
                      }}
                    >
                      {" "}
                      <td className="blue-color left  border-color"></td>
                      <td
                        colSpan={3}
                        style={{ textAlign: "left", color: "red" }}
                      >
                        {this.state.errorMessage.emptyExtendedDaysAmount}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color center" colSpan={2}>
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={() => this.clearnumberOfDaysExtended()}
                        >
                          Clear
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={this.saveExtendedDays}
                        >
                          Save &amp; Notify
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="table-responsive-sm" style={{ border: "none" }}>
                <table
                  className="table table-striped"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color">
                        <h5 className="manageEnrollment-h5">
                          Additional Charges
                        </h5>
                      </td>
                      <td className="blue-color left" />
                    </tr>
                    <tr>
                      <td className="blue-color left border-color">
                        <label>Hard Copies Shipment:</label>
                      </td>
                      <td
                        className="white-color span-box left"
                        colSpan={4}
                        style={{ borderTop: "none" }}
                      >
                        {" "}
                        <img
                          className={success}
                          src={success}
                          width="25px"
                          style={{ marginRight: "8px" }}
                        />
                        <span>
                          £{" "}
                          <input
                            type="Number"
                            name="hardCopyPaymentAmount"
                            value={hardCopyPaymentAmount}
                            onChange={this.saveHardcopyAmount}
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                        <label className="payment-date">
                          {" "}
                          {hardCopyPaymentDate}
                        </label>
                        <img
                          src={document}
                          style={{ width: "25px", marginLeft: "10px" }}
                          onClick={() => this.OpenPaymentDetailsModal(1)}
                        />
                        <img
                          src={Deleteicon}
                          className="delete-icon"
                          width="20px"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            this.deleteAdditionalCharges(
                              "hardCopyPaymentAmount"
                            )
                          }
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left border-color">
                        <label>Advance Course Fees:</label>
                      </td>
                      <td
                        className="white-color span-box left"
                        colSpan={4}
                        style={{ borderTop: "none" }}
                      >
                        {" "}
                        <img
                          className={success}
                          src={success}
                          width="25px"
                          style={{ marginRight: "8px" }}
                        />
                        <span>
                          £{" "}
                          <input
                            type="Number"
                            name="advanceCourseFees"
                            value={advanceCourseFees}
                            onChange={this.saveAdvanceCourseFees}
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                        <label className="payment-date">
                          {" "}
                          {/* {advanceCourseDate} */}
                        </label>
                        <img
                          src={document}
                          style={{ width: "25px", marginLeft: "10px" }}
                          onClick={() => this.OpenPaymentDetailsModal(6)}
                        />
                        <img
                          src={Deleteicon}
                          className="delete-icon"
                          width="20px"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            this.deleteAdditionalCharges("advanceCourseFees")
                          }
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="table-responsive-sm" style={{ border: "none" }}>
                <table
                  className="table table-striped manageEnrollment-installments"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color">
                        <h5 className="manageEnrollment-h5">
                          Course Fee Details
                        </h5>
                      </td>
                      <td className="blue-color left" />
                      <td className="blue-color left"> </td>
                      <td className="blue-color left"> </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Total Due: </label>
                      </td>
                      <td className="white-color left">£ {iterationCost}</td>
                      <td className="white-color left" colSpan={3}>
                        {" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        1st Installment Received :
                      </td>
                      <td className="white-color left">
                        {firstInstallmentOverDue ? (
                          <img
                            src={Overdue}
                            width="25px"
                            className="over-due-class"
                            style={{ left: "0px" }}
                          />
                        ) : (
                          <img
                            src={success}
                            width="25px"
                            className="over-due-class"
                            style={{ left: "0px" }}
                          />
                        )}
                      </td>
                      <td className="white-color left" colSpan={3}>
                        <input
                          type="date"
                          name="firstInstallmentReceived"
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          min="1997-01-01"
                          max="2030-12-31"
                          value={firstInstallmentReceived}
                          onChange={this.onDateChange}
                        />
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />
                        <span className="date-mark date-image">(dd/mm/yy)</span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage
                          .emptyFirstInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      {" "}
                      <td className="blue-color left  border-color"></td>
                      <td
                        colSpan={3}
                        style={{ textAlign: "left", color: "red" }}
                      >
                        {this.state.errorMessage.emptyFirstInstallmentDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color left" colSpan={4}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          £ {firstInstallmentAmount}{" "}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  {paymentPlanId >= 2 ? (
                    <tbody className="customtbody">
                      <tr>
                        <td className="blue-color left  border-color">
                          2nd Installment Received:
                        </td>
                        <td className="white-color left">
                          {secondInstallmentOverDue ? (
                            <img
                              src={Overdue}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          ) : (
                            <img
                              src={success}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {showSpamImage ? <img src={spam} width="30px" /> : ""}
                        </td>
                        <td className="white-color left" colSpan={3}>
                          <input
                            type="date"
                            name="secondInstallmentReceived"
                            placeholder="dd-mm-yyyy"
                            defaultValue
                            min={firstInstallmentReceived}
                            max="2030-12-31"
                            value={secondInstallmentReceived}
                            onChange={this.onDateChange}
                            //style={{width:'40%'}}
                          />
                          <img
                            src={Calendar}
                            className="calendar1 calendar-class"
                            width="30px"
                          />
                          <span className="date-mark date-image">
                            (dd/mm/yy)
                          </span>
                          <img
                            src={Deleteicon}
                            className="delete-icon"
                            width="20px"
                            style={{ marginLeft: "10px" }}
                            onClick={() =>
                              this.deleteInstallmentDate(
                                "secondInstallmentReceived"
                              )
                            }
                          />{" "}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: this.state.errorMessage
                            .emptySecondInstallmentDate
                            ? "contents"
                            : "none",
                        }}
                      >
                        <td className="blue-color left  border-color"></td>
                        <td
                          colSpan={3}
                          style={{ textAlign: "left", color: "red" }}
                        >
                          {this.state.errorMessage.emptySecondInstallmentDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue-color left  border-color"></td>
                        <td className="white-color left ">
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            £ {secondInstallmentAmount}
                          </span>
                        </td>
                        <td className="white-color left" colSpan={3}>
                          <span style={{ marginLeft: "-15px" }}>
                            £{" "}
                            <input
                              type="Number"
                              name="secondInstallmentAmountRecieved"
                              value={secondInstallmentAmountRecieved}
                              onChange={this.onAmountChange}
                              style={{ width: "40%", marginLeft: "5px" }}
                            />
                          </span>
                          <img
                            src={document}
                            style={{ width: "25px", marginLeft: "10px" }}
                            onClick={() => this.OpenPaymentDetailsModal(3)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                  {paymentPlanId >= 3 ? (
                    <tbody className="customtbody">
                      <tr>
                        <td
                          className="blue-color left  border-color"
                          width="25%"
                        >
                          3rd Installment Received :
                        </td>
                        <td className="white-color left">
                          {thirdInstallmentOverDue ? (
                            <img
                              src={Overdue}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          ) : (
                            <img
                              src={success}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {showSpamImage ? <img src={spam} width="30px" /> : ""}
                        </td>
                        <td className="white-color left" colSpan={3}>
                          <input
                            type="date"
                            name="thirdInstallmentReceived"
                            placeholder="dd-mm-yyyy"
                            defaultValue
                            min={secondInstallmentReceived}
                            max="2030-12-31"
                            value={thirdInstallmentReceived}
                            onChange={this.onDateChange}
                            style={{ width: "40%" }}
                          />
                          <img
                            src={Calendar}
                            className="calendar1 calendar-class"
                            width="30px"
                          />
                          <span className="date-mark date-image">
                            (dd/mm/yy)
                          </span>
                          <img
                            src={Deleteicon}
                            className="delete-icon"
                            width="20px"
                            style={{ marginLeft: "10px" }}
                            onClick={() =>
                              this.deleteInstallmentDate(
                                "thirdInstallmentReceived"
                              )
                            }
                          />{" "}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: this.state.errorMessage
                            .emptyThirdInstallmentDate
                            ? "contents"
                            : "none",
                        }}
                      >
                        <td className="blue-color left  border-color"></td>
                        <td
                          colSpan={3}
                          style={{ textAlign: "left", color: "red" }}
                        >
                          {this.state.errorMessage.emptyThirdInstallmentDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue-color left  border-color" />
                        <td className="white-color left ">
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            £ {thirdInstallmentAmount}{" "}
                          </span>
                        </td>
                        <td className="white-color left" colSpan={3}>
                          {" "}
                          <span style={{ marginLeft: "-15px" }}>
                            £{" "}
                            <input
                              type="Number"
                              name="thirdInstallmentAmountRecieved"
                              value={thirdInstallmentAmountRecieved}
                              onChange={this.onAmountChange}
                              style={{ width: "40%", marginLeft: "5px" }}
                            />
                          </span>
                          <img
                            src={document}
                            style={{ width: "25px", marginLeft: "10px" }}
                            onClick={() => this.OpenPaymentDetailsModal(4)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                  {paymentPlanId >= 4 ? (
                    <tbody className="customtbody">
                      <tr>
                        <td
                          className="blue-color left  border-color"
                          width="25%"
                        >
                          4th Installment Received :
                        </td>
                        <td className="white-color left">
                          {fourthInstallmentOverDue ? (
                            <img
                              src={Overdue}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          ) : (
                            <img
                              src={success}
                              width="25px"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {showSpamImage ? <img src={spam} width="30px" /> : ""}
                        </td>
                        <td className="white-color left" colSpan={3}>
                          <input
                            type="date"
                            name="fourthInstallmentReceived"
                            placeholder="dd-mm-yyyy"
                            defaultValue
                            min={thirdInstallmentReceived}
                            max="2030-12-31"
                            value={fourthInstallmentReceived}
                            onChange={this.onDateChange}
                            style={{ width: "40%" }}
                          />
                          <img
                            src={Calendar}
                            className="calendar1 calendar-class"
                            width="30px"
                          />
                          <span className="date-mark date-image">
                            (dd/mm/yy)
                          </span>
                          <img
                            src={Deleteicon}
                            className="delete-icon"
                            width="20px"
                            style={{ marginLeft: "10px" }}
                            onClick={() =>
                              this.deleteInstallmentDate(
                                "fourthInstallmentReceived"
                              )
                            }
                          />{" "}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: this.state.errorMessage
                            .emptyfourthInstallmentDate
                            ? "contents"
                            : "none",
                        }}
                      >
                        <td className="blue-color left  border-color"></td>
                        <td
                          colSpan={3}
                          style={{ textAlign: "left", color: "red" }}
                        >
                          {this.state.errorMessage.emptyfourthInstallmentDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue-color left  border-color" />
                        <td className="white-color left">
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            £ {fourthInstallmentAmount}{" "}
                          </span>
                        </td>
                        <td className="white-color left" colSpan={4}>
                          {" "}
                          <span style={{ marginLeft: "-15px" }}>
                            £{" "}
                            <input
                              type="Number"
                              name="fourthInstallmentAmountRecieved"
                              value={fourthInstallmentAmountRecieved}
                              onChange={this.onAmountChange}
                              style={{ width: "40%", marginLeft: "5px" }}
                            />
                          </span>
                          <img
                            src={document}
                            style={{ width: "25px", marginLeft: "10px" }}
                            onClick={() => this.OpenPaymentDetailsModal(5)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            {moduleList.map((item) =>
              item.assignmentOneSubmittedOn == "" ? (
                ""
              ) : (
                <div className="col-lg-6">
                  <div
                    className="table-responsive-sm"
                    style={{ border: "none" }}
                  >
                    <table
                      className="table table-striped"
                      style={{ padding: "10px 10px" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            colSpan={4}
                            className="blue-color left border-color"
                            width="40%"
                          >
                            <h5 className="manageEnrollment-h5">
                              {item.moduleName}
                            </h5>
                          </td>
                          {/* <td className="blue-color left" />
                          <td className="blue-color left" />
                          <td className="blue-color left" /> */}
                        </tr>
                        <tr>
                          <td
                            className="blue-color left  border-color"
                            width="40%"
                          >
                            <label>1st Assignment Submission: </label>
                          </td>
                          <td
                            className="white-color span-box left"
                            colSpan={4}
                            style={{ borderTop: "none" }}
                          >
                            <label>Submitted on - </label>
                            <label className="payment-date">
                              {" "}
                              {moment(item.assignmentOneSubmittedOn).format(
                                "MM/DD/YYYY"
                              )}
                            </label>
                          </td>
                        </tr>
                        {item.assignmentOneSubmittedOn == "" ? (
                          ""
                        ) : item.assignmentTwoSubmittedOn == "" ? (
                          ""
                        ) : (
                          <tr>
                            <td
                              className="blue-color left  border-color"
                              width="40%"
                            >
                              <label>2nd Assignment Submission: </label>
                            </td>

                            <td
                              className="white-color span-box left"
                              colSpan={4}
                              style={{ borderTop: "none" }}
                            >
                              <label>Submitted on - </label>
                              <label className="payment-date">
                                {" "}
                                {moment(item.assignmentTwoSubmittedOn).format(
                                  "MM/DD/YYYY"
                                )}
                              </label>
                            </td>
                          </tr>
                        )}
                        {item.assignmentTwoSubmittedOn == "" ? (
                          "" //since second assignment is not uploaded no need to display third
                        ) : item.assignmentThreeSubmittedOn == "" ? (
                          ""
                        ) : (
                          <tr>
                            <td
                              className="blue-color left  border-color"
                              width="40%"
                            >
                              <label>3rd Assignment Submission: </label>
                            </td>
                            <td
                              className="white-color span-box left"
                              colSpan={4}
                              style={{ borderTop: "none" }}
                            >
                              <label>Submitted on - </label>
                              <label className="payment-date">
                                {moment(item.assignmentThreeSubmittedOn).format(
                                  "MM/DD/YYYY"
                                )}
                              </label>
                            </td>
                          </tr>
                        )}
                        {item.assignmentThreeSubmittedOn == "" ? (
                          "" //since third assignment is not uploaded no need to display fourth
                        ) : item.assignmentFourSubmittedOn == "" ? (
                          ""
                        ) : (
                          <tr>
                            <td
                              className="blue-color left  border-color"
                              width="40%"
                            >
                              <label>4th Assignment Submission: </label>
                            </td>
                            <td
                              className="white-color span-box left"
                              colSpan={4}
                              style={{ borderTop: "none" }}
                            >
                              <label>Submitted on - </label>
                              <label className="payment-date">
                                {moment(item.assignmentFourSubmittedOn).format(
                                  "MM/DD/YYYY"
                                )}
                              </label>
                            </td>
                          </tr>
                        )}
                        {item.assignmentFourSubmittedOn == "" ? (
                          "" //since third assignment is not uploaded no need to display fourth
                        ) : item.assignmentFiveSubmittedOn == "" ? (
                          ""
                        ) : (
                          <tr>
                            <td
                              className="blue-color left  border-color"
                              width="40%"
                            >
                              <label>5th Assignment Submission: </label>
                            </td>
                            <td
                              className="white-color span-box left"
                              colSpan={4}
                              style={{ borderTop: "none" }}
                            >
                              <label>Submitted on - </label>
                              <label className="payment-date">
                                {moment(item.assignmentFiveSubmittedOn).format(
                                  "MM/DD/YYYY"
                                )}
                              </label>
                            </td>
                          </tr>
                        )}
                          <tr>
                            <td
                              className="blue-color left  border-color"
                              width="40%"
                            >
                              <label>Result: </label>
                            </td>
                            <td
                              className="white-color span-box left"
                              colSpan={4}
                              style={{ borderTop: "none" }}
                            >
                              <label>{item.finalResult == 1 ? "Pass" : item.finalResult == 2 ? "Fail" : item.finalResult == 3 ? "Resubmit" : item.finalResult == 4 ? "NA" : item.finalResult == 5 ? "Distinction" : "Merit"}</label>
                              
                            </td>
                          </tr>
                        <tr>
                          <td className="blue-color left  border-color" />
                          <td className="white-color left" colSpan={4}>
                            {" "}
                            <button
                              type="button"
                              className="btn view-student-info manage-button-align"
                              onClick={() =>
                                history.push(
                                  `/AssignmentSubCategory/${userID}/${courseIterationId}/${item.moduleId}`
                                )
                              }
                            >
                              Manage
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-dark button-color "
              onClick={() => this.addEditManageEnrollmentPage()}
            >
              Save
            </button>
          </div>

          <div className="col-md-12">
            <h5 className="mt-3 mb-3 gray">Student Module Grading</h5>
          </div>
          <div className="col-md-12">
            <ul style={{ listStyle: "initial", paddingLeft: "16px" }}>
              <li className="mark-list">
                {" "}
                <strong>
                  {" "}
                  Marking a Module grade as 'Active' allows the student to
                  access the Module information, and review their markings when
                  markings have been set.{" "}
                </strong>
              </li>
              <li className="mark-list">
                {" "}
                <strong>
                  {" "}
                  Please note that TMA-Q Visible will only apply if the Module
                  is flagged as visible.
                </strong>{" "}
              </li>
            </ul>
          </div>
          <div
            className="col-lg-5 flex-item-left"
            style={{ paddingLeft: "15px" }}
          >
            {/* <button
              type="button"
              className="btn delete-button delete"
              style={{ marginLeft: "11px" }}
            >
              <img src={Deleteicon} className="delete-icon" width="20px" />{" "}
              Delete
            </button> */}
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "11px", padding: "8px 20px" }}
              onClick={() => this.showUpdatePopUp()}
            >
              Update Module list
            </button>
          </div>
          <div className="col-lg-7 flex-item-right">
            <form className="example1 example2" style={{ float: "right" }}>
              <input
                type="text"
                placeholder="Search by Name/ID"
                name="search2"
                value={this.state.Search}
                onChange={this.onSearchTextChange.bind(this)}
              />
              <button type="submit">
                <i className="fa fa-search" />
              </button>
            </form>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <DataTable
                columns={this.header}
                data={moduleList}
                noHeader={true}
                pagination
                // onSelectedRowsChange={this.handleChange}
                // selectableRows // add for checkbox selection
              />
            </div>
          </div>

          <div
            className="col-md-12"
            style={{ paddingTop: "20px", paddingLeft: "2px" }}
          >
            <button
              type="button"
              className="btn back-button"
              onClick={() =>
                // history.push(
                //   `/EditCourseIteration/${courseIterationId}/${courseID}`
                // )
                history.goBack()
              }
            >
              <i className="fa fa-angle-left" />
              Back to Course Iterations{" "}
            </button>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {this.state.openPaymentDetailsModal && (
          <PaymentDetailsModal
            ref="paymentDetailsModal"
            closeModal={this.ClosePaymentDetailsModal}
            OpenModal={this.state.openPaymentDetailsModal}
            getPaymentDetailsFromXero={this.getPaymentDetailsFromXero}
          ></PaymentDetailsModal>
        )}
        {showUpdateModel && (
          <AddUnitToStudentModel
            courseIterationId={courseIterationId}
            courseID={courseID}
            selectedUnitList={unfilteredList}
            userID={userID}
            closeModal={this.closeUnitListModal}
          />
        )}
      </div>
    );
  }
}
