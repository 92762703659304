import React, { Component } from "react";
import recordlogo1 from "../../assets/record-logo1.png";
import recordlogo2 from "../../assets/record-logo2.png";
import chiefexecutiveofficersign from "../../assets/chief-executive-officer-sign.png";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { history } from "../../_helpers/history";

export default class CertificatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName:'',
      unitName:'',
      name:'vidj',
    };
  }

  componentDidMount = async() => {
    var studentName= localStorage.getItem("UserName");
    var moduleName= this.props.match.params.moduleName;
    this.setState({
      userName:studentName,
      unitName:moduleName,
    },async()=>{
      await this.downloadCertificate();
    })
    

  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To download the certificate
  <summary>*/ 
  downloadCertificate=()=>{
    try {
      this.setState({
        isLoading: true,
      });
      html2canvas(document.querySelector("#certificateDiv")).then((canvas) => {
        // document.body.appendChild(canvas); // if you want see your screenshot in body.
        const imgData = canvas.toDataURL("image/png");
        //const pdf = new jsPDF("l", "mm", [1800, 1304]);
        var pdf = new jsPDF({
          orientation: "potrait",
          unit: "px",
         // format: [4, 2]
        });
          
        var imgProps = pdf.getImageProperties(imgData);
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 1, 1, pdfWidth, pdfHeight);
        pdf.save("Certificate.pdf");
        this.setState({
          isLoading: false,
        });
        history.goBack();
      });

      
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const{
      userName,
      unitName,
      name,
    }=this.state;
    return (
      <section className="record-of-studies">
        <div className="container" >
          <div className="col-12 col-md-12  ">
            <div id="certificateDiv" className="a4-page">
              <div className="certificate-box">
                <div className="certificate-body">
                  <div className="row certificate-heading">
                    <div className="col-md-12">
                      <h2>The International Institute of Marine Surveying </h2>
                    </div>
                    <div className="col-xs-4 col-md-4">
                      <img src={recordlogo1} />
                    </div>
                    <div className="col-xs-4 col-md-4">
                      <h1>Record of Studies</h1>
                    </div>
                    <div className="col-xs-4 col-md-4">
                      <img src={recordlogo2} />
                    </div>
                    <div className="col-xs-12 col-md-12">
                      <p>this is to certify that</p>
                    </div>
                  </div>
                  <div className="row certificate-midtext">
                    <div className="col-md-1" />
                    <div className="col-md-10">
                      <h1>{userName}</h1>
                      <p>
                        has undertaken a period of study and successfully
                        achieved the following Module, awarded by the
                        International Institute of Marine Surveying:
                      </p>
                      <p>{unitName}</p>
                    </div>
                    <div className="col-md-1" />
                  </div>
                  <div className="row certificate-endtext">
                    <div className="col-2 col-md-2" />
                    <div className="col-8 col-md-8">
                      <img src={chiefexecutiveofficersign} />
                      <span className="signing-name">
                        Chief Executive Officer
                      </span>
                      <p>INTERNATIONAL INSTITUTE OF MARINE SURVEYING</p>
                      <span>United Kingdom</span>
                    </div>
                    <div className="col-2 col-md-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
