import React, { Component } from "react";
import { loginService } from "../_services/login.service";
import RegisterStudentModel from "../Models/RegisterStudentModel";
import Loader from "../_helpers/Loader";
import PopupModal from "../_helpers/PopupModal";
import { helperText } from "../_helpers/helpertext";
import { isStringEmpty, isEmailValid } from "../_helpers/string_helper";
import StudentFooter from "../_components/StudentFooter";
import distancelearninglogo from "../assets/distance-learning-logo.png";
import { history } from "../_helpers/history";
import { MessageType } from "../_helpers/Enums";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      firstName: "",
      lastName: "",
      displayName: "",
      emailAddress: "",
      password: "",
      address: "",
      contactNo: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      firstNameError: false,
      emailError: false,
      addressError: false,
      contactnoError: false,
      isChecked: false,
      selectedOption: "1",
      userID:0,
    };
  }

  componentDidMount=()=>{
    var studentId = localStorage.getItem("UserID");
    this.setState({
      userID:studentId==undefined?0:studentId,
    })
  }

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      emailError: false,
      firstNameError: false,
      emailError: false,
      addressError: false,
      contactnoError: false,
    });
  };

  validate = async () => {
    // e.preventDefault();
    const { firstName, emailAddress, address, contactNo } = this.state;

    let hasErrors = false;
    if (isStringEmpty(firstName)) {
      hasErrors = true;
      this.setState({
        firstNameError: helperText.registerText.firstNameText,
      });
    } else {
      this.setState({
        firstNameError: "",
      });
    }

    if (isStringEmpty(emailAddress)) {
      hasErrors = true;
      this.setState({ emailError: helperText.registerText.emailAddressText });
    } else if (!isEmailValid(emailAddress)) {
      hasErrors = true;
      this.setState({
        emailError: helperText.registerText.inValidEmailAddress,
      });
    } else
      this.setState({
        emailError: "",
      });

    if (isStringEmpty(address)) {
      hasErrors = true;
      this.setState({
        addressError: helperText.registerText.addressText,
      });
    } else {
      this.setState({
        addressError: "",
      });
    }

    if (isStringEmpty(contactNo)) {
      hasErrors = true;
      this.setState({
        contactnoError: helperText.registerText.contactnoText,
      });
    } else {
      this.setState({
        contactnoError: "",
      });
    }
    if (hasErrors) return;

    this.registerStudent();
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To save the details of the user who has enquired about the category along with the category id
  <summary>*/
  registerStudent = async () => {
    try {
      const { firstName, emailAddress, address, contactNo, selectedOption } =
        this.state;

      this.setState({
        isLoading: true,
      });

      var registerStudentModel = new RegisterStudentModel();
      registerStudentModel.firstName = firstName;
      registerStudentModel.emailAddress = emailAddress;
      registerStudentModel.address = address;
      registerStudentModel.contactNo = contactNo;
      registerStudentModel.selectedOption = selectedOption;

      var response = await loginService.RegisterStudentForCategoryEnquired(registerStudentModel);
      if (response.status == 200) {
        if (response.data == true) {
          //saved
          this.setState({
            popUpModalText: helperText.Register.enquiryRegistered,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          //error while saving
          this.setState({
            popUpModalText:helperText.Register.emailExistError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      } else {
        this.setState({
          popUpModalText: helperText.GeneralText.unableToConnect,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        firstName: "",
        lastName: "",
        displayName: "",
        emailAddress: "",
        password: "",
        address: "",
        contactNo: "",
        isLoading: false,
      });
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  onValueChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    });
  };

  render() {
    const {
      firstName,
      emailAddress,
      password,
      address,
      contactNo,
      isLoading,
      showPopupModal,
      popUpModalText,
      messageType,
      firstNameError,
      emailError,
      addressError,
      contactnoError,
      userID
    } = this.state;
    return (
      <div className="student-body">
        {/* <Header currentPage="Register" /> */}
        <nav className="navbar navbar-default custom-student-nav">
          <div className="container-fluid">
            {/* Logo and toggle for mobile display */}
            <div className="navbar-header">
              <a
                className="navbar-brand"
                onClick={() => history.push("/LoginPage")}
              >
                <img
                  src={distancelearninglogo}
                  className="img-responsive image-small logo-alignment"
                />
              </a>
            </div>
            {/* Collect the nav links and other content for toggling */}
            {userID==0?
            <div
              className="collapse navbar-collapse"
              id="slide-navbar-collapse"
            >
              <ul className="nav navbar-nav navbar-right">
                <li className>
                  <a>
                    <button
                      type="button"
                      onClick={() => history.push("/LoginPage")}
                      className="btn btn btn-primary purple"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      Login
                    </button>
                  </a>
                </li>
              </ul>
            </div>
            :""}
            {/* /.navbar-collapse */}
          </div>
          {/* /.container-fluid */}
        </nav>
        {isLoading && <Loader />}
        <div className="menu-overlay"> &nbsp;</div>
        <section id="coursenbanner" className="register-form">
          <div className="row">
            <div className="col-md-12">
              <h2>Register your Interest</h2>
              <p>
                Please complete the form below and a member of the IIMS
                education team will shortly be in touch.
              </p>
            </div>
          </div>
        </section>
        <section className=" container-fluid courselist reg-form" id="content">
          <div className="row">
            <br />
            <div className="col-md-6">
              <div className="form-reg">
                <label htmlFor="lastname" className="control-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Full Name"
                  name="firstName"
                  value={firstName}
                  onChange={this.onTextChange}
                />
              </div>
              {!isStringEmpty(firstNameError) && (
                <small
                  id="emailHelp"
                  className="form-text"
                  style={{ color: "red", marginLeft: "4px" }}
                >
                  Enter your full name.
                </small>
              )}
              <div className="form-reg">
                <label htmlFor="lastname" className="control-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  placeholder="Email Address"
                  name="emailAddress"
                  value={emailAddress}
                  onChange={this.onTextChange}
                />
              </div>
              {!isStringEmpty(emailError) && (
                <small
                  id="emailHelp"
                  className="form-text"
                  style={{ color: "red", marginLeft: "4px" }}
                >
                  Enter email address.
                </small>
              )}
              <div className="form-reg">
                <label htmlFor="lastname" className="control-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contactNo"
                  placeholder="Phone Number"
                  name="contactNo"
                  value={contactNo}
                  onChange={this.onTextChange}
                />
              </div>
              {!isStringEmpty(contactnoError) && (
                <small
                  id="emailHelp"
                  className="form-text"
                  style={{ color: "red", marginLeft: "4px" }}
                >
                  Enter phone number.
                </small>
              )}
              <div className="form-reg">
                <label htmlFor="lastname" className="control-label">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Country"
                  name="address"
                  value={address}
                  onChange={this.onTextChange}
                />
              </div>
              {!isStringEmpty(addressError) && (
                <small
                  id="emailHelp"
                  className="form-text"
                  style={{ color: "red", marginLeft: "4px" }}
                >
                  Enter country.
                </small>
              )}
              <div className="form-reg">
                <label htmlFor="lastname" className="control-label">
                  Choose one of the two options below*
                </label>{" "}
                <br />
                &nbsp;{" "}
                <input
                  type="radio"
                  value="1"
                  checked={this.state.selectedOption === "1"}
                  onChange={this.onValueChange}
                />
                &nbsp;{" "}
                <label htmlFor="css">
                  Professional Qualification in Yacht &amp; Small Craft Marine Surveying
                </label>
                <br />
                &nbsp;{" "}
                <input
                  type="radio"
                  value="2"
                  checked={this.state.selectedOption === "2"}
                  onChange={this.onValueChange}
                />
                &nbsp;{" "}
                <label htmlFor="javascript">
                  Professional Qualification in Commercial Ship Marine Surveying
                </label>
              </div>
              <div className="form-reg">
                <label className="conta-check">
                  <input
                    type="checkbox"
                    onChange={this.toggleChange}
                    defaultChecked={this.state.isChecked}
                  />
                  <span
                    className="checkbox"
                    style={{
                      top: "-6px",
                      border: "1px solid black",
                      borderRadius: "4px",
                    }}
                  />
                  <span className="checkbox-text">
                    By using this form you agree with the storage and handling
                    of your data by this website.{" "}
                  </span>
                </label>
              </div>
              <button
                style={{ marginBottom: "15px" }}
                disabled={!this.state.isChecked}
                type="button"
                className="btn btn-primary form-send"
                onClick={() => this.validate()}
              >
                Send
              </button>
            </div>
          </div>
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}

export default Register;
