import React, { Component } from "react";
import { history } from "../_helpers/history";
import { Role } from "../_helpers/Enums";
import user2 from "../assets/user2.png";
import distancelearninglogo from "../assets/distance-learning-logo.png";
import { loginService } from "../_services/login.service";
import { studentService } from "../_services/student.service";

export default class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBuger: false,
      IsloggedIn: false,
      currentTab: this.props.currentTab,
      menuwidth: 0,
      menuBlock: "",
      notificationCount:0,
      userName: "",
      isLoading:false,
      profileImagePath:"",
    };
    this.increaseWidthId = null;
    this.decreaseWidthId = null;
  }

  componentDidMount=async()=> {
    this.setState({
      isLoading:true,
    })
    if (localStorage.getItem("RoleID") === Role.Admin.toString()) {
      this.setState({
        IsloggedIn: true,
        userName: localStorage.getItem("UserName"),
        notificationCount:localStorage.getItem("notificationCount"),
        profileImagePath:localStorage.getItem("ProfileImagePath")
      });
    } else {
      this.setState({
        IsloggedIn: false,
      });
      history.push("/LoginPage");
    }
    this.getNotificationCount();
    
    this.setState({
      isLoading:false,
    })
  }

 

  getNotificationCount = async () => {
    const userID = localStorage.getItem("UserID");
    
    var response = await studentService.GetNotificationCount(userID);
    if (response.status == 200) {
      localStorage.setItem("notificationCount", response.data);
      this.setState({
        notificationCount:response.data
      })
    }
  };

  //buger was not coming properly so used this to toggle
  toggleBurger = () => {
    this.setState((prevState) => ({
      showBuger: !prevState.showBuger,
    }));
  };
  showIndexPage = () => {
    localStorage.clear();
    history.push("/LoginPage");
  };

  toggleSlider = () => {
    if (this.state.menuwidth <= 0) {
      if (this.decreaseWidthId != null) {
        clearInterval(this.decreaseWidthId);
      }
      this.increaseWidthId = setInterval(() => this.method(), 50);
    } else {
      if (this.increaseWidthId != null) {
        clearInterval(this.increaseWidthId);
      }
      //  this.decreaseWidthId = setInterval(()=>this.decreaseWidth(), 100);
      this.decreaseWidth();
    }

  };

  method = () => {
    if (this.state.menuwidth < 200) {
      this.setState((prev) => ({
        menuwidth: prev.menuwidth + 50,
        menuBlock: "block",
      }));
    }
  };

  decreaseWidth = () => {
    if (this.state.menuwidth <= 200) {
      this.setState((prev) => ({
        menuwidth: 0,
        menuBlock: "none",
      }));
    }
  };

  Logout = () => {
    const UserName = localStorage.getItem("UserName");
    const userID = localStorage.getItem("UserID");
    localStorage.clear();
    this.setState(
      {
        IsloggedIn: false,
      },
      async () => {
        var response = await loginService.SaveLogoutInfo(UserName, userID);
        if (response.status == 200) {
          //success
          history.push("/LoginPage");
        }
      }
    );
  };
  render() {
    const { 
      currentTab, 
      menuwidth, 
      menuBlock,
      notificationCount, 
      profileImagePath,
      userName,  
    } = this.state;
    return (
      <div>
        <nav className="navbar navbar-default custom-admin-nav">
          <div className="container">
            {/* Logo and toggle for mobile display */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="slide-collapse"
                data-target="#slide-navbar-collapse"
                aria-expanded="false"
                onClick={() => this.toggleSlider()}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" onClick={()=>history.push("/DashboardPage")} style={{cursor:'pointer'}}>
                <img
                  src={distancelearninglogo}
                  className="img-responsive image-small logo-alignment"
                />
              </a>
              <div className="mob-notify">
                <a href="/AdminNotification">
                  <img src="images/notification.png" />
                  <span className="notify">{notificationCount>99?'99':notificationCount}</span>
                </a>
              </div>
            </div>
            {/* Collect the nav links and other content for toggling */}
            <div
              className="collapse navbar-collapse"
              id="slide-navbar-collapse"
              style={{
                display: menuBlock,
                width: menuwidth,
                overflow: "hidden",
              }}
            >
              <ul className="nav navbar-nav navbar-right">
                {currentTab == "Home" ? (
                  <li className="active li-mt">
                    <a href="/DashboardPage">
                      Home <span className="sr-only">(current)</span>
                    </a>
                  </li>
                ) : (
                  <li className="li-mt">
                    <a href="/DashboardPage">
                      Home 
                    </a>
                  </li>
                )}
                {currentTab == "Students" ? (
                   <li className="active li-mt">
                     <a href="/StudentCourseManager">Student</a>
                   </li>
                 ) : (
                  <li className="li-mt">
                  <a href="/StudentCourseManager">Student</a>
                </li>
                 )}
                {currentTab == "Courses" ? (
                  <li className="active li-mt">
                    <a href="/CreateCourse">Courses</a>
                  </li>
                ) : (
                  <li className="li-mt">
                  <a href="/CreateCourse">Courses</a>
                </li>
                )}
                
                <li className="hidden-xs hide-small">
                  <a href="/AdminNotification">
                    <img src="images/notification.png" />
                    <span className="notify">{notificationCount>99?'99':notificationCount}</span>
                  </a>
                </li>
                <li className="menu-user-icon">
                  <a href="/AdminProfile">
                    <img src={profileImagePath==""?user2:profileImagePath} className="header-profile-img" /> {userName}{" "}
                  </a>
                </li>
                <li>
                  <a>
                    <button
                      type="button"
                      className="btn btn btn-primary purple"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={this.showIndexPage}
                    >
                      Logout
                    </button>
                  </a>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>
          {/* /.container-fluid */}
        </nav>
        <div className="menu-overlay"> &nbsp;</div>
      </div>
    );
  }
}
