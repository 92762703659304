export const helperText={
  loginPageText: {
    emailSentText: "Password reset email has been sent successfully",
    errorSendingMail: "Email address is not registered",
    errorEmailAddress: "Please enter your account email address.",
    inValidEmailAddress: "Incorrect email format entered.",
    emailPlaceholder: "Please enter account email address.",
    errorPassword: "Please enter your account password.",
    passwordPlaceholder: "Please enter your password.",
    userNotActive: "User is not active",
    LoginFailed: "Incorrect account email address or password",
    resetLinkSent:"The link to reset your password has been emailed successfully.",
    emailUnmatch:"This email address does not match with the registered email address",
    checkUsernamePassword:"Please check your username or password",
    confirmPassword:"*Please enter a correct password to confirm.",
    bothPasswordDoesNotMatch:"*Your Password and Confirm New Password do not match.",
  },
  registerText: {
    firstNameText: "Please enter first name.",
    lastNameText: "Please enter last name.",
    displayNameText: "Please enter display name.",
    emailAddressText: "Please enter email address.",
    passwordText: "Please enter the password.",
    addressText: "Please enter your address.",
    contactnoText: "Please enter your contact number.",
    inValidEmailAddress: "Incorrect email format entered.",
  },
  resetPageText:{
    unMatchPassword:"Your password does not match",
    passwordLength:"Your password length should be of minimum 8 characters",
    passwordUpdateSuccess:"Your Password has been updated",
    urlAlreadyUsed:"This Url has already been used to change the Password please send a new URL to your email address"
  },
  AssignmentSubCategory:{
    saveResultSucess:"Result has been saved successfully",
    saveResultError:"Error While saving the result",
    noQuestionUploaded:"No questions have been uploaded",
    remarksUploaded:"Remarks has been uploaded.",
  },
  courseNameChange:{
    message:"Type the course name that you want to set it to"
  },
  AddUnitToStudentModelText:{
    addUnit:"Please add a Module to update",
    addremoveunit:"Please add or remove a Module to update",
    UnitAdded:"Module Added to the iteration",
  },
  AdvanceCoursePopupModalText:{
    mailsent:"Your mail has been sent",
  },
  PasswordChangeModalText:{
    passwordupdated:"Your password has been updated",
  },
  GeneralText:{
    unableToConnect:"Unable to connect to the database",
  },
  AddEditTemplate:{
    emptyTemplatename:"*Please enter the templateName",
    emptyTemplatesubject : "*Please enter the Subject",
    emptyTemplatemessage : "*Please type a message to send.",
  },
  DataLoadError:{
    loadError:"error while loading Data",
    dataDeleteError:"error while deleting data",
    updateError:"error While Updating data",
    downloadError:"Error while downloading",
    imageUploadError:"Error while uploading image",
    imageUploaded:"Image has been uploaded",
    dataUploadSuccess:"Data uploaded successfully",
    uploadDataError:"Error while uploading data",
  },
  EditCourseNameModel:{
    updatedCourseName:"Course name has been updated",
  },
  courseIteration:{
    emptyiterationCheckbox:"*Please select the course iterations to be deleted.",
    courseDeleted:"Courses deleted.",
  },
  createCourse:{
    nameExist:" Course name exist",
    courseCreated:"Course created",
    emptyCourseName:"*Please enter a course name.",
    emptyCourseCheckbox:"*Please select the courses to be deleted.",
  },
  createCourseIteration:{
    emptyTitle:"*Please enter a Title.",
    emptyAvailableFrom:"*Please select a start date.",
    emptyAvailableTo:"*please select an end date.",
    emptyIterationCost:"*Please enter an amount.",
    emptyFirstInstallmentDate:"*Please select correct first installment date.",
    emptySecondInstallmentDate:"*Please select correct second installment date.",
    emptyThirdInstallmentDate:"*Please select correct third installment date.",
    emptyFourthInstallmentDate:"*Please select correct fourth installment date.",
  },
  EditCourseIteration:{
    addedCourseIteration:"Course Iteration added successfully",
    updatedCourseIteration:"Course Iteration Updated successfully",
    studentNotPresent:"The student with this membership id is not present",
    memebershipNumberExist:"Membership number already present",
    FileNotUploaded:"File not yet uploaded",
    FileUploaded:"File uploaded successfully",
    moduleUpdated:"Module updated Successfully",
    moduleNotUpdated:"Unable to update the Module",
    emptyWelcomeCheckbox:"*Please select atleast one check box.",
    welcomeNoteSent:"Welcome note has been sent",
  },
  EnrolledCourses:{
    studentEnrolled:"Student enrolled",
    studentNotEnrolled:"error while enrolling student",
    studentRemoved:"Student has been removed from the selected Iteration.",
  },
  ManageAutoNotification:{
    emailUpdated:"Email details have been updated",
  },
  manageEnrollment:{
    dateSaveError:"error while saving date",
    worngFirstInstallmentDate:"*first installment received date should be less then the second installment date.",
    worngSecondInstallmentDate:"*Second installment received date cannot be greater than third installment received date.",
    worngThirdInstallmentDate:"*fourth installment received date cannot be less than Third installment received date.",
    cannotRemoveStudent:"Cannot remove the student.",
    updateSubmmissionAmountAndDate:"Subission amount and date has been updated.",
    studentOnHold:"Students hold status has been updated.",
    courseExtended:"Course has been extended.",
    courseExtendError:"Error while Extending the course.",
    emptyExtendedDate:"*Please select a extended date.",
    advanceCourseEndDateSave:"You advance course end date has been updated",
    advanceCourseEndDateError:"Error while updating the advance course end date",
    dataSaved:"Details updated successfully",
  },
  RegisteredStudent:{
    errorStudentList:"error while loading registered student list",
  },
  StudentCourseManager:{
    emptyStudentMailCheckbox:"*Please select atleast one student to send the mail.",
    templateMailSent:"Template mail has been sent",
    templateMailSentError:"error while sending the mail",
    studentDeleteSuccess:"Selected Student's have been deleted",
    studentDeleteFailure:"Error while deleting student",
    emptyStudentDeleteCheckbox:"*please select atleast one student to Delete."
  },
  AddEditUnitAddDynamicContent:{
    tabLoadError:"error while loading tab data",
    moduleLoadError:"error while loading Module data",
    moduleCreated:"Module created Successfully",
    moduleDetailsUpdated:"Details updated",
    updateDetailsError:"Unable to update",
    emptyModuleNumber:"*Please enter a Module nummber.",
    emptyModuleHeader:"*Please enter a Module Header.",
    emptyHeader:"*Please enter a header.",
    emptyLink:"*Please enter a link.",
    modulePDFUploaded:"Module PDF has been uploaded successfully",
  },
  AddEditUnitTabSelection:{
    selectModule:"Please select atleast one Module tab",
  },
  ManageStudyMaterial:{
    selectOneCheckboxToDelete:"select atleast one check box to delete",
    moduleDeleted:"Module deleted successfully",
    moduleDeleteError:"error while deleting Module",
    selectModuleToUpdate:"Please select one Module to update",
  },
  AdminNotification:{
    templateSaved:"Your template has been saved",
    templateDeleted:"Templates deleted",
    templateDeleteError:"error while deleting course",
  },
  profile:{
    passwordUpdateError: "Error while updating your password",
    assignmentUploadError: "Unable to upload the answer sheet make sure that you have paid the assignment submission amount before attempting again",
    assinmentUploaded : "Your assignment answers have been uploaded successfully",
  },
  Dashboard:{
    emptyExport:"Nothing to export",
  },
  Email:{
    emptyEmailAddress:"*select an email address to send.",
    accessorEmailSaved:"Accessor Email has been saved",
    emailPresentError:"This email address is already present",
    emailSaveError:"Error while saving the email",
  },
  ReadEmail:{
    noPermissionToDownload:"you do not have the permission to download the assignment contact the administrator",
  },
  IIms:{
    iimsNewsError:"error while loading iims news",
    iimsEventError:"error while loading iims events",
  },
  courseDetails:{
    fileNotPresentError:"File is not present to download. Make sure that the admin has uploaded the file",
  },
  Register:{
    enquiryRegistered:"Your enquiry has been registered.",
    emailExistError:"Email address already exists, try with a different email address",
  },
  AssignmentDetails:{
    assignmentDeleteSuccess:"The Assignment has been deleted",
    assignmentDeleteFailure:"Error while deleting the assignment"
  },
  AssignmentRemarksPopupModal:{
    remarksUpdateSucess:"Files have been updated",
    remarksUpdateFailure:"Error While Updating the file",
  }
}