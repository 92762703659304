import React, { Component } from "react";
import studentlogin from "../assets/studentlogin.png";
import phone from "../assets/phone.png";
import EmailIcon from "../assets/EmailIcon.png";
import { isStringEmpty, isEmailValid } from "../_helpers/string_helper";
import { helperText } from "../_helpers/helpertext";
import { history } from "../_helpers/history";
import { loginService } from "../_services/login.service";
import { Role } from "../_helpers/Enums";
import { getBrowserDetails } from "../_helpers/utilities";
import Loader from "../_helpers/Loader";
import PopupModal from "../_helpers/PopupModal";
import eye from "../assets/eye.png";
import passspam from "../assets/pass spam.png";
import { studentService } from "../_services/student.service";
import { MessageType } from "../_helpers/Enums";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            password: "",
            emailError: false,
            passwordError: false,
            isLoading: false,
            showPopupModal: false,
            popUpModalText: "",
            messageType: 0,
            showPassword: false,
            resetEmail: "",
            resetEmailError: false,
        };
    }

    componentDidMount = () => {
    }

    deleteCache = () => {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            //window.location.reload(true);
        }
    }
    onTextChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            emailError: false,
            passwordError: false,
            resetEmailError: false,
        });
    };

    validate = async (e) => {
        e.preventDefault();
        const { emailAddress, password } = this.state;
        let hasErrors = false;

        if (isStringEmpty(emailAddress)) {
            hasErrors = true;
            this.setState({ emailError: helperText.loginPageText.errorEmailAddress });
        } else if (!isEmailValid(emailAddress)) {
            hasErrors = true;
            this.setState({
                emailError: helperText.loginPageText.inValidEmailAddress,
            });
        } else
            this.setState({
                emailError: "",
            });

        if (isStringEmpty(password)) {
            hasErrors = true;
            this.setState({
                passwordError: helperText.loginPageText.errorPassword,
            });
        } else
            this.setState({
                passwordError: "",
            });

        if (hasErrors) return;

        this.GetLoginInfo();
    };

    /* <summary>
        date: 21-06-2021
        Name: VD
        description: User Login Service
        //S.S-> 31 Mar 2022, Added new localStorage value 'BelongsToMarineCorrosionCourse'.
        //If Logged in Student belongs to MarineCorrosion, hide HOME and ASSIGNMENT option from Header.
        <summary>*/
    GetLoginInfo = async () => {
        try {
            const browserDetails = getBrowserDetails();
            const browsername = browserDetails.browserName;
            const browserversion = browserDetails.browserversion;
            const osName = browserDetails.os.name;
            const osVersion = browserDetails.os.versionName;
            const { emailAddress, password } = this.state;
            this.setState({ isLoading: true });
            var response = await loginService.login(
                emailAddress,
                password,
                browsername,
                browserversion,
                osName,
                osVersion
            );
            this.setState({
                isLoading: false,
            });
            if (response.status === 200) {
                this.deleteCache();
                if (response.data == null) {
                    this.setState({
                        popUpModalText: helperText.loginPageText.checkUsernamePassword,
                        showPopupModal: true,
                        messageType: MessageType.Failure,
                    });
                } else {
                    localStorage.setItem("SessionToken", response.data.jwtToken);
                    localStorage.setItem("RoleID", response.data.roleID);
                    localStorage.setItem("UserName", response.data.userName);
                    localStorage.setItem("UserID", response.data.userID);
                    localStorage.setItem("BelongsToMarineCorrosionCourse", response.data.belongsToMarineCorrosionCourse);

                    if (response.data.roleID == Role.Admin) {
                        this.getStudentProfileImage("/DashboardPage");
                    } else if (response.data.roleID == Role.Student) {
                        if (response.data.belongsToMarineCorrosionCourse == 1)//If course is MARINE CORROSION
                            this.getStudentProfileImage("/AllCourses");
                        else
                            this.getStudentProfileImage("/StudentHomePage");
                    }
                    else {
                        this.setState({
                            popUpModalText: helperText.loginPageText.checkUsernamePassword,
                            showPopupModal: true,
                            messageType: MessageType.Failure,
                        });
                    }
                }
            } else {
                this.setState({
                    popUpModalText: helperText.GeneralText.unableToConnect,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }
        } catch (ex) {
            return "";
        }
    };

    getStudentProfileImage = async (Page) => {
        var userID = localStorage.getItem("UserID");
        var response = await studentService.GetProfileImagePath(userID);
        if (response.status == 200) {
            localStorage.setItem("ProfileImagePath", response.data.response)
        }
        history.push(Page);
    }

    closeModal = () => {
        this.setState({
            showPopupModal: false,
        });
    };

    toggleEye = () => {
        const currentState = this.state.showPassword;
        this.setState({ showPassword: !currentState });
    };

    resetEmailValidate = async (e) => {
        e.preventDefault();
        const { resetEmail } = this.state;
        let hasErrors = false;

        if (isStringEmpty(resetEmail)) {
            hasErrors = true;
            this.setState({
                resetEmailError: helperText.loginPageText.errorEmailAddress,
            });
        } else if (!isEmailValid(resetEmail)) {
            hasErrors = true;
            this.setState({
                resetEmailError: helperText.loginPageText.inValidEmailAddress,
            });
        } else
            this.setState({
                resetEmailError: "",
            });

        if (hasErrors) return;

        this.sendResetMail();
    };

    /* <summary>
    date: 15-11-2021
    Name: VD
    description: This is used to sent the reset password email in case the user forgets his email
    <summary>*/
    sendResetMail = async () => {
        try {
            this.setState({
                isLoading: true,
            });
            const { resetEmail } = this.state;
            var response = await loginService.ResetPassword(resetEmail);
            if (response.status == 200) {
                if (response.data == true) {
                    this.setState({
                        popUpModalText: helperText.loginPageText.resetLinkSent,
                        showPopupModal: true,
                        messageType: MessageType.Success,
                    });
                } else {
                    this.setState({
                        popUpModalText: helperText.loginPageText.emailUnmatch,
                        showPopupModal: true,
                        messageType: MessageType.Failure,
                    });
                }
            }
            this.setState({
                isLoading: false,
                resetEmail: "",
            });
        } catch (e) {
            return "";
        }
    };

    render() {
        const {
            emailAddress,
            emailError,
            password,
            passwordError,
            isLoading,
            showPopupModal,
            popUpModalText,
            messageType,
            showPassword,
            resetEmail,
            resetEmailError,
        } = this.state;
        return (
            <div className="login-background" style={{ minHeight: "100vh" }}>
                <div className="container container-background pg-height">
                    <div className="text-center" style={{ paddingTop: "40px" }}>
                        <h2 className="title">
                            {" "}
              Welcome to the <br />
                            <strong> IIMS Education Portal </strong>
                        </h2>
                        <img src={studentlogin} alt="logo image" width="120px" />
                    </div>
                    <section className="studentloginbox">
                        <form action="index.html" style={{ marginTop: "-50px" }}>
                            <div className="list-groupstudent">
                                <div className="list-groupstudent">
                                    {" "}
                                    <label> Email-Address </label>
                                    <input
                                        type="email"
                                        className="form-control input-border"
                                        placeholder="Enter email"
                                        name="emailAddress"
                                        value={emailAddress}
                                        onChange={this.onTextChange}
                                    />{" "}
                                </div>
                                {!isStringEmpty(emailError) && (
                                    <small
                                        id="emailHelp"
                                        className="form-text"
                                        style={{ color: "red", marginLeft: "17px" }}
                                    >
                                        Enter valid email address.
                                    </small>
                                )}
                                <div className="list-groupstudent password-padding">
                                    {" "}
                                    <label> Password</label>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control input-border"
                                        placeholder="Password"
                                        name="password"
                                        value={password}
                                        onChange={this.onTextChange}
                                    />
                                    <img
                                        style={{ marginLeft: "90%", marginTop: "-65px" }}
                                        src={eye}
                                        onClick={this.toggleEye}
                                    />{" "}
                                </div>
                                {!isStringEmpty(passwordError) && (
                                    <small
                                        id="error"
                                        className="form-text"
                                        style={{ color: "red", marginLeft: "17px" }}
                                    >
                                        {passwordError}
                                    </small>
                                )}
                                <div className="list-groupstudent forgot-pass">
                                    {/* <img src={passspam} className="spam-img" />{" "} */}
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        data-toggle="modal"
                                        data-target="#changePasswordModal"
                                    >
                                        Forgot Password?
                  </button>{" "}
                                </div>
                                <center>
                                    <button
                                        type="submit"
                                        className="btn btn-primary1"
                                        onClick={this.validate}
                                    >
                                        LOGIN
                  </button>
                                </center>
                            </div>
                        </form>
                        <div className="text-center">
                            <p className="contact-info">
                                Contact Vicki Loizides if you're unable to login:{" "}
                            </p>
                            <img src={phone} alt="phone" width="25px" />
                            <a href="tel:+44 23 9238 5223" style={{ color: "#2C68A4" }}>
                                {" "}
                +44 23 9238 5223
              </a>
                            <img src={EmailIcon} alt="email" width="25px" className="mob" />
                            <a href="mailto: education@iims.org.uk" className="mob">
                                {" "}
                education@iims.org.uk
              </a>
                        </div>
                        {/* Modal */}
                        <div
                            className="modal fade in opt-in-box modal-background"
                            id="changePasswordModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="changePasswordModalTitle"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered pass-modal"
                                role="document"
                            >
                                <div className="modal-content change-password-pop">
                                    <div className="modal-header">
                                        <h4
                                            className="modal-title"
                                            id="changePasswordModalLongTitle"
                                        >
                                            Forgot Password
                    </h4>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body ">
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="newemail">
                                                    Please enter your registered email address
                        </label>
                                            </div>
                                        </form>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="newpassword">
                                                    We will reset your password and send verification link
                                                    to your registered email address.
                        </label>
                                                <input
                                                    type="email"
                                                    className="form-control input-border"
                                                    placeholder="Enter email"
                                                    name="resetEmail"
                                                    value={resetEmail}
                                                    onChange={this.onTextChange}
                                                />
                                            </div>
                                            {!isStringEmpty(resetEmailError) && (
                                                <small
                                                    id="emailHelp"
                                                    className="form-text"
                                                    style={{ color: "red", marginLeft: "2px" }}
                                                >
                                                    Enter valid email address.
                                                </small>
                                            )}
                                        </form>
                                        {/* <div className="opt-in-alert">
                      <h5>
                        Your request for change password is sent successfully.
                      </h5>
                    </div> */}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            CANCEL
                    </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={this.resetEmailValidate}
                                        >
                                            SUBMIT
                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*End Modal */}
                    </section>
                </div>
                {isLoading && <Loader />}
                {showPopupModal && (
                    <PopupModal
                        closeModal={this.closeModal}
                        Text={popUpModalText}
                        messageType={messageType}
                    />
                )}
            </div>
        );
    }
}

export default LoginPage;
