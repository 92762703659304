import React, { Component } from "react";
import Header from "../../_components/Header";
import { history } from "../../_helpers/history";
import { studentService } from "../../_services/student.service";
import moment from "moment";
import RegisterStudentModel from "../../Models/RegisterStudentModel";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import Logout from "../../_helpers/Logout";
import StudentFooter from "../../_components/StudentFooter";
import PasswordChangeModal from "../../_helpers/PasswordChangeModal";
import p0 from "../../assets/p0.jpg";
import CommonUploadForm from "../../_helpers/CommonUploadForm";
import upload from "../../assets/upload.png";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";

var now = new Date();
var currentDate = moment(now).format("DD-MMMM-YYYY");

export default class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userID: 0,
      displayName: "",
      contactNo: "",
      emailAddress: "",
      address: "",
      createdOn: "",//moment().format("DD-MMMM-YYYY"),
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      coursesList: [],
      dueDate: moment().format("DD-MMMM-YYYY"),
      unfilteredCourseList: [],
      amountDue: 0,
      uniqueCategoryList: [],
      showPasswordModal: false,
      completedUnitsCount: 0,
      folderName: "ProfileImage",
      showCommomUploadModel: false,
      profileImagePath: "",
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the student profile to display
  <summary>*/
  componentDidMount = () => {
    try {
      var studentId = localStorage.getItem("UserID");
      this.setState(
        {
          userID: studentId,
          isLoading: true,
        },
        async () => {
          var response = await studentService.getStudentProfile(studentId);
          if (response.status === 200) {
            this.setState({
              displayName: response.data.studentData.displayName,
              contactNo: response.data.studentData.contactNo,
              emailAddress: response.data.studentData.emailAddress,
              address: response.data.studentData.address,
              createdOn: response.data.studentData.expiryDate,// response.data.studentData.createdOn,
              dueDate: response.data.studentData.nextInstallmentDue,
              amountDue: response.data.studentData.amountDue,
              coursesList: response.data.studentCourseData,
              unfilteredCourseList: response.data.studentCourseData,
              completedUnitsCount:
                response.data.studentData.completedUnitsCount,
              profileImagePath: response.data.studentData.profileImagePath,
            });
            localStorage.setItem(
              "ProfileImagePath",
              response.data.studentData.profileImagePath
            );
            this.uniqueList();
            this.uniqueCategory();
          } else if (response.status == 401) {
            Logout();
          } else {
            this.setState({
              popUpModalText: "Error while loading data",
              showPopupModal: true,
              messageType: MessageType.Failure,
            });
          }
        }
      );

      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  };

  uniqueList = async () => {
    const { coursesList } = this.state;

    const uniqueTags = [];
    coursesList.map((item) => {
      var findItem = uniqueTags.find((x) => x.courseName === item.courseName);
      if (!findItem) uniqueTags.push(item);
      return item;
    });

    this.setState({
      coursesList: uniqueTags,
    });
  };

  uniqueCategory = async () => {
    const { coursesList } = this.state;

    const uniqueTags = [];
    coursesList.map((item) => {
      var findItem = uniqueTags.find(
        (x) => x.courseCategory === item.courseCategory
      );
      if (!findItem) uniqueTags.push(item);
      return item;
    });

    this.setState({
      uniqueCategoryList: uniqueTags,
    });
  };

  /* <summary>
date: 22-07-2021
Name: VD
description:Once the Text has been changed it gets saved in the setstate 
<summary>*/
  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To edit the student details 
  <summary>*/
  onEditClick = async () => {
    try {
      const {
        userID,
        displayName,
        contactNo,
        emailAddress,
        address,
        createdOn,
      } = this.state;
      this.setState({
        isLoading: true,
      });

      var registerStudentModel = new RegisterStudentModel();
      registerStudentModel.userID = userID;
      registerStudentModel.emailAddress = emailAddress;
      registerStudentModel.address = address;
      registerStudentModel.contactNo = contactNo;

      var response = await studentService.AddEditUserProfileInfo(
        registerStudentModel
      );
      if (response.status == 200) {
        //success
        this.setState({
          popUpModalText: "Details updated",
          showPopupModal: true,
          messageType: MessageType.Success,
        });
      } else {
        this.setState({
          popUpModalText: "Unable to update",
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
      showCommomUploadModel: false,
    });
  };
  showCertificatePage = () => {
    history.push("/Certificates");
  };

  changePassword = () => {
    this.setState({
      showPasswordModal: true,
    });
  };

  closePasswordChangeModal = (id) => {
    this.setState({
      showPasswordModal: false,
    });
    if (id == 1) {
      this.setState({
        popUpModalText: helperText.PasswordChangeModalText.passwordupdated,
        showPopupModal: true,
        messageType: MessageType.Success,
      });
    } else if (id == 2) {
      this.setState({
        popUpModalText: "Error while updating your password",
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    }
  };

  showUploadModel = async () => {
    this.setState({
      showCommomUploadModel: true,
    });
  };

  getFileMediaId = async (Id) => {
    // if(Id==null){
    //   this.setState({
    //     popUpModalText: "Unable to update your profile Image",
    //     showPopupModal: true,
    //     messageType:MessageType.Failure,
    //   });
    // }else{
    //   this.setState({
    //     popUpModalText: "Your profile image has been successfully updated",
    //     showPopupModal: true,
    //     messageType:MessageType.Success,
    //   });
    // }

    await this.getProfileIcon();
  };

  getProfileIcon = async () => {
    var userID = localStorage.getItem("UserID");
    var response = await studentService.GetProfileImagePath(userID);
    if (response.status == 200) {
      localStorage.setItem("ProfileImagePath", response.data.response);
      await this.componentDidMount();
      window.location.reload();
    }
    this.setState({ isLoading: false });
  };

  render() {
    const {
      displayName,
      contactNo,
      emailAddress,
      address,
      createdOn,
      showPopupModal,
      popUpModalText,
      messageType,
      isLoading,
      dueDate,
      amountDue,
      showPasswordModal,
      completedUnitsCount,
      folderName,
      showCommomUploadModel,
      userID,
      profileImagePath,
    } = this.state;
    return (
      <div>
        <section className="student-footer-body-space">
          <Header currentTab="StudentProfile" />
          {isLoading && <Loader />}
          <section id="coursenbanner" style={{ marginTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-2 col-sm-2 col-xs-6">
                  <img
                    style={{ width: "100%" }}
                    src={profileImagePath == "" ? p0 : profileImagePath}
                  />
                  <div className="profile-img-Upload">
                    <img
                      src={upload}
                      className="img-responsive"
                      onClick={() => this.showUploadModel()}
                    />{" "}
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 col-xs-6">
                  <h2 style={{ marginTop: "3px" }}>{displayName}</h2>

                  <p>Expiry Date: {createdOn}</p>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.changePassword();
                    }}
                  >
                    Change Password
                  </button>
                </div>
                {localStorage.getItem("BelongsToMarineCorrosionCourse") != 1 ? (
                  <div className="col-md-5 col-sm-5 col-xs-12">
                    <p>
                      Next Installment Due on :
                      <span className="red">
                        {" "}
                        {dueDate == ""
                          ? ""
                          : moment(dueDate).format("DD-MMMM-YYYY")}
                      </span>
                    </p>
                    <p>
                      Amount Due :
                      {amountDue == 0 ? (
                        <span> £ {amountDue}</span>
                      ) : (
                        <span className="red"> £ {amountDue}</span>
                      )}
                    </p>
                    <p>
                      Certificates :{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        className="blue"
                        onClick={this.showCertificatePage}
                      >
                        {" "}
                        {completedUnitsCount} Download
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
          <section className="courselist col-md-6" id="content">
            <div className="form-group">
              <label htmlFor="lastname" className="control-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                placeholder="Phone Number"
                name="contactNo"
                value={contactNo}
                onChange={this.onTextChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname" className="control-label">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="lastname"
                placeholder="Email Address"
                name="emailAddress"
                value={emailAddress}
                disabled
                //onChange={this.onTextChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname" className="control-label">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                placeholder="Country"
                name="address"
                value={address}
                onChange={this.onTextChange}
              />
            </div>
          </section>
          <section className="courselist col-md-6" id="content">
            <div className="form-group" style={{ marginBottom: 0 }}>
              <label htmlFor="lastname" className="control-label">
                Courses Registered
              </label>
            </div>
            <div className="box">
              {this.state.uniqueCategoryList.map((item) => {
                return (
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    {item.courseCategory}
                  </h2>
                );
              })}

              <table className="table percentagelist">
                <tbody>
                  {this.state.coursesList.map((item) => {
                    return (
                      <tr>
                        {/* <td className="td-width">
                              <span
                                style={{ height: "35px", width: "35px" }}
                                className="percentage"
                              >
                                <p style={{ fontSize: "13px" }}>70%</p>
                              </span>
                            </td> */}
                        <td
                          style={{
                            textAlign: "left",
                            fontSize: "initial",
                            fontWeight: "bold",
                          }}
                        >
                          {item.courseName}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="form-group" style={{ marginTop: "13px" }}>
                  <label htmlFor="lastname" className="control-label">
                    Other Info 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    placeholder="Other Info"
                  />
                </div> */}
          </section>
          <div
            className="col-md-12"
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            {" "}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.onEditClick()}
            >
              Update Profile
            </button>
            <br />
          </div>
          <div className="clearfix" />
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showPasswordModal && (
          <PasswordChangeModal
            closePasswordChangeModal={this.closePasswordChangeModal}
            emailAddress={this.state.emailAddress}
          />
        )}
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            currentPage="Profile"
            userId={userID}
          />
        )}
      </div>
    );
  }
}
