import webserviceWrapper from "../_helpers/webserviceWrapper";

export const uploadfileservice={
    SaveFileMedia,
    SendEmail,
    AddEditTemplate,
}

function SaveFileMedia(data){
    return webserviceWrapper.post(
        `Admin/SaveFileMedia`,
        data
    )
}

function SendEmail(data){
    return webserviceWrapper.post(
        `Admin/SendEmail`,
        data
    )
}

function AddEditTemplate(data){
    return webserviceWrapper.post(
        `Admin/AddEditTemplate`,
        data
    )
}
