import webserviceWrapper from "../_helpers/webserviceWrapper";

export const studentService={
    getStudentProfile,
    AddEditUserProfileInfo,
    GetStudentNotification,
    GetStudentAssignmentListByStudentID,
    GetCourseGraphDetails,
    SendAdvanceCourseEnquiryMail,
    GetNotificationCount,
    GetCompletedUnitList,
    GetModuleListBasedOnStudentID,
    GetProfileImagePath,
    GetEmailTemplateList,
    GetMarineNews,
    GetMarineEvents,
    GetReportMagazine,
    GetEmailTemplateAttachementList,
    UpdateGraduateColumn
}

function getStudentProfile(studentId){
    return webserviceWrapper.post(
        `Student/getStudentProfile?studentId=${studentId}`
    )
}

function AddEditUserProfileInfo(registerStudentModel){
    return webserviceWrapper.post(
        `Student/AddEditUserProfileInfo`,
        registerStudentModel
      );
}

function GetStudentNotification(studentId){
    return webserviceWrapper.post(
        `Student/GetStudentNotification?studentId=${studentId}`
    )
}

function GetStudentAssignmentListByStudentID(studentId){
    return webserviceWrapper.post(
        `Student/GetStudentAssignmentListByStudentID?studentId=${studentId}`
    )
}

function GetCourseGraphDetails(studentId){
    return webserviceWrapper.post(
        `Student/GetCourseGraphDetails?studentId=${studentId}`
    )
}

function SendAdvanceCourseEnquiryMail(studentId,module,message){
    return webserviceWrapper.post(
        `Student/SendAdvanceCourseEnquiryMail`,
        {studentId,module,message}
    )
}

function GetNotificationCount(studentId){
    return webserviceWrapper.post(
        `Student/GetNotificationCount`,
        {studentId}
    )
}

function GetCompletedUnitList(studentId){
    return webserviceWrapper.post(
        `Student/GetCompletedUnitList`,
        {studentId}
    )
}

function GetModuleListBasedOnStudentID(studentId){
    return webserviceWrapper.post(
        `Student/GetModuleListBasedOnStudentID`,
        {studentId}
    )
}

function GetProfileImagePath(studentId){
    return webserviceWrapper.post(
        `Student/GetProfileImagePath`,
        {studentId}
    )
}

function GetEmailTemplateList(){
    return webserviceWrapper.get(
        `Student/GetEmailTemplateList`
    )
}

function GetMarineNews()
{
    return webserviceWrapper.get(
        `Student/GetMarineNews`
    )
}

function GetMarineEvents() {
    return webserviceWrapper.get(
        `Student/GetMarineEvents`
    )
}

function GetReportMagazine()
{
    return webserviceWrapper.get(
        `Student/GetReportMagazine`
    )
}

function GetEmailTemplateAttachementList(courseEnquiryEmailTemplateID){
    return webserviceWrapper.post(
        `Student/GetEmailTemplateAttachementList`,
        {courseEnquiryEmailTemplateID}
    )
}

function UpdateGraduateColumn(studentID, isGraduate) {
    return webserviceWrapper.post(
        `Student/UpdateGraduateColumn`,
        { studentID, isGraduate }
    )
}

