import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import { history } from "../../../_helpers/history";
import Loader from "../../../_helpers/Loader";
import { studentService } from "../../../_services/student.service";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import DataTable from "react-data-table-component";
import { admincourseservice } from "../../../_services/admincourse.service";
import PopupModal from "../../../_helpers/PopupModal";
import GraduateModal from "../../../_helpers/GraduateModal";
import Logout from "../../../_helpers/Logout";
import AdminFooter from "../../../_components/AdminFooter";
import Deleteicon from "../../../assets/Deleteicon.png";
import Overdue from "../../../assets/Over-due.png";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

export default class EnrolledCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            membershipNumber: "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            contactNo: "",
            isGraduated: false,
            enrolledCourse: [],
            yetToEnrollcourses: [],
            selected: "",
            selectedIndex: -1,
            userID: "",
            showGraduateModal: false,
            showPopupModal: false,
            popUpModalText: "",
            messageType: 0,
            showEnrollButton: false,
            deleteIterationCheckBox: [],
            errorMessage: {},
            outstandingInstallment: false,
        };
    }

    /* <summary>
    date: 19-10-2021
    Name: VD
    description: To get the details of the student based on the student id
    <summary>*/
    componentDidMount = async () => {
        try {
            const userID = this.props.match.params.userID;
            this.setState({
                isLoading: true,
                userID: userID,
            });
            var response = await studentService.getStudentProfile(userID);
            if (response.status == 200) {
                this.setState({
                    membershipNumber: response.data.studentData.membershipNumber,
                    firstName: response.data.studentData.firstName,
                    lastName: response.data.studentData.lastName,
                    emailAddress: response.data.studentData.emailAddress,
                    contactNo: response.data.studentData.contactNo,
                    isGraduated: response.data.studentData.isGraduated,
                });
            } else if (response.status == 401) {
                Logout();
            } else {
                this.setState({
                    popUpModalText: helperText.DataLoadError.loadError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }
            this.getCourses();
            this.setState({
                isLoading: false,
            });
        } catch (e) {
            return "";
        }
    };

    /* <summary>
    date: 19-10-2021
    Name: VD
    description: To Get the lis of course and iteration that the user has been enrolled into
    <summary>*/
    getCourses = async () => {
        try {
            this.setState({
                isLoading: true,
            });
            var courseresponse =
                await adminstudentservice.GetCourseandIterationForUserID(
                    this.state.userID
                );
            if (courseresponse.status == 200) {
                this.setState({
                    enrolledCourse: courseresponse.data.enrolledCourses,
                    yetToEnrollcourses: courseresponse.data.yetToEnrollCourses,
                    outstandingInstallment:
                        courseresponse.data.enrolledCourses.length == 0
                            ? false
                            : courseresponse.data.enrolledCourses[0].outstandingInstallment,
                });
            } else {
                this.setState({
                    popUpModalText: helperText.DataLoadError.loadError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }
            this.setState({
                isLoading: false,
            });
        } catch (e) {
            return "";
        }
    };

    header = [
        {
            name: <th className="align-middle">Course</th>,
            //selector:,
            cell: (row) => (
                <td style={{ textAlign: "left" }} className="text-color">
                    {row.courseName}
                </td>
            ),
        },
        {
            name: <th className="align-middle">Iteration</th>,
            //selector:,
            cell: (row) => (
                <td
                    className="center text-color"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/CourseIteration/${row.courseID}`)}
                >
                    {row.courseIterationName}
                </td>
            ),
        },
        {
            name: <th className="align-middle">Status</th>,
            //selector:,
            cell: (row) => <td>In progress</td>,
        },
        {
            name: <th className="align-middle">Manage</th>,
            //selector:,
            cell: (row) => (
                <td className="center cell-width">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                            history.push(
                                `/ManageEnrollment/${this.state.userID}/${row.courseIterationID}/${row.courseID}`
                            )
                        }
                    >
                        Manage
                    </button>
                </td>
            ),
            center: true,
        },
    ];

    OnEventChange(event) {
        const value = event.target.value;
        this.setState({
            selected: value,
            selectedIndex: event.target.selectedIndex,
            showEnrollButton: true,
        });
    }

    /* <summary>
    date: 19-10-2021
    Name: VD
    description: To enroll the student to any particular iteration
    <summary>*/
    enrollCourseToStudent = async () => {
        try {
            const { selected, membershipNumber, userID } = this.state;
            this.setState({
                isLoading: true,
            });
            var response = await adminstudentservice.enrollCourseToStudent(
                userID,
                selected,
                membershipNumber
            );
            if (response.status == 200) {
                await this.getCourses();
                this.removeFromDropDown();
                this.setState({
                    popUpModalText: helperText.EnrolledCourses.studentEnrolled,
                    showPopupModal: true,
                    messageType: MessageType.Success,
                });
            } else {
                this.setState({
                    popUpModalText: helperText.EnrolledCourses.studentNotEnrolled,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }
            this.setState({
                isLoading: false,
                selected: "",
            });
        } catch (e) {
            return "";
        }
    };

    /* <summary>
    date: 19-10-2021
    Name: VD
    description: enrolled students are removed from the dropdown
    <summary>*/
    removeFromDropDown = () => {
        const { selected, yetToEnrollcourses, selectedIndex } = this.state;

        var filteredValue = [...yetToEnrollcourses];
        if (selectedIndex !== -1) {
            filteredValue.splice(selectedIndex, 1);
            this.setState({ yetToEnrollcourses: filteredValue });
        }
    };

    closeModal = () => {
        this.setState({
            showPopupModal: false
        });
    };

    closeGraduateModal = () => {
        this.setState({
            showGraduateModal: false
        });
    }

    handleChange = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        this.setState({
            deleteIterationCheckBox: state.selectedRows,
            isChanged: true,
            errorMessage: {},
        });
    };

    /* <summary>
    date: 11-10-2021
    Name: VD
    description: When the admin removes the student from the iteration
    <summary>*/
    deleteSelectedCourses = async () => {
        try {
            this.setState({
                isLoading: true,
            });
            const { deleteIterationCheckBox, userID } = this.state;
            if (this.validateCheckbox()) {
                var response =
                    await admincourseservice.RemoveEnrolledStudentFromIteration(
                        deleteIterationCheckBox,
                        userID
                    );
                if (response.status == 200) {
                    this.setState({
                        deleteIterationCheckBox: [],
                        popUpModalText: helperText.EnrolledCourses.studentRemoved,
                        showPopupModal: true,
                        messageType: MessageType.Success,
                    });
                    this.getCourses();
                } else {
                    this.setState({
                        popUpModalText: helperText.DataLoadError.dataDeleteError,
                        showPopupModal: true,
                        messageType: MessageType.Failure,
                    });
                }
            }
            this.setState({
                isLoading: false,
            });
        } catch (e) {
            return "";
        }
    };

    validateCheckbox = () => {
        const { deleteIterationCheckBox } = this.state;
        try {
            var isValid = true;
            if (deleteIterationCheckBox == "") isValid = false;

            if (deleteIterationCheckBox == "") {
                var error = this.state.errorMessage;
                error["emptyCheckbox"] =
                    helperText.courseIteration.emptyiterationCheckbox;
                this.setState({ errorMessage: error });
            }
            return isValid;
        } catch (error) { }
    };

    openGraduateModal = (isGraduate) => {
        this.setState({ showGraduateModal: true });
    }

    render() {
        const {
            isLoading,
            membershipNumber,
            firstName,
            lastName,
            emailAddress,
            contactNo,
            isGraduated,
            popUpModalText,
            showPopupModal,
            messageType,
            showEnrollButton,
            outstandingInstallment,
            showGraduateModal
        } = this.state;
        return (
            <div id="admin" style={{ backgroundColor: "#F1F4F6" }}>
                <AdminHeader currentTab="Courses" />
                {isLoading && <Loader />}
                {/* Begin page content */}
                <main role="main" className="container admin-main-body ">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="mt-3 mb-3 gray">Student Course Manager</h5>
                        </div>
                        <div className="col-md-12" style={{ marginTop: "-1rem" }}>
                            <h6 className="mt-3 mb-3 gray">
                                Student Course Manager&gt; <strong> Enrolled Courses </strong>
                            </h6>
                        </div>
                        <div className="col-md-12">
                            <div
                                className="alert alert-danger alert-dismissible fade show"
                                role="alert"
                            >
                                No valid course or student specified.
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                        </div>
                        {outstandingInstallment ? (
                            <div className="col-md-12" style={{ color: "red" }}>
                                <img
                                    src={Overdue}
                                    style={{ width: "25px", height: "25px", marginRight: "10px" }}
                                />
                                This student has not paid one of the installments{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="col-md-6  col-sm-12">
                            <div className="student-info">
                                <div className="table-responsive">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>Membership Number: </strong>
                                                </td>
                                                <td className="leftPadding cell-size">
                                                    {membershipNumber}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>First Name:</strong>
                                                </td>
                                                <td className="leftPadding cell-size">{firstName}</td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>Last Name:</strong>
                                                </td>
                                                <td className="leftPadding cell-size">{lastName}</td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>Email:</strong>
                                                </td>
                                                <td className="leftPadding cell-size">
                                                    {emailAddress}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>Phone:</strong>
                                                </td>
                                                <td className="leftPadding cell-size">{contactNo} </td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size">
                                                    <strong>Is Graduated ?</strong>
                                                </td>
                                                <td className="leftPadding cell-size">
                                                    {isGraduated ? "Yes" : "No"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cell-size"></td>
                                                <td className="leftPadding cell-size btn-link">
                                                    {isGraduated ?
                                                        <a onClick={() => this.openGraduateModal(isGraduated)}>
                                                            <u>Do you want to change the student's status to 'Not Graduated'?</u>
                                                        </a> :
                                                        <a onClick={() => this.openGraduateModal(isGraduated)}>
                                                            <u>Do you want to change the student's status to 'Graduated'?</u>
                                                            </a>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6   col-sm-12">
                            <div className="student-info">
                                <h6>
                                    <strong>Enroll Student on new Course / Iteration:</strong>
                                </h6>
                                <div className="row">
                                    <div className="col-lg-8 col-sm-12">
                                        <select
                                            className="form-control1 courses course-dropdown"
                                            id="courses"
                                            name="courses"
                                            onChange={this.OnEventChange.bind(this)}
                                            value={this.state.selected}
                                        >
                                            <option value="" selected disabled>
                                                Select Course / Iteration
                                            </option>
                                            {this.state.yetToEnrollcourses != undefined &&
                                                this.state.yetToEnrollcourses.map((item, i) => {
                                                    return (
                                                        <option value={item.courseIterationId} key={i}>
                                                            {item.courseIterationName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <button
                                            type="button"
                                            className="btn enroll-button"
                                            onClick={this.enrollCourseToStudent}
                                            disabled={!showEnrollButton}
                                        >
                                            Enroll
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h5 className="mt-3 mb-3 gray">Enrolled Courses</h5>
                        </div>
                        <div className="col-md-12" style={{ marginBottom: "1em" }}>
                            <button
                                type="button"
                                className="btn delete-button"
                                onClick={this.deleteSelectedCourses}
                            >
                                <img src={Deleteicon} className="delete-icon" width="20px" />{" "}
                                Delete
                            </button>
                            <div
                                style={{
                                    display: this.state.errorMessage.emptyCheckbox
                                        ? "contents"
                                        : "none",
                                    color: "red",
                                }}
                            >
                                <p className="empty-msg">
                                    {this.state.errorMessage.emptyCheckbox}
                                </p>
                            </div>
                        </div>

                        <div className="container" style={{ marginBottom: "4em" }}>
                            <div className="table-responsive">
                                <DataTable
                                    columns={this.header}
                                    data={this.state.enrolledCourse}
                                    noHeader={true}
                                    pagination
                                    onSelectedRowsChange={this.handleChange}
                                    selectableRows // add for checkbox selection
                                />
                            </div>
                            <button
                                type="button"
                                style={{ marginTop: "20px" }}
                                className="btn back-button"
                                onClick={() => history.push("/StudentCourseManager")}
                            >
                                <i className="fa fa-angle-left" /> Back
                            </button>
                        </div>
                    </div>
                </main>
                <AdminFooter />
                {showPopupModal && (
                    <PopupModal
                        closeModal={this.closeModal}
                        Text={popUpModalText}
                        messageType={messageType}
                    />
                )}
                {showGraduateModal && (
                    <GraduateModal
                        closeGraduateModal={this.closeGraduateModal}
                        isGraduate={this.state.isGraduated}
                        userID={this.state.userID}
                    />
                )}
            </div>
        );
    }
}
