import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Overdue from "../../../assets/Over-due.png";
import DataTable from "react-data-table-component";
import { history } from "../../../_helpers/history";
import Loader from "../../../_helpers/Loader";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import { admincourseservice } from "../../../_services/admincourse.service";
import AdminFooter from "../../../_components/AdminFooter";
import moment from "moment";
import { MessageType } from "../../../_helpers/Enums";
import { studentService } from "../../../_services/student.service";
import { helperText } from "../../../_helpers/helpertext";

var now = new Date();
var currentDate = moment(now).format("YYYY-MM-DD");

export default class StudentCourseManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      studentList: [],
      unFilteredStudentList: [],
      Search: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      courseDetails: [],
      selectedCategory: 0,
      roleID: 0,
      checkedBoxes: [],
      errorMessage: {},
      categoryList: [],
      categoryId: 1,
      templateList: [],
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of Students
  <summary>*/
  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      var RoleID = localStorage.getItem("RoleID");

      this.studentList();

      this.GetCourseList();
      this.GetCategoryList();
      this.getEmailTemplate();
      this.setState({
        isLoading: false,
        roleID: RoleID,
      });
    } catch (e) {
      return "";
    }
  };

  studentList = async () => {
    try {
      var response = await adminstudentservice.StudentsList();
        if (response.status == 200) {
        this.setState({
          isLoading: false,
          studentList: response.data,
          unFilteredStudentList: response.data,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        //error while loading data
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
    } catch (e) {
      return "";
    }
  };

  GetCategoryList = async () => {
    try {
      var response = await admincourseservice.GetcourseCategoryList();
      if (response.status == 200) {
        this.setState({
          categoryList: response.data,
        });
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the course list
  <summary>*/
  GetCourseList = async () => {
    try {
      var response = await admincourseservice.GetCourseList();
      if (response.status == 200) {
        this.setState({
          courseDetails: response.data,
        });
      }
    } catch (e) {
      return "";
    }
  };

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();

    var filteredStudent = this.state.unFilteredStudentList.filter(
      (p) =>
        p.firstName.toLowerCase().includes(inputValue) ||
        p.lastName.toLowerCase().includes(inputValue) ||
        p.emailAddress.toLowerCase().includes(inputValue)
      //p.membershipNumber.toLowerCase().includes(inputValue))
    );

    this.setState({
      Search: event.target.value,
      studentList: filteredStudent,
    });
  };

  header = [
    {
      name: <div className="table-header" scope="col"></div>,
      //selector:'membershipNumber',
      cell: (row) =>
        row.nextInstallmentDue == "" ? (
          ""
        ) : moment(row.nextInstallmentDue).format("YYYY-MM-DD") >
          currentDate ? (
          ""
        ) : (
          <img src={Overdue} width="30px;" />
        ),
      center: true,
      width: "45px",
      },
      {
          name: (
              <div className="table-header" scope="col">
                  Is Graduated?
              </div>
          ),
          selector: "isGraduated",
          cell: (row) => row.isGraduated ? "Yes" : "No",
          center: true,
          width: "100px",
      },
    {
      name: (
        <div className="table-header" scope="col">
          Membership Number
        </div>
      ),
      selector: "membershipNumber",
      cell: (row) => row.membershipNumber,
      center: true,
      width: "160px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          First name
        </div>
      ),
      selector: "firstName",
      cell: (row) => row.firstName,
        center: true,
        width: "100px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          Last Name
        </div>
      ),
      selector: "lastName",
      cell: (row) => row.lastName,
        center: true,
        width: "100px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          Email
        </div>
      ),
      selector: "emailAddress",
      cell: (row) => row.emailAddress,
      center: false,
      width: "190px",
      grow: 2,
    },
    {
      name: (
        <div className="text-center" scope="col">
          Password
        </div>
      ),
      selector: "password",
      cell: (row) => (
        <input
          type="password"
          style={{ border: "none", overflow: "scroll" }}
          onFocus={(e) => (e.target.type = "text")}
          onBlur={(e) => (e.target.type = "password")}
          readOnly
          value={row.password}
        />
      ),
      center: false,
      width: "90px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          Payment Due date
        </div>
      ),
      selector: "nextInstallmentDue",
      cell: (row) =>
        row.nextInstallmentDue == ""
          ? ""
          : moment(row.nextInstallmentDue).format("YYYY-MM-DD"),
      center: true,
      width: "130px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          Courses
        </div>
      ),
      selector: "Courses",
      cell: (row) => (
        <button
          onClick={() => history.push(`/EnrolledCourses/${row.userID}`)}
          style={{ cursor: "pointer" }}
          className="btn btn-link"
          type="button"
        >
          Manage
        </button>
      ),
        center: true,
        width: "80px",
    },
    {
      name: (
        <div className="text-center" scope="col">
          E-Mail
        </div>
      ),
      selector: "Courses",
      cell: (row) => (
        <button
          onClick={() =>
            history.push(`/SendEmail/${row.emailAddress}/${this.state.roleID}`)
          }
          style={{ cursor: "pointer" }}
          className="btn btn-link"
          type="button"
        >
          Send mail
        </button>
      ),
        center: true,
        width: "70px",
    },
  ];

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  /* <summary>
  date: 22-09-2021
  Name: VD
  description: This is used to change the dropdown value as well as to filter the student list
  <summary>*/
  OnEventChange(event) {
    try {
      var value = event.target.value;
      this.setState({
        selectedCategory: event.target.value,
      });
      var response;
      if (value == 0) {
        this.setState({
          studentList: this.state.unFilteredStudentList,
        });
      } else {
        this.setState(
          {
            isLoading: true,
          },
          async () => {
            response = await adminstudentservice.FilterStudentBasedOnCourseId(
              value
            );
            if (response.status == 200) {
              this.setState({
                studentList: response.data,
              });
            }
          }
        );
        this.setState({ isLoading: false });
      }
    } catch (e) {
      return "";
    }
  }

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    this.setState({
      checkedBoxes: state.selectedRows,
      isChanged: true,
      errorMessage: {},
    });
  };

  validateCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes == "") isValid = false;

      if (checkedBoxes == "") {
        var error = this.state.errorMessage;
        error["emptyCheckbox"] =
          helperText.StudentCourseManager.emptyStudentMailCheckbox;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (e) {
      return "";
    }
  };

  sendTemplateMail = async () => {
    this.setState({ isLoading: true });
    try {
      const { checkedBoxes, categoryId } = this.state;
      if (this.validateCheckbox()) {
        var response =
          await adminstudentservice.sendTemplateMailToSelectedStudentGroup(
            checkedBoxes,
            categoryId
          );
        if (response.status == 200) {
          this.setState({
            checkedBoxes: [],
            popUpModalText: helperText.StudentCourseManager.templateMailSent,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText:
              helperText.StudentCourseManager.templateMailSentError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
    } catch (e) {
      return "";
    }
    this.setState({ isLoading: false });
  };

  onCategoryChange = (event) => {
    this.setState({
      categoryId: event.target.value,
    });
  };

  getEmailTemplate = async () => {
    try {
      this.setState({ isLoadingL: true });
      var response = await studentService.GetEmailTemplateList();
      if (response.status == 200) {
        this.setState({
          templateList: response.data,
        });
      }
      this.setState({ isLoading: false });
    } catch (error) {}
  };

  deleteStudent = async () => {
    const { checkedBoxes } = this.state;
    this.setState({ isLoading: true });
    try {
      if (this.validatedeleteStudentCheckbox()) {
        var response = await adminstudentservice.removeStudentFromDB(
          checkedBoxes
        );
        if (response.status == 200) {
          this.setState({
            checkedBoxes: [],
            popUpModalText:
              helperText.StudentCourseManager.studentDeleteSuccess,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
          this.studentList();
        } else {
          this.setState({
            popUpModalText:
              helperText.StudentCourseManager.studentDeleteFailure,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
    } catch (error) {}
    this.setState({ isLoading: false });
  };

  validatedeleteStudentCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes == "") isValid = false;

      if (checkedBoxes == "") {
        var error = this.state.errorMessage;
        error["emptydeleteCheckbox"] =
          helperText.StudentCourseManager.emptyStudentDeleteCheckbox;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (e) {
      return "";
    }
  };
  render() {
    const {
      isLoading,
      showPopupModal,
      popUpModalText,
      messageType,
      courseDetails,
      selectedCategory,
      categoryId,
      templateList,
    } = this.state;
    return (
      <div id="admin" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main
          role="main"
          className="container admin-main-body "
          style={{ marginBottom: "4em" }}
        >
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">Student Course Manager</h5>
            </div>

            <div className="col-md-9">
              <button
                type="button"
                className="btn btn-dark button-color"
                onClick={() => history.push("/StudentsEnquired")}
              >
                Students Enquired
              </button>
              <button
                type="button"
                className="btn btn-dark button-color"
                style={{ marginLeft: "9px" }}
                onClick={() => history.push("/RegisteredStudents")}
              >
                Registered Students
              </button>
            </div>
            <div className="col-md-3">
              <div className="search-container search-bar ">
                <form className="example1 search-bar-md">
                  <input
                    type="text"
                    placeholder="Search.."
                    name="Search"
                    value={this.state.Search}
                    onChange={this.onSearchTextChange.bind(this)}
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <h5 className="mt-3 mb-3 gray">Course / Iteration Filter:</h5>
              <select
                className="form-control1 courses"
                id="courses"
                name="courses"
                value={selectedCategory}
                onChange={(e) => this.OnEventChange(e)}
              >
                <option value={0}>Select Course / Iteration </option>
                {courseDetails.map((item) => {
                  return (
                    <option value={item.courseID}>{item.courseName} </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-5">
              <h5 className="mt-3 mb-3 gray">Select Template</h5>
              <select
                className="form-control1 courses"
                id="courses"
                name="courses"
                value={categoryId}
                onChange={(e) => this.onCategoryChange(e)}
                style={{ width: "450px" }}
              >
                {templateList.map((item) => {
                  return (
                    <option value={item.courseEnquiryEmailTemplateID}>
                      {item.templateName}{" "}
                    </option>
                  );
                })}
              </select>
              <div
                style={{
                  display: this.state.errorMessage.emptyCheckbox
                    ? "table-cell"
                    : "none",
                  color: "red",
                }}
              >
                {this.state.errorMessage.emptyCheckbox}
              </div>
            </div>
            <div className="col-md-3 template-btn send-template-md">
              <h5 className="mt-10 mb-3 gray"> </h5>
              <button
                type="button"
                className="btn btn-dark button-color send-template-md"
                onClick={this.sendTemplateMail}
              >
                Send Template
              </button>
            </div>
            <div className=" col-12 col-md-12" style={{ paddingTop: "20px" }}>
              <button
                type="button"
                onClick={this.deleteStudent}
                class="btn delete-button"
              >
                <img
                  src="images/Delete icon.png"
                  class="delete-icon"
                  width="20px"
                />{" "}
                Delete
              </button>
              <div
                style={{
                  display: this.state.errorMessage.emptydeleteCheckbox
                    ? "table-cell"
                    : "none",
                  color: "red",
                }}
              >
                {this.state.errorMessage.emptydeleteCheckbox}
              </div>
            </div>
            <div className="col-md-12">
              <div className="table-responsive" style={{ paddingTop: "2em" }}>
                <table className="table table-striped">
                  <DataTable
                    columns={this.header}
                    data={this.state.studentList}
                    noHeader={true}
                    pagination
                    selectableRows
                    onSelectedRowsChange={this.handleChange}
                  />
                </table>
              </div>
              <button
                type="button"
                className="btn back-button"
                style={{ marginTop: "20px" }}
                onClick={() => history.push("/DashboardPage")}
              >
                <i className="fa fa-angle-left" /> Back
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
