import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Calendar from "../../../assets/Calendar.png";
import { history } from "../../../_helpers/history";
import moment from "moment";
import CourseIterationModel from "../../../Models/CourseIterationModel";
import Loader from "../../../_helpers/Loader";
import { admincourseservice } from "../../../_services/admincourse.service";
import AdminFooter from "../../../_components/AdminFooter";
import { helperText } from "../../../_helpers/helpertext";

var now = new Date();
var currentDate = moment(now).format("YYYY-MM-DD");
const dateFormat = "YYYY-MM-DD";

const errorMessageStyle = {
  color: "Red",
  fontStyle: "bold",
  fontSize: 14,
  marginBottom: "10px",
};

export default class CreateCourseIteration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      courseName: "",
      startDate:"",
      //endDate: moment().format(dateFormat),
      endDate:"",
      firstInstallmentDate: "",
      secondInstallmentDate: "",
      minSecondInstallmentDate:  moment().format(dateFormat),
      thirdInstallmentDate: "",
      minThirdInstallmentDate:  moment().format(dateFormat),
      fourthInstallmentDate: "",
      minFourthInstallmentDate:  moment().format(dateFormat),
      iterationCost: "",
      courseID: 1,
      courseIterationId: 0,
      errorMessage: {},
    };
  }

  componentDidMount = async () => {
    // var courseID = this.props.match.params.id;
    // if(courseID==undefined||courseID==0){
    //   courseID=this.state.courseID;
    // }
    this.setState({
      isLoading: true,
    });

    this.setState({ isLoading: false });
  };

  /* <summary>
date: 21-07-2021
Name: VD
description:Once the Text has been changed it gets saved in the setstate 
<summary>*/
  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 21-07-2021
  Name: VD
  description: Date Change Values
  <summary>*/
  onDateChange = (e) => {
    var selectedDate = e.target.value;
    const { name, value } = e.target;

    //fix for future date on firefox
    //if (selectedDate >= currentDate) {
      this.setState({
        [name]: value,
      });
    //}

    this.setminDate(name,value);
    
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To set the min installment dates
  <summary>*/ 
  setminDate=(name,value)=>{

    if (name == "firstInstallmentDate") {
      this.setState({
        minSecondInstallmentDate: moment(value).add(3, "months").format("YYYY-MM-DD"),
      });
    }
    if (name == "secondInstallmentDate") {
      this.setState({
        minThirdInstallmentDate: moment(value).add(3, "months").format("YYYY-MM-DD"),
      });
    }
    if (name == "thirdInstallmentDate") {
      this.setState({
        minFourthInstallmentDate: moment(value).add(3, "months").format("YYYY-MM-DD"),
      });
    }

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
    date: 21-07-2021
    Name: VD
    description: So that it doesnt accept negative value
    <summary>*/
  onChangeNumber = (e) => {
    var value = 0;
    if (e.target.value < 0) {
      value = 0;
    } else {
      value = e.target.value;
    }
    const { name } = e.target;
    this.setState({
      [name]: value,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 21-07-2021
  Name: VD
  description: To save the selected value into the Database
  <summary>*/
  addEditCourseIteration = async () => {
    const userID = localStorage.getItem("UserID");
    try {
      if (this.validate()) {
        this.setState({
          isLoading: true,
        });
        const {
          courseIterationId,
          courseName,
          startDate,
          endDate,
          firstInstallmentDate,
          secondInstallmentDate,
          thirdInstallmentDate,
          fourthInstallmentDate,
          iterationCost,
          courseID,
        } = this.state;

        var courseIteration = new CourseIterationModel();
        courseIteration.courseIterationId = courseIterationId;
        courseIteration.courseID = courseID;
        courseIteration.courseName = courseName;
        courseIteration.startDate = startDate;
        courseIteration.endDate = endDate;
        courseIteration.firstInstallmentDate = firstInstallmentDate;
        courseIteration.secondInstallmentDate = secondInstallmentDate;
        courseIteration.thirdInstallmentDate = thirdInstallmentDate;
        courseIteration.fourthInstallmentDate = fourthInstallmentDate;
        courseIteration.iterationCost = iterationCost;
        courseIteration.userID = userID;

        var response = await admincourseservice.AddEditCourseIteration( courseIteration );
        if (response.status == 200) {
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) { 
    }
    //() => history.push("/EditCourseIteration")
  };

  /* <summary>
  date: 28-07-2021
  Name: VD
  description: Validation of the Text  +when comparing two dates
  <summary>*/
  validate = () => {
    try {
      var {
        courseName,
        startDate,
        endDate,
        firstInstallmentDate,
        secondInstallmentDate,
        minSecondInstallmentDate,
        thirdInstallmentDate,
        minThirdInstallmentDate,
        fourthInstallmentDate,
        minFourthInstallmentDate,
        iterationCost,
      } = this.state;
      var isValid = true;

      if (
        courseName == "" ||
        startDate == "" ||
        endDate == "" ||
        +firstInstallmentDate <= +startDate ||
        +secondInstallmentDate >= +minSecondInstallmentDate ||
        +thirdInstallmentDate >= +minThirdInstallmentDate ||
        +fourthInstallmentDate >= +minFourthInstallmentDate ||
        iterationCost == 0
      )
        isValid = false;

      if (courseName == "") {
        var error = this.state.errorMessage;
        error["emptyTitle"] = helperText.createCourseIteration.emptyTitle;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (startDate == "") {
        var error = this.state.errorMessage;
        error["emptyAvailableFrom"] = helperText.createCourseIteration.emptyAvailableFrom;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (endDate == "") {
        var error = this.state.errorMessage;
        error["emptyAvailableTo"] = helperText.createCourseIteration.emptyAvailableTo;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (iterationCost == "") {
        var error = this.state.errorMessage;
        error["emptyIterationCost"] = helperText.createCourseIteration.emptyIterationCost;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (firstInstallmentDate <= startDate) {
        var error = this.state.errorMessage;
        error["emptyFirstInstallmentDate"] = helperText.createCourseIteration.emptyFirstInstallmentDate;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (+secondInstallmentDate >= +minSecondInstallmentDate) {
        var error = this.state.errorMessage;
        error["emptySecondInstallmentDate"] =helperText.createCourseIteration.emptySecondInstallmentDate;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (+thirdInstallmentDate >= +minThirdInstallmentDate) {
        var error = this.state.errorMessage;
        error["emptyThirdInstallmentDate"] =helperText.createCourseIteration.emptyThirdInstallmentDate;
        this.setState({ errorMessage: error });
        return isValid;
      }

      if (
        fourthInstallmentDate < minFourthInstallmentDate ||
        fourthInstallmentDate == "" ||
        fourthInstallmentDate <= thirdInstallmentDate
      ) {
        var error = this.state.errorMessage;
        error["emptyFourthInstallmentDate"] = helperText.createCourseIteration.emptyFourthInstallmentDate;
        this.setState({ errorMessage: error });
        return isValid;
      }
      

      //return isValid;
    } 
    catch (error) {
      return false;
    }
  };
  
  render() {
    const {
      iterationCost,
      courseName,
      startDate,
      endDate,
      firstInstallmentDate,
      secondInstallmentDate,
      minSecondInstallmentDate,
      thirdInstallmentDate,
      minThirdInstallmentDate,
      fourthInstallmentDate,
      minFourthInstallmentDate,
      isLoading,
    } = this.state;
    return (
      <div id="admin" style={{backgroundColor: '#F1F4F6'}}>
        <AdminHeader currentTab="Courses"/>
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">Create Course Iterations</h5>
            </div>
            <div className="col-md-12">
              <p>
                Complete the form below to add a new iteration to the selected
                course. Once the iteration has been created, you may upload
                modules and assign students to it.
              </p>
            </div>
            <div className="col-lg-8">
              <div className="table-responsive" style={{ border: "none" }}>
                <table
                  className="table table-striped"
                  style={{ padding: "10px 10px" }}
                >
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color">
                        <label>Selected Course: </label>
                      </td>
                      <td className="white-color left">
                        Professional Qualification in Yatch and Small Craft
                        Marine Surveying 2021-22
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Iteration Title:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          className="input-align textbox"
                          type="text"
                          id="description"
                          name="courseName"
                          value={courseName}
                          onChange={this.onTextChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyTitle
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyTitle}</td>
                    </tr>

                    <tr>
                      <td className="blue-color left  border-color">
                        <label> Available from: </label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="startDate"
                          value={startDate}
                          placeholder="yyyy-mm-dd"
                          defaultValue
                          //min={currentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyAvailableFrom
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyAvailableFrom}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Available to:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="endDate"
                          value={endDate}
                          placeholder="dd-mm-yyyy"
                          defaultValue
                          //min={startDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyAvailableTo
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyAvailableTo}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        {" "}
                        <label>Iteration Cost:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="number"
                          step="0.01"
                          name="iterationCost"
                          value={iterationCost}
                          onChange={this.onChangeNumber}
                          placeholder="Iteration Cost"
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyIterationCost
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyIterationCost}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        {" "}
                        <label>First installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="firstInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={firstInstallmentDate}
                          //min={minSecondInstallmentDate}
                          max={startDate}
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyFirstInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyFirstInstallmentDate}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Second installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="secondInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={secondInstallmentDate}
                          min={minSecondInstallmentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptySecondInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptySecondInstallmentDate}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Third installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="thirdInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={thirdInstallmentDate}
                          min={minThirdInstallmentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyThirdInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyThirdInstallmentDate}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Fourth installment Due:</label>
                      </td>
                      <td className="white-color left">
                        <input
                          type="date"
                          name="fourthInstallmentDate"
                          placeholder="dd-mm-yyyy"
                          value={fourthInstallmentDate}
                          min={minFourthInstallmentDate}
                          max="2030-12-31"
                          style={{}}
                          onChange={this.onDateChange}
                        />
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: this.state.errorMessage.emptyFourthInstallmentDate
                          ? "contents"
                          : "none",
                      }}
                    >
                      <td className="blue-color left  border-color"></td>
                      <td style={{textAlign:'left',color:'red'}}>{this.state.errorMessage.emptyFourthInstallmentDate}</td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" />
                      <td className="white-color left">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-dark button-color create-iteration"
                          onClick={this.addEditCourseIteration}
                        >
                          Create Iteration
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <button
                type="button"
                className="btn back-button"
                onClick={() => history.push("/CourseIteration")}
              >
                <i className="fa fa-angle-left" />
                Back to Course Iterations
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    );
  }
}
